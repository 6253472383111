import { RootState } from '../../../../store/types';

export const fetchUsersIsLoadingSelector = (state: RootState) => state.users.fetchUsersIsLoading;
export const usersSelector = (state: RootState) => state.users.users.data;
export const usersTotalPagesSelector = (state: RootState) => state.users.users.totalPages;
export const usersCountSelector = (state: RootState) => state.users.users.count;
export const fetchUsersHasErrorSelector = (state: RootState) => state.users.fetchUsersHasError;

export const createUserIsLoadingSelector = (state: RootState) => state.users.createUserIsLoading;
export const createUserIsSuccessSelector = (state: RootState) => state.users.createUserIsSuccess;
export const createUserHasErrorSelector = (state: RootState) => state.users.createUserHasError;

export const editUserIsLoadingSelector = (state: RootState) => state.users.editUserIsLoading;
export const editUserIsSuccessSelector = (state: RootState) => state.users.editUserIsSuccess;
export const editUserHasErrorSelector = (state: RootState) => state.users.editUserHasError;

export const deleteUserIsLoadingSelector = (state: RootState) => state.users.deleteUserIsLoading;
export const deleteUserIsSuccessSelector = (state: RootState) => state.users.deleteUserIsSuccess;
export const deleteUserHasErrorSelector = (state: RootState) => state.users.deleteUserHasError;
