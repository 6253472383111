import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Grid, IconButton } from '@mui/material';
import { useFormikContext } from 'formik';

import DeleteWindowModal from './DeleteWindowModal';

import AddDateFieldIcon from '../../../../assets/images/PlusIconRed.svg';
import DeleteIcon from '../../../../assets/images/tableRowIcons/delete.svg';
import WindowTextField from './WindowTextField';

const StyledLabel = styled('p')`
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  color: #0c1b2a;
  margin-bottom: 4px;
  line-height: 110%;
  font-family: 'Public-sans';
`;

const windowFields = [
  { label: '#', name: 'numberOfWindows' },
  { label: 'Breedte(cm)', name: 'width' },
  { label: 'Hoogte(cm)', name: 'height' },
];

interface WindowFieldProps {
  idLots: number | undefined;
  idLotType: number | undefined;
}

export default function WindowsFieldComponent(props: WindowFieldProps) {
  const { idLots, idLotType } = props;
  const { setFieldValue, values } = useFormikContext();
  const floor = values.selectedFloor ?? 0;
  const [openDeleteWindow, setOpenDeleteWindow] = useState(false);
  const [selectedWindow, setSelectedWindow] = useState(-1);

  function addWindow() {
    setFieldValue(`${floor}.windows`, [
      ...values[floor].windows,
      {
        numberOfWindows: '',
        height: '',
        width: '',
        idWindows: -1,
      },
    ]);
  }

  function clearDeletedWindow(id: number | undefined) {
    if (id) {
      const tempWindows = values[floor].windows;
      const filteredWindows = tempWindows.filter((i) => i.idWindows !== id);
      setFieldValue(`${floor}.windows`, filteredWindows);
    }
  }

  return (
    <Box sx={{ marginTop: '16px' }}>
      <StyledLabel>Ramen</StyledLabel>
      {values[floor].windows.map((window, i) => (
        <Grid key={i} container direction="row" justifyContent={'space-between'} mt={2}>
          {windowFields.map((item, j) => (
            <Grid item key={j}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <StyledLabel>{item.label}</StyledLabel>
                <WindowTextField name={`${floor}.windows.${i}.${item.name}`} />
              </div>
            </Grid>
          ))}
          <Grid item>
            <IconButton
              onClick={() => {
                if (window.idWindows === -1) {
                  const windows = [...values[floor].windows];
                  windows.splice(i, 1);
                  setFieldValue(`${floor}.windows`, windows);
                } else {
                  setSelectedWindow(values[floor].windows[i]);
                  setOpenDeleteWindow(true);
                }
              }}
              sx={{
                padding: 0,
                '&:disabled': {
                  opacity: '0 !important',
                },
              }}
            >
              <img src={DeleteIcon} alt="DeleteIcon" />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '15px' }}>
        <img src={AddDateFieldIcon} alt="AddDateFieldIcon" style={{ cursor: 'pointer' }} onClick={addWindow} />
      </Grid>
      <DeleteWindowModal
        openModal={openDeleteWindow}
        closeModal={() => setOpenDeleteWindow(false)}
        selectedWindow={selectedWindow}
        idLots={idLots}
        idLotType={idLotType}
        floor={floor + 1}
        clearWindow={clearDeletedWindow}
      />
    </Box>
  );
}
