import { call, put } from 'redux-saga/effects';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { GetProductDropDownParam, ProductTypeDropDown } from '../../types';
import { PayloadAction } from '@reduxjs/toolkit';

async function callApi(param: GetProductDropDownParam) {
  const queryParam = {
    Clientid: param.Clientid,
    Floor: param.Floor,
    idLots: param.idLots,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getProductTypeDropDown, undefined, queryParam);
}

export default function* callFetchProductTypeDropDownSaga({ payload }: PayloadAction<GetProductDropDownParam>) {
  try {
    const response: ProductTypeDropDown[] = yield call(callApi, payload);
    yield put(clientActions.fetchProductTypeDropDownSuccess(response));
  } catch (error: unknown) {
    console.error('callFetchProductTypeDropDownSaga', error);
    yield put(clientActions.fetchProductTypeDropDownFailure());
  }
}
