import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/types';
import { CreateTemplatePayload, Template } from '../types';
import { Form, Formik } from 'formik';
import CustomDialog from '../../../shared/components/modal/CustomDialog';
import { Grid } from '@mui/material';
import TextInputField from '../../../shared/components/textField/TextInputField';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { productActions } from '../store/slice/productsSlice';
import {
  createTemplateIsLoadingSelector,
  createTemplateIsSuccessSelector,
  editTemplateIsLoadingSelector,
  editTemplateIsSuccessSelector,
  existingMaterialsSelector,
  fetchExistingMaterialsIsLoadingSelector,
} from '../store/selectors/productsSelector';
import { clientActions } from '../../clients/store/slice/clientSlice';
import { materialsSelector } from '../../clients/store/selectors/clientSelector';
import MultiSelect from '../../../shared/components/customSelect/MultiSelect';
import DisableSwitch from './DisableSwitch';

const ErrorText = styled('p')`
  color: #ec4c29;
  font-family: 'Public-sans';
  font-size: 12px;
  font-weight: 700;
  margin-top: 3px;
`;

interface CreateTemplateModalProps {
  openModal: boolean;
  closeModal: () => void;
  selectedTemplate: Template;
  fetchTemplates: () => void;
}

export default function CreateEditTemplateModal(props: CreateTemplateModalProps) {
  const { openModal, closeModal, selectedTemplate, fetchTemplates } = props;
  const dispatch = useDispatch();
  const isEditMode = !!selectedTemplate;

  const createTemplateIsLoading = useAppSelector(createTemplateIsLoadingSelector);
  const editTemplateIsLoading = useAppSelector(editTemplateIsLoadingSelector);
  const createTemplateIsSuccess = useAppSelector(createTemplateIsSuccessSelector);
  const editTemplateIsSuccess = useAppSelector(editTemplateIsSuccessSelector);
  const existingMaterials = useAppSelector(existingMaterialsSelector);
  const fetchExistingMaterialsIsLoading = useAppSelector(fetchExistingMaterialsIsLoadingSelector);
  const materials = useAppSelector(materialsSelector);

  const [windowError, setWindowError] = useState(false);
  const [floorError, setFloorError] = useState(false);

  useEffect(() => {
    if (createTemplateIsSuccess || editTemplateIsSuccess) {
      closeModal();
      fetchTemplates();
      dispatch(productActions.resetCreateTemplate());
      dispatch(productActions.resetEditTemplate());
      setWindowError(false);
      setFloorError(false);
    }
  }, [closeModal, createTemplateIsSuccess, dispatch, editTemplateIsSuccess, fetchTemplates]);

  useEffect(() => {
    if (openModal) {
      dispatch(clientActions.fetchMaterials());
      if (selectedTemplate?.idTemplate) {
        dispatch(productActions.fetchExistingMaterials(selectedTemplate?.idTemplate));
      }
    }
  }, [openModal, dispatch, selectedTemplate]);

  if (!openModal) {
    return null;
  }

  function handleCloseModal() {
    setWindowError(false);
    setFloorError(false);
    closeModal();
  }

  const floorDecorationMaterials = materials.reduce((acc, i) => {
    if (i.materialGroupCode === 1) {
      acc.push({ idMaterial: i.idMaterialType, materialName: i.materialTypeName });
    }
    return acc;
  }, []);

  const windowDecorationMaterials = materials.reduce((acc, i) => {
    if (i.materialGroupCode === 2) {
      acc.push({ idMaterial: i.idMaterialType, materialName: i.materialTypeName });
    }
    return acc;
  }, []);

  const curtainDecorationMaterials = materials.reduce((acc, i) => {
    if (i.materialGroupCode === 3) {
      acc.push({ idMaterial: i.idMaterialType, materialName: i.materialTypeName });
    }
    return acc;
  }, []);

  const existingFloorDecorationMaterials = existingMaterials.reduce((acc, i) => {
    if (i.materialGroupCode === 1) {
      acc.push({ idMaterial: i.idMaterialType, materialName: i.materialTypeName });
    }
    return acc;
  }, []);

  const existingWindowDecorationMaterials = existingMaterials.reduce((acc, i) => {
    if (i.materialGroupCode === 2) {
      acc.push({ idMaterial: i.idMaterialType, materialName: i.materialTypeName });
    }
    return acc;
  }, []);

  const existingCurtainDecorationMaterials = existingMaterials.reduce((acc, i) => {
    if (i.materialGroupCode === 3) {
      acc.push({ idMaterial: i.idMaterialType, materialName: i.materialTypeName });
    }
    return acc;
  }, []);

  const initialValues: CreateTemplatePayload = {
    isActive: selectedTemplate?.isActive ?? true,
    windowTemplateMaterials: isEditMode ? existingWindowDecorationMaterials : windowDecorationMaterials,
    floorTemplateMaterials: isEditMode ? existingFloorDecorationMaterials : floorDecorationMaterials,
    curtainTemplateMaterials: isEditMode ? existingCurtainDecorationMaterials : curtainDecorationMaterials,
    templateName: selectedTemplate?.name || '',
  };

  const validationSchema = Yup.object().shape({
    templateName: Yup.string().required('Template naam vereist'),
    // email: Yup.string().email('Email must be a valid email').required('Email required'),
    // idRole: Yup.string().required('Authorization role required'),
  });

  function handleSubmit(values: CreateTemplatePayload) {
    if (isEditMode) {
      if (
        values.windowTemplateMaterials.length === 0 &&
        values.floorTemplateMaterials.length === 0 &&
        values.curtainTemplateMaterials.length === 0
      ) {
        setWindowError(true);
        setFloorError(true);
      } else {
        setWindowError(false);
        setFloorError(false);
      }
      if (
        values.windowTemplateMaterials.length !== 0 ||
        values.floorTemplateMaterials.length !== 0 ||
        values.curtainTemplateMaterials.length !== 0
      ) {
        dispatch(productActions.editTemplate({ ...values, id: selectedTemplate.idTemplate }));
      }
    } else {
      if (
        values.windowTemplateMaterials.length === 0 &&
        values.floorTemplateMaterials.length === 0 &&
        values.curtainTemplateMaterials.length === 0
      ) {
        setWindowError(true);
        setFloorError(true);
      } else {
        setWindowError(false);
        setFloorError(false);
      }
      if (
        values.windowTemplateMaterials.length !== 0 ||
        values.floorTemplateMaterials.length !== 0 ||
        values.curtainTemplateMaterials.length !== 0
      ) {
        dispatch(productActions.createTemplate(values));
      }
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ dirty, isValid, values, submitForm, errors, setFieldValue }) => {
        return (
          <Form>
            <CustomDialog
              open={openModal}
              handleClose={handleCloseModal}
              title={isEditMode ? 'Template wijzigen' : 'Template maken'}
              cancelButtonText={'Annuleren'}
              handleCancelButtonPress={handleCloseModal}
              primaryButtonText={isEditMode ? 'Opslaan' : 'Template maken'}
              primaryButtonDisabled={!isValid || !dirty || createTemplateIsLoading || editTemplateIsLoading}
              handlePrimaryButtonPress={submitForm}
            >
              <Grid container rowGap={2}>
                <Grid item xs={12}>
                  <TextInputField
                    label={'Naam'}
                    name="templateName"
                    placeholder={'Naam van de template...'}
                    disabled={values.isActive ? false : true}
                  />
                </Grid>
                {isEditMode && (
                  <Grid item xs={12}>
                    <DisableSwitch />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <MultiSelect
                    name={'floorTemplateMaterials'}
                    label="Vloerdecoratie"
                    isLoading={fetchExistingMaterialsIsLoading}
                    placeholder={'Selecteer...'}
                    disabled={values.isActive ? false : true}
                  />
                  {floorError && <ErrorText>Selecteer vloermaterialen</ErrorText>}
                </Grid>
                <Grid item xs={12}>
                  <MultiSelect
                    name={'windowTemplateMaterials'}
                    label="Raamdecoratie"
                    isLoading={fetchExistingMaterialsIsLoading}
                    placeholder={'Selecteer...'}
                    disabled={values.isActive ? false : true}
                  />
                  {windowError && <ErrorText>Selecteer raammaterialen</ErrorText>}
                </Grid>
                <Grid item xs={12}>
                  <MultiSelect
                    name={'curtainTemplateMaterials'}
                    label="Gordijnen"
                    isLoading={fetchExistingMaterialsIsLoading}
                    placeholder={'Selecteer...'}
                    disabled={values.isActive ? false : true}
                  />
                  {windowError && <ErrorText>Selecteer gordijnen</ErrorText>}
                </Grid>
              </Grid>
            </CustomDialog>
          </Form>
        );
      }}
    </Formik>
  );
}
