import { call, put } from 'redux-saga/effects';
import { FetchUsersParams, FetchUsersResponse } from '../../types';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SIZE, ENDPOINTS, WORKSPACE_CODE, WORKSPACE_ID } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { userActions } from '../slice/usersSlice';

async function callApi(props: FetchUsersParams) {
  const queryParam = {
    searchKey: props?.searchKey,
    name: props?.name,
    email: props?.email,
    autherization: props?.autherization,
    KeyProperty: props?.KeyProperty,
    IsAscending: props?.IsAscending,
    workspaceId: WORKSPACE_ID,
    workspaceCode: WORKSPACE_CODE,
    pageSize: props?.pageSize ?? DEFAULT_PAGE_SIZE,
    pageNumber: props?.pageNumber ?? 1,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getUser, undefined, queryParam);
}

export default function* callFetchUsersSaga({ payload }: PayloadAction<FetchUsersParams>) {
  try {
    const users: FetchUsersResponse = yield call(callApi, payload);
    yield put(userActions.fetchUsersSuccess(users));
  } catch (error: unknown) {
    console.error('callFetchUsersSaga', error);
    yield put(userActions.fetchUsersFailure());
  }
}
