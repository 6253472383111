import React from 'react';
import Card from '@mui/material/Card';

import TableStatusLabel, { TableStatusType } from '../../../../shared/components/tableTextOptions/TableStatusLabel';

import { styled } from '@mui/material/styles';

const Label = styled('p')`
  font-size: 16px;
  font-weight: 700;
  color: #343e47;
`;

const Value = styled('p')`
  font-size: 16px;
  font-weight: 300;
  color: #343e47;
`;

interface ProjectDetailsCardProps {
  name: string;
  floors: number | null;
  status: string;
  code: number | null;
}

function ProjectDetailsCard(props: ProjectDetailsCardProps) {
  const { name, floors, status, code } = props;

  function handleTableStatus(colorCode: string) {
    switch (colorCode) {
      case '1':
        return TableStatusType.Error;
      case '2':
        return TableStatusType.Warn;
      case '3':
        return TableStatusType.Success;
      default:
        return undefined;
    }
  }

  return (
    <Card style={{ padding: '20px 12px', boxShadow: '0px 10px 24px 0px rgba(12, 27, 42, 0.06)', minHeight: '275px' }}>
      <div style={{ marginBottom: '15px' }}>
        <Label>Naam</Label>
        <Value>{name}</Value>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <Label>Aantal verdiepingen</Label>
        <Value>{floors}</Value>
      </div>
      <div>
        <Label style={{ marginBottom: '10px' }}>Portaal status</Label>
        <TableStatusLabel label={status} type={handleTableStatus(`${code}`)} />
      </div>
    </Card>
  );
}

export default ProjectDetailsCard;
