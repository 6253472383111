import { call, put } from 'redux-saga/effects';
import { ApiRequest } from '@api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { SyncLotsWithExactParam } from '../../types';
import { projectActions } from '../slice/projectsSlice';

async function callApi(props: SyncLotsWithExactParam) {
  const queryParam = {
    idProject: props.payload.idProject,
  };
  return ApiRequest(Method.GET, ENDPOINTS.syncLotsWithExact, undefined, queryParam, {});
}

export default function* callSyncLotsWithExact(payload: SyncLotsWithExactParam) {
  try {
    yield call(callApi, payload);
    yield put(projectActions.projectSyncLotsWithExactSuccess());
  } catch (error: unknown) {
    console.error('callSyncLotsWithExact', error);
    yield put(projectActions.projectSyncLotsWithExactFailure());
  }
}
