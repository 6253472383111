import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FetchLotTypesParams,
  FetchLotTypesResponse,
  FetchProjectMaterialPriceParam,
  FetchProjectsParams,
  FetchProjectsResponse,
  MaterialPrice,
  PostTimeSlotModal,
  PostTimeSlotResp,
  ProjectState,
  SyncLotsWithExactParam,
  UpdateFloorAmountPayload,
  UpdateMaterialPricePayload,
} from '../../types';

const initialState: ProjectState = {
  fetchProjectsIsLoading: false,
  projects: {
    count: 0,
    data: [],
    totalPages: 0,
  },
  fetchProjectsHasError: false,

  fetchLotTypesIsLoading: false,
  lotTypes: {
    count: 0,
    data: [],
    totalPages: 0,
  },
  fetchLotTypesHasError: false,

  updateFloorAmountIsLoading: false,
  updateFloorAmountIsSuccess: false,
  updateFloorAmountHasError: false,

  fetchMaterialPriceIsLoading: false,
  materialPrice: [],
  fetchMaterialPriceHasError: false,

  updateMaterialPriceIsLoading: false,
  updateMaterialPriceIsSuccess: false,
  updateMaterialPriceHasError: false,

  updateTimeSlotIsLoading: false,
  updateTimeSlotIsSuccess: false,
  updateTimeSlotHasError: false,
  postTimeSlotIsValid: true,
  postTimeSlotMessage: null,

  deleteTimeSlotIsLoading: false,
  deleteTimeSlotIsSuccess: false,
  deleteTimeSlotHasError: false,

  projectSyncWithExactSuccess: false,
  projectSyncWithExactIsLoading: false,
  projectSyncWithExactHasError: false,

  projectSyncLotsWithExactIsLoading: false,
  projectSyncLotsWithExactIsSuccess: false,
  projectSyncLotsWithExactHasError: false,
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchProjects(state, action: PayloadAction<FetchProjectsParams>) {
      state.fetchProjectsIsLoading = true;
      state.fetchProjectsHasError = false;
    },
    fetchProjectsSuccess(state, action: PayloadAction<FetchProjectsResponse>) {
      state.fetchProjectsIsLoading = false;
      state.projects = {
        count: action.payload.count,
        data: action.payload.getProjects,
        totalPages: action.payload.totalPages,
      };
    },
    fetchProjectsFailure(state) {
      state.fetchProjectsIsLoading = false;
      state.fetchProjectsHasError = true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchLotTypes(state, action: PayloadAction<FetchLotTypesParams>) {
      state.fetchLotTypesIsLoading = true;
      state.fetchProjectsHasError = false;
    },
    fetchLotTypesSuccess(state, action: PayloadAction<FetchLotTypesResponse>) {
      state.fetchLotTypesIsLoading = false;
      state.lotTypes = {
        count: action.payload.count,
        data: action.payload.project,
        totalPages: action.payload.totalPages,
      };
    },
    fetchLotTypesFailure(state) {
      state.fetchLotTypesIsLoading = false;
      state.fetchProjectsHasError = true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateFloorAmount(state, action: PayloadAction<UpdateFloorAmountPayload>) {
      state.updateFloorAmountIsLoading = true;
      state.updateFloorAmountIsSuccess = false;
      state.updateFloorAmountHasError = false;
    },
    updateFloorAmountSuccess(state) {
      state.updateFloorAmountIsLoading = false;
      state.updateFloorAmountIsSuccess = true;
    },
    updateFloorAmountFailure(state) {
      state.updateFloorAmountIsLoading = false;
      state.updateFloorAmountHasError = true;
    },
    resetUpdateFloorAmount(state) {
      state.updateFloorAmountIsLoading = false;
      state.updateFloorAmountIsSuccess = false;
      state.updateFloorAmountHasError = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchMaterialPrice(state, action: PayloadAction<FetchProjectMaterialPriceParam>) {
      state.fetchMaterialPriceIsLoading = true;
      state.fetchMaterialPriceHasError = false;
    },
    fetchMaterialPriceSuccess(state, action: PayloadAction<MaterialPrice[]>) {
      state.fetchMaterialPriceIsLoading = false;
      state.materialPrice = action.payload;
    },
    fetchMaterialPriceFailure(state) {
      state.fetchMaterialPriceIsLoading = false;
      state.fetchMaterialPriceHasError = true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateMaterialPrice(state, action: PayloadAction<UpdateMaterialPricePayload>) {
      state.updateMaterialPriceIsLoading = true;
      state.updateMaterialPriceIsSuccess = false;
      state.updateMaterialPriceHasError = false;
    },
    updateMaterialPriceSuccess(state) {
      state.updateMaterialPriceIsLoading = false;
      state.updateMaterialPriceIsSuccess = true;
    },
    updateMaterialPriceFailure(state) {
      state.updateMaterialPriceIsLoading = false;
      state.updateMaterialPriceHasError = true;
    },
    resetUpdateMaterialPrice(state) {
      state.updateMaterialPriceIsLoading = false;
      state.updateMaterialPriceIsSuccess = false;
      state.updateMaterialPriceHasError = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateTimeSlot(state, action: PayloadAction<PostTimeSlotModal>) {
      state.updateTimeSlotIsLoading = true;
      state.updateTimeSlotIsSuccess = false;
      state.updateTimeSlotHasError = false;
    },
    updateTimeSlotSuccess(state, action: PayloadAction<PostTimeSlotResp>) {
      state.updateTimeSlotIsLoading = false;
      state.postTimeSlotIsValid = action.payload.success;
      state.postTimeSlotMessage = action.payload.message;
      state.updateTimeSlotIsSuccess = true;
    },
    updateTimeSlotFailure(state) {
      state.updateTimeSlotIsLoading = false;
      state.updateTimeSlotHasError = true;
    },
    resetTimeSlot(state) {
      state.updateTimeSlotIsLoading = false;
      state.updateTimeSlotIsSuccess = false;
      state.updateTimeSlotHasError = false;
      state.postTimeSlotIsValid = true;
      state.postTimeSlotMessage = null;
    },
    projectSyncWithExact(state) {
      state.projectSyncWithExactIsLoading = true;
    },
    projectSyncWithExactIsSuccess(state) {
      state.projectSyncWithExactIsLoading = false;
      state.projectSyncWithExactSuccess = true;
    },
    projectSyncWithExactFailure(state) {
      state.projectSyncWithExactIsLoading = false;
      state.projectSyncWithExactHasError = true;
    },
    resetProjectSyncWithExact(state) {
      state.projectSyncWithExactSuccess = false;
      state.projectSyncWithExactIsLoading = false;
      state.projectSyncWithExactHasError = false;
    },
    projectSyncLotsWithExact(state, action: PayloadAction<SyncLotsWithExactParam>) {
      state.projectSyncLotsWithExactIsLoading = true;
    },
    projectSyncLotsWithExactSuccess(state) {
      state.projectSyncLotsWithExactIsLoading = false;
      state.projectSyncLotsWithExactIsSuccess = true;
    },
    projectSyncLotsWithExactFailure(state) {
      state.projectSyncLotsWithExactIsLoading = false;
      state.projectSyncLotsWithExactHasError = true;
    },
    resetProjectSyncLotsWithExact(state) {
      state.projectSyncLotsWithExactIsLoading = false;
      state.projectSyncLotsWithExactIsSuccess = false;
      state.projectSyncLotsWithExactHasError = false;
    },
    deleteTimeSlot(state, action: PayloadAction<number>) {
      state.deleteTimeSlotIsLoading = true;
      state.deleteTimeSlotIsSuccess = false;
      state.deleteTimeSlotHasError = false;
    },
    deleteTimeSlotSuccess(state) {
      state.deleteTimeSlotIsLoading = false;
      state.deleteTimeSlotIsSuccess = true;
      state.deleteTimeSlotHasError = false;
    },
    deleteTimeSlotFailure(state) {
      state.deleteTimeSlotIsLoading = false;
      state.deleteTimeSlotIsSuccess = false;
      state.deleteTimeSlotHasError = true;
    },
    resetDeleteTimelSlot(state) {
      state.deleteTimeSlotIsLoading = false;
      state.deleteTimeSlotIsSuccess = false;
      state.deleteTimeSlotHasError = false;
    },
  },
});

// Actions
export const projectActions = projectsSlice.actions;

// Reducer
const projectReducer = projectsSlice.reducer;
export default projectReducer;
