import CustomDialog from '@shared/components/modal/CustomDialog';
import React, { useEffect, useMemo } from 'react';
import { Client, UpdateClientPortal } from '@clients/types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import TextInputField from '../../../shared/components/textField/TextInputField';
import SelectInputField from '../../../shared/components/selectField/SelectInputField';
import { styled } from '@mui/material/styles';
import { ReactComponent as WarningIcon } from '../../../../assets/images/warning.svg';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import { clientActions } from '../store/slice/clientSlice';
import {
  availablePortalStatusSelector,
  updateClientPortalIsLoadingSelector,
  updateClientPortalIsSuccessSelector,
} from '../store/selectors/clientSelector';
import ErrorToast from '../../../shared/components/toast/ErrorToast';

const WarningText = styled('p')`
  color: #ec4c29;
  font-size: 14px;
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.3px;
`;

interface EditPortalModalProps {
  openModal: boolean;
  closeModal: () => void;
  selectedClient: Client;
  refreshClients: () => void;
}

export default function EditPortalModal(props: EditPortalModalProps) {
  const { openModal, closeModal, selectedClient, refreshClients } = props;
  const dispatch = useAppDispatch();
  const availablePortalStatus = useAppSelector(availablePortalStatusSelector);
  const updateClientPortalIsLoading = useAppSelector(updateClientPortalIsLoadingSelector);
  const updateClientPortalIsSuccess = useAppSelector(updateClientPortalIsSuccessSelector);

  useEffect(() => {
    if (openModal) {
      dispatch(clientActions.fetchAvailablePortalStatus());
    }
  }, [dispatch, openModal]);

  useEffect(() => {
    if (updateClientPortalIsSuccess) {
      refreshClients();
      closeModal();
      dispatch(clientActions.resetUpdateClientPortal());
    }
  }, [closeModal, dispatch, refreshClients, updateClientPortalIsSuccess]);

  const portalStatusOptions = useMemo(() => {
    if (availablePortalStatus) {
      return availablePortalStatus.filter((i) => i.code === 1 || i.code === 7);
    }
  }, [availablePortalStatus]);

  if (!selectedClient) return null;
  if (!openModal) return null;

  function handleSubmit(values: UpdateClientPortal) {
    if (values?.numberOfFloors === '0') {
      ErrorToast('Vloer niet geselecteerd', 'Selecteer vloer');
    } else if (values?.portalStatus === 'Selecteer portalstatus') {
      ErrorToast(undefined, 'Portaalstatus mag niet leeg zijn');
    } else {
      dispatch(clientActions.updateClientPortal(values));
    }
  }

  const initialValues: UpdateClientPortal = {
    id: selectedClient.idclient,
    IdLot: selectedClient.idLots,
    name: selectedClient.lots,
    portalStatus: selectedClient.statusCode,
    numberOfFloors: selectedClient.floors,
  };

  const validationSchema = Yup.object().shape({
    // portalStatus: Yup.string().required(),
    // noOfFloors: Yup.number().integer().min(1).required(),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ dirty, isValid, values, submitForm }) => {
        const hasPortalStatusChanged = initialValues.portalStatus !== values.portalStatus;
        return (
          <Form>
            <CustomDialog
              open={openModal}
              handleClose={closeModal}
              title={'Portaal bewerken'}
              cancelButtonText={'Annuleren'}
              handleCancelButtonPress={closeModal}
              primaryButtonText={'Opslaan'}
              primaryButtonDisabled={!isValid || !dirty || updateClientPortalIsLoading}
              handlePrimaryButtonPress={submitForm}
            >
              <Grid container rowGap={2}>
                <Grid item xs={12}>
                  <TextInputField label={'Naam'} name="name" disabled />
                </Grid>
                {/* <Grid item xs={12}>
                  <SelectInputField
                    label={'Aantal verdiepingen'}
                    placeholder={'Selecteer het aantal verdiepingen'}
                    placeholderValue={0}
                    name="numberOfFloors"
                    options={[
                      { label: '1', value: 1 },
                      { label: '2', value: 2 },
                      { label: '3', value: 3 },
                      { label: '4', value: 4 },
                      { label: '5', value: 5 },
                    ]}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <SelectInputField
                    label={'Portaal status'}
                    name="portalStatus"
                    placeholder={'Selecteer portalstatus'}
                    options={portalStatusOptions?.map((i) => ({ label: i.status, value: i.idClientStatus }))}
                  />
                  {hasPortalStatusChanged && (
                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mt={'4px'}>
                      <WarningIcon style={{ marginRight: '2px' }} />
                      <WarningText>Het aanpassen van de status heeft invloed op de geautomatiseerde flow</WarningText>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </CustomDialog>
          </Form>
        );
      }}
    </Formik>
  );
}
