import { call, put } from 'redux-saga/effects';
import { DEFAULT_PAGE_SIZE, ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { productActions } from '../slice/productsSlice';
import { ApiRequest } from '../../../../api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { FetchMaterialColorParams, FetchMaterialColorResponse } from '../../types';

async function callApi(props: FetchMaterialColorParams) {
  const queryParam = {
    color: props.color,
    filename: props.filename,
    pageSize: props?.pageSize ?? DEFAULT_PAGE_SIZE,
    pageNumber: props?.pageNumber ?? 1,
    KeyProperty: props?.KeyProperty,
    IsAscending: props?.IsAscending,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getProductMaterialColor, { id: props.id }, queryParam);
}

export default function* callFetchProductMaterialColorsSaga({ payload }: PayloadAction<FetchMaterialColorParams>) {
  try {
    const fetchMaterialColorResponse: FetchMaterialColorResponse = yield call(callApi, payload);
    yield put(productActions.fetchProductMaterialColorsSuccess(fetchMaterialColorResponse));
  } catch (error: unknown) {
    console.error('callFetchProductMaterialColorsSaga', error);
    yield put(productActions.fetchProductMaterialColorsFailure());
  }
}
