import { CustomTableDataCell } from '@shared/components/customTable/CustomTable';
import { IconButton } from '@mui/material';
import React from 'react';
import { TableBodyRow } from '@shared/types';
import { User } from '../types';
import { ReactComponent as EditIcon } from '@assets/images/tableRowIcons/edit.svg';
import { ReactComponent as DeleteIcon } from '@assets/images/tableRowIcons/delete.svg';
import { styled } from '@mui/material/styles';
import LightTooltip from '../../../utils/lightTooltip';

const AdminLabel = styled('span')`
  border-radius: 3px;
  background: #bd8890;
  padding: 2px 4px;
  font-size: 14px;
  color: #fffffe;
  height: 24px;
  display: flex;
  width: fit-content;
`;

const UserLabel = styled('span')`
  border-radius: 3px;
  background: #4b7c8a;
  padding: 2px 4px;
  font-size: 14px;
  color: #fffffe;
  height: 24px;
  display: flex;
  width: fit-content;
`;

function AuthLabel(props: { authorization: string }) {
  const { authorization } = props;
  switch (authorization) {
    case 'Admin':
      return <AdminLabel>Admin</AdminLabel>;
    case 'User':
    default:
      return <UserLabel>Gebruiker</UserLabel>;
  }
}

export default function userTableData(
  userData: User[],
  tableHeaderWidth: (string | undefined)[],
  handleEditUserClick: (a: User) => void,
  handleDeleteUserClick: (a: User) => void
): TableBodyRow[] {
  return userData.map((user: User) => {
    return {
      id: `${user.idUser}`,
      tableCell: (
        <>
          <CustomTableDataCell width={tableHeaderWidth[0] ?? 'unset'} fontWeight={700}>
            {user.fullName}
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[1] ?? 'unset'} fontWeight={700}>
            {user.email}
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[2] ?? 'unset'}>
            <AuthLabel authorization={user.authorization} />
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[3] ?? 'unset'}>
            <LightTooltip title="Bewerk gebruiker">
              <IconButton
                className={'table-icon'}
                onClick={() => handleEditUserClick(user)}
                sx={{
                  padding: '5px',
                  '&:disabled': {
                    opacity: '0 !important',
                  },
                }}
              >
                <EditIcon width={16} height={16} />
              </IconButton>
            </LightTooltip>
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[4] ?? 'unset'}>
            <LightTooltip title="Verwijder gebruiker">
              <IconButton
                className={'table-icon'}
                onClick={() => handleDeleteUserClick(user)}
                sx={{
                  padding: '5px',
                  '&:disabled': {
                    opacity: '0 !important',
                  },
                }}
              >
                <DeleteIcon width={16} height={16} />
              </IconButton>
            </LightTooltip>
          </CustomTableDataCell>
        </>
      ),
    };
  });
}
