import { call, put } from 'redux-saga/effects';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { CreateQuote } from '../../types';
import { PayloadAction } from '@reduxjs/toolkit';

async function callApi(props: CreateQuote) {
  const queryParam = {
    idProject: props.idProject,
  };
  const pathParam = {
    id: props.id,
  };
  return ApiRequest(Method.POST, ENDPOINTS.createQuote, pathParam, queryParam, undefined);
}

export default function* callCreateQuoteSaga({ payload }: PayloadAction<CreateQuote>) {
  try {
    yield call(callApi, payload);
    yield put(clientActions.createQuoteInChangeStyleSuccess());
    SuccessToast('Succes!', 'Offerte is succesvol aangemaakt');
  } catch (error: unknown) {
    console.error('callCreateQouteSaga', error);
    yield put(clientActions.createQuoteInChangeStyleFailure());
    ErrorToast(undefined, error.toString());
  }
}
