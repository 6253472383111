import { call, put } from 'redux-saga/effects';
import { UpdateClientTemplatePayload } from '../../types';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { Method } from '../../../../api/types';

async function callApi(payload: UpdateClientTemplatePayload) {
  const windowsToUpload = payload.windows.map((i) => {
    return {
      floors: i.floors,
      height: i.height,
      idWindows: i.idWindows < 0 ? 0 : i.idWindows,
      numberOfWindows: i.numberOfWindows,
      width: i.width,
    };
  });
  const queryParam = {
    LotTypeId: payload.idLotType,
    LotsId: payload.idLot,
  };
  const dataToUpload = {
    idLot: payload.idLot,
    idLotType: payload.idLotType,
    templetes: payload.templetes,
    windows: windowsToUpload,
  };
  return ApiRequest(Method.POST, ENDPOINTS.selectTemplate, undefined, queryParam, dataToUpload);
}

export default function* callUpdateClientTemplateSaga({ payload }: PayloadAction<UpdateClientTemplatePayload>) {
  try {
    yield call(callApi, payload);
    yield put(clientActions.updateClientTemplateSuccess());
    SuccessToast('Success!', 'Templates succesvol gewijzigd');
  } catch (error: unknown) {
    console.error('callUpdateClientTemplateSaga', error);
    yield put(clientActions.updateClientTemplateFailure());
    ErrorToast(undefined, error.toString());
  }
}
