import { FilterType, TableHeaders } from '../../../shared/types';

export default function productsTableHeaders(
  typeFilter: string,
  setTypeFilter: (a: string) => void,
  groupFilter: string,
  setGroupFilter: (a: string) => void,
  colorsFilter: number | undefined,
  setColorsFilter: (a: number | undefined) => void,
  statusFilter: string,
  setStatusFilter: (a: string) => void
): TableHeaders[] {
  return [
    {
      label: 'Beschrijving',
      isSortable: true,
      filterPlaceHolder: 'Filter beschrijving...',
      filterType: FilterType.search,
      filterValue: typeFilter,
      onFilterChange: setTypeFilter,
      columnName: 'Type',
    },
    {
      label: 'Groep',
      isSortable: true,
      filterPlaceHolder: 'Filter groep...',
      filterType: FilterType.search,
      filterValue: groupFilter,
      onFilterChange: setGroupFilter,
      columnName: 'Group',
      width: '25%',
    },
    {
      label: 'Kleuren',
      isSortable: true,
      filterPlaceHolder: 'Filter kleuren...',
      columnName: 'Color',
      width: '15%',
      filterType: FilterType.search,
      filterValue: colorsFilter,
      onFilterChange: setColorsFilter,
    },
    {
      label: 'Status',
      isSortable: true,
      filterPlaceHolder: 'Filter status...',
      filterType: FilterType.select,
      filterValue: statusFilter,
      filterOptions: [
        { value: 'Voltooid', label: 'Voltooid' },
        { value: 'Er ontbreekt informatie', label: 'Er ontbreekt informatie' },
        { value: 'Afbeelding voor brochure ontbreekt', label: 'Afbeelding voor brochure ontbreekt' },
        { value: 'Afbeeldingen voor kleuren ontbreken', label: 'Afbeeldingen voor kleuren ontbreken' },
        { value: 'Afbeelding voor producttype ontbreekt', label: 'Afbeelding voor producttype ontbreekt' },
      ],
      onFilterChange: setStatusFilter,
      columnName: 'status',
      width: '20%',
    },
    {
      label: '',
      columnName: 'edit',
      align: 'center',
      width: '34px',
    },
  ];
}
