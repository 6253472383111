import { call, put } from 'redux-saga/effects';
import { PostChosenStylesParams } from '../../types';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { Method } from '../../../../api/types';

async function callApi(props: PostChosenStylesParams) {
  const payload = props.values.updateChosenStyles
    .filter((i) => Number(i.idProductType) !== -1)
    .map((i) => ({
      idClient: props.values.clientId,
      idTemplete: props.idTemplete,
      idDefaultTemplete: props.idDefaultTemplete,
      idProductType: Number(i.idProductType),
      idProductGroup: Number(i.idProductGroup),
      idMaterialColor: Number(i.idMaterialColor),
      floors: Number(i.floors),
    }));
  if (payload.length > 0) {
    return ApiRequest(Method.POST, ENDPOINTS.postChosenStyles, undefined, undefined, payload);
  }
}

export default function* callPostChosenStylesSaga({ payload }: PayloadAction<PostChosenStylesParams>) {
  try {
    const resp = yield call(callApi, payload);
    yield put(clientActions.postChosenStylesIsSuccess());
    if (resp) {
      SuccessToast('Succes!', 'Je hebt de gekozen stijl toegevoegd');
    }
  } catch (error: unknown) {
    console.error('callPostChosenStylesSaga', error);
    yield put(clientActions.postChosenStylesFailure());
    ErrorToast(undefined, error.toString());
  }
}
