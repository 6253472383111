import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { User } from '../types';
import CustomDialog from '../../../shared/components/modal/CustomDialog';
import { userActions } from '../store/slice/usersSlice';
import { useAppSelector } from '../../../store/types';
import { deleteUserIsLoadingSelector, deleteUserIsSuccessSelector } from '../store/selectors/userSelectors';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const StyledTitle = styled('p')`
  color: #222;
  font-size: 16px;
  font-weight: 300;
  line-height: 110%;
  letter-spacing: 0.64px;
  text-align: center;
`;

const StyledUserName = styled('p')`
  color: #0c1b2a;
  font-size: 16px;
  font-weight: 700;
  line-height: 110%;
`;

interface DeleteUserModalProps {
  openModal: boolean;
  closeModal: () => void;
  selectedUser: User;
  fetchUsers: () => void;
}

export default function DeleteUserModal(props: DeleteUserModalProps) {
  const { openModal, closeModal, selectedUser, fetchUsers } = props;
  const dispatch = useDispatch();

  const deleteUserIsLoading = useAppSelector(deleteUserIsLoadingSelector);
  const deleteUserIsSuccess = useAppSelector(deleteUserIsSuccessSelector);

  useEffect(() => {
    if (deleteUserIsSuccess) {
      closeModal();
      fetchUsers();
      dispatch(userActions.resetDeleteUser());
    }
  }, [closeModal, dispatch, deleteUserIsSuccess, fetchUsers]);

  if (!openModal) return null;

  function handleSubmit() {
    dispatch(userActions.deleteUser(selectedUser.idUser));
  }

  return (
    <CustomDialog
      open={openModal}
      handleClose={closeModal}
      cancelButtonText={'Annuleren'}
      handleCancelButtonPress={closeModal}
      primaryButtonText={'Verwijderen'}
      primaryButtonDisabled={deleteUserIsLoading}
      handlePrimaryButtonPress={handleSubmit}
      footerAlign={'center'}
      showCloseButton={false}
    >
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <StyledTitle>Weet je zeker dat je het volgende wilt verwijderen </StyledTitle>
        <StyledUserName>{selectedUser?.fullName}</StyledUserName>
      </Box>
    </CustomDialog>
  );
}
