import { ApiRequest } from '../../../../api/ApiService';
import { Method } from '../../../../api/types';
import { ENDPOINTS } from '../../../../api/constants';
import { PostTimeSlotModal, TimeSlot } from '../../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import { projectActions } from '../slice/projectsSlice';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';

async function callApi(data: PostTimeSlotModal) {
  const queryParam = {
    ProjectId: data.idProject,
  };
  const timeSlots: TimeSlot[] = data?.timeSlots?.map((d) => {
    return {
      idStyleRoom: d.idStyleRoom && d.idStyleRoom > 0 ? d.idStyleRoom : 0,
      bookedDate: d.bookedDate,
      from: d.from,
      to: d.to,
    };
  });
  const dataToUpload: PostTimeSlotModal = {
    idProject: data.idProject,
    timeSlots: timeSlots,
  };
  return ApiRequest(Method.POST, ENDPOINTS.updateTimeSlots, undefined, queryParam, dataToUpload);
}

export default function* callUpdateTimeSlotSaga({ payload }: PayloadAction<PostTimeSlotModal>) {
  try {
    const response = yield call(callApi, payload);
    const isNotValidDate = response.message.includes('is al geboekt');
    if (!isNotValidDate) {
      SuccessToast('Succes!', 'Tijdslot ingesteld in kalender');
    }
    yield put(projectActions.updateTimeSlotSuccess(response));
  } catch (error) {
    console.error('callUpdateTimeSlotSaga Error', error);
    yield put(projectActions.updateTimeSlotFailure());
    ErrorToast(undefined, error.toString());
  }
}
