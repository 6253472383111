import { call, put } from 'redux-saga/effects';
import { ApiRequest } from '@api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { projectActions } from '../slice/projectsSlice';

async function callApi() {
  return ApiRequest(Method.GET, ENDPOINTS.syncWithExact, undefined, undefined);
}

export default function* callSyncWithExact() {
  try {
    yield call(callApi);
    yield put(projectActions.projectSyncWithExactIsSuccess());
  } catch (error: unknown) {
    yield put(projectActions.projectSyncWithExactFailure());
    console.error('callSyncWithExact', error);
  }
}
