import React from 'react';
import { Grid } from '@mui/material';
import MSLogoSvg from '@assets/images/msLogo.svg';
import classes from './Login.module.css';

export default function LoginPage() {
  function handleLoginClick() {
    const loginURL = process.env.REACT_APP_LOGIN_URL;
    if (loginURL) {
      window.location.href = loginURL;
    }
  }

  return (
    <Grid container style={{ minHeight: '100vh' }}>
      <Grid item xs={12} md={6} sm={12} className={classes.leftRoot}>
        <div className={classes.leftContentWrapper}>
          <label className={classes.headerLabel}>admin.stijlpartner</label>
          <label className={classes.subHeader}>
            Powered by Nimble Smart Layer<sup style={{ fontSize: '8px' }}>TM</sup> technologies
          </label>
          <label className={classes.description}>
            The Nimble Smart Layer empowers people with the most advanced technologies to easily automate, analyze and
            integrate data. It offers inifinite possibilities in one highly efficient layer on top of all core systems.
          </label>
        </div>
      </Grid>
      <Grid item xs={12} md={6} sm={12} className={classes.rightRoot}>
        <div className={classes.rightContent}>
          <div className={classes.logoWrapper}>
            <label className={classes.welcomLabel}>Welkom bij</label>
            <label className={classes.headerLabelRight}>admin.stijlpartner</label>
          </div>
          <div className={classes.button} onClick={handleLoginClick}>
            <div className={classes.leftWrapper}>
              <img src={MSLogoSvg} alt="" />
            </div>
            <div className={classes.rightWrapper}>
              <label className={classes.buttonLabel}>Login met Microsoft</label>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
