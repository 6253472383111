import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateUserPayload, EditUserPayload, FetchUsersParams, FetchUsersResponse, UserState } from '../../types';

const initialState: UserState = {
  fetchUsersHasError: false,
  fetchUsersIsLoading: false,
  users: {
    count: 0,
    data: [],
    totalPages: 0,
  },
  createUserHasError: false,
  createUserIsLoading: false,
  createUserIsSuccess: false,

  editUserIsLoading: false,
  editUserIsSuccess: false,
  editUserHasError: false,

  deleteUserIsLoading: false,
  deleteUserIsSuccess: false,
  deleteUserHasError: false,
};

const usersSlice = createSlice({
  name: 'Users',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchUsers(state, action: PayloadAction<FetchUsersParams>) {
      state.fetchUsersIsLoading = true;
      state.fetchUsersHasError = false;
    },
    fetchUsersSuccess(state, action: PayloadAction<FetchUsersResponse>) {
      state.fetchUsersIsLoading = false;
      state.users = {
        count: action.payload.count,
        data: action.payload.vwUsers,
        totalPages: action.payload.totalPages,
      };
    },
    fetchUsersFailure(state) {
      state.fetchUsersIsLoading = false;
      state.fetchUsersHasError = true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createUser(state, action: PayloadAction<CreateUserPayload>) {
      state.createUserIsLoading = true;
      state.createUserIsSuccess = false;
      state.createUserHasError = false;
    },
    createUserSuccess(state) {
      state.createUserIsLoading = false;
      state.createUserIsSuccess = true;
    },
    createUserFailure(state) {
      state.createUserIsLoading = false;
      state.createUserHasError = true;
    },
    resetCreateUser(state) {
      state.createUserIsLoading = false;
      state.createUserIsSuccess = false;
      state.createUserHasError = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    editUser(state, action: PayloadAction<EditUserPayload>) {
      state.editUserIsLoading = true;
      state.editUserIsSuccess = false;
      state.editUserHasError = false;
    },
    editUserSuccess(state) {
      state.editUserIsLoading = false;
      state.editUserIsSuccess = true;
    },
    editUserFailure(state) {
      state.editUserIsLoading = false;
      state.editUserHasError = true;
    },
    resetEditUser(state) {
      state.editUserIsLoading = false;
      state.editUserIsSuccess = false;
      state.editUserHasError = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteUser(state, action: PayloadAction<number>) {
      state.deleteUserIsLoading = true;
      state.deleteUserIsSuccess = false;
      state.deleteUserHasError = false;
    },
    deleteUserSuccess(state) {
      state.deleteUserIsLoading = false;
      state.deleteUserIsSuccess = true;
    },
    deleteUserFailure(state) {
      state.deleteUserIsLoading = false;
      state.deleteUserHasError = true;
    },
    resetDeleteUser(state) {
      state.deleteUserIsLoading = false;
      state.deleteUserIsSuccess = false;
      state.deleteUserHasError = false;
    },
  },
});

// Actions
export const userActions = usersSlice.actions;

// Reducer
const userReducer = usersSlice.reducer;
export default userReducer;
