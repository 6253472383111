import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MaterialColor } from '../types';
import CustomDialog from '../../../shared/components/modal/CustomDialog';
import { productActions } from '../store/slice/productsSlice';
import { useAppSelector } from '../../../store/types';
import {
  deleteProductColorImageIsLoadingSelector,
  deleteProductColorImageIsSuccessSelector,
} from '../store/selectors/productsSelector';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const StyledTitle = styled('p')`
  color: #222;
  font-size: 16px;
  font-weight: 300;
  line-height: 110%;
  letter-spacing: 0.64px;
  text-align: center;
`;

const StyledUserName = styled('p')`
  color: #0c1b2a;
  font-size: 16px;
  font-weight: 700;
  line-height: 110%;
`;

interface DeleteProductColorModalProps {
  openModal: boolean;
  closeModal: () => void;
  selectedMaterialColor: MaterialColor | undefined;
  fetchMaterialColor: () => void;
  fetchProducts: () => void;
}

export default function DeleteProductColorImageModal(props: DeleteProductColorModalProps) {
  const { openModal, closeModal, selectedMaterialColor, fetchMaterialColor, fetchProducts } = props;
  const dispatch = useDispatch();

  const deleteProductColorImageIsLoading = useAppSelector(deleteProductColorImageIsLoadingSelector);
  const deleteProductColorImageIsSuccess = useAppSelector(deleteProductColorImageIsSuccessSelector);

  useEffect(() => {
    if (deleteProductColorImageIsSuccess) {
      closeModal();
      fetchMaterialColor();
      fetchProducts();
      dispatch(productActions.resetDeleteProductColorImage());
    }
  }, [closeModal, dispatch, deleteProductColorImageIsSuccess, fetchMaterialColor, fetchProducts]);

  if (!openModal) return null;

  function handleSubmit() {
    if (selectedMaterialColor?.idMaterialColor) {
      dispatch(productActions.deleteProductColorImage(selectedMaterialColor?.idMaterialColor));
    }
  }

  return (
    <CustomDialog
      open={openModal}
      handleClose={closeModal}
      cancelButtonText={'Annuleren'}
      handleCancelButtonPress={closeModal}
      primaryButtonText={'Verwijderen'}
      primaryButtonDisabled={deleteProductColorImageIsLoading}
      handlePrimaryButtonPress={handleSubmit}
      footerAlign={'center'}
      showCloseButton={false}
    >
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <StyledTitle>Weet u zeker dat u de kleur wilt verwijderen? </StyledTitle>
        <StyledUserName>{selectedMaterialColor?.colorName}</StyledUserName>
      </Box>
    </CustomDialog>
  );
}
