import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react';
import { styled } from '@mui/material/styles';

export const StyledInput = styled('input')<{ $disabled: boolean; $color: string }>`
  padding: 8px;
  gap: 8px;
  width: 100%;
  height: 34px;
  border: 1px solid #9a9fa5;
  border-radius: 5px;
  font-weight: 300;
  font-size: 16px;
  font-family: 'Public-sans';
  line-height: 110%;
  color: ${({ $color, $disabled }) => ($disabled ? '#50606b' : $color)};
  background-color: ${({ $disabled }) => ($disabled ? 'rgba(12, 27, 42, 0.06)' : 'white')};
  &:focus {
    outline: none;
    opacity: 1;
  }
  &::placeholder {
    color: #25334f;
    opacity: 0.7;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ErrorText = styled('p')`
  color: #fc8181;
  font-size: 12px;
  font-weight: 300;
  line-height: 160%;
  letter-spacing: 0.24px;
  margin-top: 4px;
`;

const StyledSpan = styled('span')`
  color: #fc8181;
  font-size: 14px;
  margin-bottom: 8px;
  margin-left: 4px;
`;

const StyledLabelContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

const StyledHint = styled('p')`
  color: #50606b;
  font-size: 12px;
  font-weight: 300;
  line-height: 160%;
  letter-spacing: 0.24px;
  margin-top: 4px;
`;

const StyledLabel = styled('p')<{
  labelColor: string;
  disabled: boolean;
}>`
  line-height: 110%;
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  color: ${({ labelColor, disabled }) => (disabled ? '#50606b' : labelColor)};
  margin-bottom: 4px;
`;

interface Props {
  label?: string;
  name: string;
  type?: string;
  required?: boolean;
  markAsRequired?: boolean;
  placeholder?: string;
  hint?: string;
  disabled?: boolean;
  labelColor?: string;
  color?: string;
}

export default function TextInputField(props: Props) {
  const {
    label,
    labelColor = '#0c1b2a',
    name,
    type = 'text',
    required = false,
    markAsRequired = false,
    placeholder = '',
    hint = '',
    disabled = false,
    color = '#50606b',
  } = props;
  const renderError = (message: string) => <ErrorText>{message}</ErrorText>;
  const { errors } = useFormikContext();
  // @ts-ignore
  const hasError = errors[name];

  return (
    <>
      {label && (
        <StyledLabelContainer>
          <StyledLabel labelColor={labelColor} disabled={disabled}>
            {label}
          </StyledLabel>
          {!disabled && markAsRequired && <StyledSpan>*</StyledSpan>}
        </StyledLabelContainer>
      )}
      <Field
        as={StyledInput}
        placeholder={placeholder}
        type={type}
        required={required}
        name={name}
        disabled={disabled}
        $disabled={disabled}
        $color={color}
      />
      {hint && !hasError && <StyledHint>{hint}</StyledHint>}
      <ErrorMessage name={name} render={renderError} />
    </>
  );
}
