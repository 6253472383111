import { useDispatch } from 'react-redux';
import { ImageData, MaterialColor, Product, UploadColorImage } from '../types';
import React, { useCallback, useEffect, useState } from 'react';
import CustomDialog from '../../../shared/components/modal/CustomDialog';
import { Grid, IconButton } from '@mui/material';
import { productActions } from '../store/slice/productsSlice';
import { useAppSelector } from '../../../store/types';
import {
  downloadProductColorImageSelector,
  materialColorsIsLoadingSelector,
  materialColorsSelector,
  materialColorsTotalPagesSelector,
  uploadProductColorImageIsLoadingSelector,
  uploadProductColorImageIsSuccessSelector,
} from '../store/selectors/productsSelector';
import { FilterType, TableBodyRow, TableColumnSortDirection, TableHeaders } from '../../../shared/types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import CustomTable, { CustomTableDataCell } from '../../../shared/components/customTable/CustomTable';
import orderBy from 'lodash/orderBy';
import { ReactComponent as DeleteIcon } from '@assets/images/tableRowIcons/delete.svg';
import { ReactComponent as AddIcon } from '@assets/images/add.svg';
import { styled } from '@mui/material/styles';
import TableLinkLabel from '../../../shared/components/tableTextOptions/TableLinkLabel';
import ShowImageModal from './ShowImageModal';
import convertToBase64 from '../../../utils/convertBase64';
import ErrorToast from '../../../shared/components/toast/ErrorToast';
import DeleteProductColorImageModal from './DeleteProductColorImageModal';
import LightTooltip from '../../../utils/lightTooltip';

const Input = styled('input')({
  display: 'none',
});

const UploadImageLabel = styled('p')`
  color: #ec9e29;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`;

const ImageLinkContainer = styled('button')`
  cursor: pointer;
  border: none;
  background: transparent;
`;

function productColorsHeaders(
  colorFilter: string,
  setColorFilter: (value: string) => void,
  imageFilter: string,
  setImageFilter: (value: string) => void,
  colorPickerFilter: string,
  setColorPickerFilter: (value: string) => void
): TableHeaders[] {
  return [
    {
      label: 'Kleur',
      isSortable: true,
      filterPlaceHolder: 'Kleur filter...',
      filterType: FilterType.search,
      filterValue: colorFilter,
      onFilterChange: setColorFilter,
      columnName: 'Color',
    },
    {
      label: `Foto's`,
      isSortable: true,
      filterPlaceHolder: 'Afbeeldingen filteren...',
      filterType: FilterType.search,
      filterValue: imageFilter,
      onFilterChange: setImageFilter,
      columnName: 'Filename',
      width: '40%',
    },
    // {
    //   label: 'Kleurkiezer',
    //   isSortable: true,
    //   filterPlaceHolder: 'Kleur filter...',
    //   filterType: FilterType.search,
    //   filterValue: colorPickerFilter,
    //   onFilterChange: setColorPickerFilter,
    //   columnName: 'totalPrice',
    //   width: '200px',
    // },
    {
      label: '',
      columnName: 'edit',
      align: 'center',
      width: '34px',
    },
  ];
}

function productColorsData(
  materialPriceData: MaterialColor[],
  tableHeaderWidth: (string | undefined)[],
  handleDeleteColorClick: (a: MaterialColor) => void,
  handleUploadImageClick: (a: MaterialColor, e: React.ChangeEvent<HTMLInputElement>) => void,
  handleImageClick: (a: MaterialColor, b: any) => void,
  values: object
): TableBodyRow[] {
  return materialPriceData.map((color: MaterialColor, index) => {
    const selectedValue = values[color.idMaterialColor.toString()];
    return {
      id: `${index}`,
      tableCell: (
        <>
          <CustomTableDataCell width={tableHeaderWidth[0] ?? 'unset'} fontWeight={700}>
            {color.colorName}
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[1] ?? 'unset'} fontWeight={700}>
            {color.fileName ? (
              <ImageLinkContainer onClick={() => handleImageClick(color, selectedValue)}>
                <TableLinkLabel showUnderline fontWeight={400}>
                  {color.fileName}
                </TableLinkLabel>
              </ImageLinkContainer>
            ) : (
              <label htmlFor={`image-file-upload${color.idMaterialColor}`}>
                <Input
                  accept=".png, .jpg, .jpeg, .heic"
                  id={`image-file-upload${color.idMaterialColor}`}
                  type="file"
                  onChange={(event) => handleUploadImageClick(color, event)}
                />
                {selectedValue ? (
                  // <ReadyToUpload>{selectedValue?.fileName}</ReadyToUpload>
                  <ImageLinkContainer onClick={() => handleImageClick(color, selectedValue)}>
                    <TableLinkLabel showUnderline fontWeight={400}>
                      {selectedValue?.fileName}
                    </TableLinkLabel>
                  </ImageLinkContainer>
                ) : (
                  <UploadImageLabel>
                    <AddIcon stroke={'#EC9E29'} style={{ marginRight: '4px' }} />
                    Bestand uploaden
                  </UploadImageLabel>
                )}
              </label>
            )}
          </CustomTableDataCell>
          {/* <CustomTableDataCell width={tableHeaderWidth[2] ?? 'unset'} fontWeight={700}>
            <div />
          </CustomTableDataCell> */}
          <CustomTableDataCell width={tableHeaderWidth[3] ?? 'unset'}>
            {color.fileName && (
              <LightTooltip title="Kleur verwijderen">
                <IconButton
                  onClick={() => handleDeleteColorClick(color)}
                  sx={{
                    padding: '5px',
                    '&:disabled': {
                      opacity: '0 !important',
                    },
                  }}
                >
                  <DeleteIcon width={16} height={16} />
                </IconButton>
              </LightTooltip>
            )}
          </CustomTableDataCell>
        </>
      ),
    };
  });
}

interface ProductColorImageModalProps {
  openModal: boolean;
  closeModal: () => void;
  selectedProduct?: Product;
  fetchProducts: () => void;
}

export default function ProductColorImageModal(props: ProductColorImageModalProps) {
  const { openModal, closeModal, selectedProduct, fetchProducts } = props;
  const dispatch = useDispatch();

  const materialColorsIsLoading = useAppSelector(materialColorsIsLoadingSelector);
  const materialColors = useAppSelector(materialColorsSelector);
  const totalNumberOfPages = useAppSelector(materialColorsTotalPagesSelector);
  // const downloadProductColorImageIsLoading = useAppSelector(downloadProductColorImageIsLoadingSelector);
  const downloadProductColorImage = useAppSelector(downloadProductColorImageSelector);
  const uploadProductColorImageIsLoading = useAppSelector(uploadProductColorImageIsLoadingSelector);
  const uploadProductColorImageIsSuccess = useAppSelector(uploadProductColorImageIsSuccessSelector);

  const [selectedColor, setSelectedColor] = useState<MaterialColor | undefined>();
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [openDeleteProductColorModal, setOpenDeleteProductColorModal] = useState(false);
  const [uploadImages, setUploadImages] = useState<HTMLInputElement[]>([]);

  const [sortBy, setSortBy] = useState<string | undefined>('Color');
  const [sortDirection, setSortDirection] = useState<TableColumnSortDirection>(TableColumnSortDirection.ASC);

  const [colorFilter, setColorFilter] = useState<string>('');
  const [imageFilter, setImageFilter] = useState<string>('');
  const [showImage, setShowImage] = useState<ImageData | null>(null);
  const [colorPickerFilter, setColorPickerFilter] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [blobImage, setBlobImage] = useState<string | null>(null);

  const handleCloseModal = useCallback(() => {
    fetchProducts();
    setUploadImages([]);
    setShowImage(null);
    resetAllFilters();
    setCurrentPage(1);
    closeModal();
  }, [closeModal, fetchProducts]);

  function resetAllFilters() {
    setColorFilter('');
    setImageFilter('');
    setColorPickerFilter('');
  }

  function fetchProductMaterialColors() {
    dispatch(
      productActions.fetchProductMaterialColors({
        id: selectedProduct!.idProduct,
        color: colorFilter,
        filename: imageFilter,
        pageNumber: currentPage,
        KeyProperty: sortBy,
        IsAscending: sortDirection === 'asc' ? true : false,
      })
    );
  }

  useEffect(() => {
    if (openModal) {
      fetchProductMaterialColors();
    }
  }, [
    dispatch,
    currentPage,
    openModal,
    selectedProduct,
    colorFilter,
    imageFilter,
    colorPickerFilter,
    sortBy,
    sortDirection,
  ]);

  useEffect(() => {
    if (uploadProductColorImageIsSuccess) {
      fetchProducts();
      handleCloseModal();
      dispatch(productActions.resetUploadProductColorImage());
    }
  }, [dispatch, handleCloseModal, uploadProductColorImageIsSuccess, fetchProducts]);

  if (!selectedProduct || !openModal) {
    return null;
  }

  function handleCloseDeleteProductColorModal() {
    setSelectedColor(undefined);
    setOpenDeleteProductColorModal(false);
  }

  function handleDeleteProductColorClick(materialColor: MaterialColor) {
    setSelectedColor(materialColor);
    setOpenDeleteProductColorModal(true);
  }

  function handleSubmit(values: object) {
    const uploadArray: UploadColorImage[] = [];
    for (const [key, value] of Object.entries(values)) {
      uploadArray.push({
        idColor: parseInt(key),
        fileName: value.fileName,
        fileData: value.fileData,
      });
    }
    dispatch(productActions.uploadProductColorImage(uploadArray));
  }

  // @ts-ignore
  // const modifiedProductColorData = orderBy(
  //   materialColors,
  //   [(mp) => `${mp[sortBy ?? 'colorName']}`.toLowerCase()],
  //   sortDirection
  // );

  const initialValues = {};

  const validationSchema = Yup.object().shape({});

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
  }

  function handleImageClick(color: MaterialColor, uploadedValue: any) {
    if (uploadedValue) {
      setShowImage(uploadedValue);
    } else {
      setBlobImage(encodeURIComponent(color?.colorBlodId));
    }
    setShowImageModal(true);
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid, values, submitForm, setFieldValue }) => {
          const tableHeaders = productColorsHeaders(
            colorFilter,
            setColorFilter,
            imageFilter,
            setImageFilter,
            colorPickerFilter,
            setColorPickerFilter
          );

          async function handleUploadImageClick(color: MaterialColor, event: React.ChangeEvent<HTMLInputElement>) {
            // @ts-ignore
            const imageFile = event.target.files[0];
            const imageSizeInBytes = imageFile.size;
            const sizeInKB = imageSizeInBytes / (1024 * 1024);
            if (sizeInKB >= 5) {
              ErrorToast(undefined, 'De afbeelding moet kleiner zijn dan 5mb');
            } else {
              const base64 = await convertToBase64(imageFile);
              setFieldValue(color.idMaterialColor.toString(), {
                fileName: imageFile?.name,
                fileData: base64.split(',')[1],
              });
            }
          }

          const tableData = productColorsData(
            materialColors,
            tableHeaders.map((i) => i.width),
            handleDeleteProductColorClick,
            handleUploadImageClick,
            handleImageClick,
            values
          );
          return (
            <Form>
              <CustomDialog
                width={'832px'}
                open={openModal}
                handleClose={handleCloseModal}
                title={`${selectedProduct.type}: <span style="color: #4b7c8a">${selectedProduct.group}</span>`}
                cancelButtonText={'Annuleren'}
                handleCancelButtonPress={handleCloseModal}
                primaryButtonText={'Opslaan'}
                primaryButtonDisabled={!isValid || !dirty || uploadProductColorImageIsLoading}
                handlePrimaryButtonPress={submitForm}
              >
                <ShowImageModal
                  open={showImageModal}
                  image={showImage ? showImage : downloadProductColorImage}
                  isBlobId={showImage === null}
                  blobId={blobImage}
                  handleClose={() => {
                    setShowImageModal(false);
                    setBlobImage(null);
                    dispatch(productActions.resetDownloadProductColorImage());
                  }}
                />
                <Grid container mt={'-24px'}>
                  <CustomTable
                    tableHeaders={tableHeaders}
                    showFilters
                    tableData={tableData}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                    isLoading={materialColorsIsLoading}
                    totalNumberOfPages={totalNumberOfPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    hidePagination={totalNumberOfPages <= 1}
                  />
                </Grid>
              </CustomDialog>
            </Form>
          );
        }}
      </Formik>
      <DeleteProductColorImageModal
        openModal={openDeleteProductColorModal}
        closeModal={handleCloseDeleteProductColorModal}
        selectedMaterialColor={selectedColor}
        fetchMaterialColor={fetchProductMaterialColors}
        fetchProducts={fetchProducts}
      />
    </>
  );
}
