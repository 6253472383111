import { FilterType, TableHeaders } from '@shared/types';

export default function clientTableHeaders(
  lotFilter: string | undefined,
  setLotFilter: (a: string | undefined) => void,
  clientFilter: string | undefined,
  setClientFilter: (a: string | undefined) => void,
  projectFilter: string | undefined,
  setProjectFilter: (a: string | undefined) => void,
  statusFilter: string | undefined,
  setStatusFilter: (a: string | undefined) => void,
  floorFilter: number | undefined,
  setFloorFilter: (a: number | undefined) => void
): TableHeaders[] {
  return [
    {
      label: 'Projectnaam',
      isSortable: true,
      filterPlaceHolder: 'Filter project...',
      filterType: FilterType.search,
      filterValue: projectFilter,
      onFilterChange: setProjectFilter,
      columnName: 'projects',
    },
    {
      label: 'Bouwnummer',
      isSortable: true,
      filterPlaceHolder: 'Filter bouwnummer...',
      filterType: FilterType.search,
      filterValue: lotFilter,
      onFilterChange: setLotFilter,
      columnName: 'lots',
    },
    {
      label: 'Klant',
      isSortable: true,
      filterPlaceHolder: 'Filter klant...',
      filterType: FilterType.search,
      filterValue: clientFilter,
      onFilterChange: setClientFilter,
      columnName: 'clients',
      width: '20%',
    },
    {
      label: 'Status',
      isSortable: true,
      filterPlaceHolder: 'Filter status...',
      filterType: FilterType.search,
      filterValue: statusFilter,
      onFilterChange: setStatusFilter,
      columnName: 'status',
      width: '20%',
    },
    {
      label: 'Verdiepingen',
      filterPlaceHolder: 'Filter verdiepingen...',
      filterType: FilterType.select,
      filterValue: floorFilter,
      onFilterChange: setFloorFilter,
      filterOptions: [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
      ],
      columnName: 'floors',
      align: 'center',
      width: '105px',
    },
    {
      label: '',
      columnName: 'view',
      align: 'center',
      width: '34px',
    },
    {
      label: '',
      columnName: 'edit',
      align: 'center',
      width: '34px',
    },
    {
      label: '',
      columnName: 'create',
      align: 'center',
      width: '34px',
    },
  ];
}
