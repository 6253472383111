import { ApiRequest } from '../../../../api/ApiService';
import { Method } from '../../../../api/types';
import { ENDPOINTS } from '../../../../api/constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { CreateTemplatePayload } from '../../types';
import { productActions } from '../slice/productsSlice';

async function callApi(props: CreateTemplatePayload) {
  const payload = {
    templateName: props.templateName,
    isActive: props.isActive,
    templateMaterials: [
      ...props.windowTemplateMaterials,
      ...props.floorTemplateMaterials,
      ...props.curtainTemplateMaterials,
    ],
  };
  return ApiRequest(Method.POST, ENDPOINTS.createTemplate, undefined, undefined, payload);
}

export default function* callCreateTemplateSaga({ payload }: PayloadAction<CreateTemplatePayload>) {
  try {
    const response = yield call(callApi, payload);
    if (!response?.success) {
      ErrorToast(undefined, response?.message);
    } else {
      yield put(productActions.createTemplateSuccess());
      SuccessToast('Succes!', 'Sjabloon succesvol aangemaakt');
    }
  } catch (error: unknown) {
    console.error('callCreateTemplateSaga', error);
    yield put(productActions.createTemplateFailure());
    // @ts-ignore
    ErrorToast(undefined, error.toString());
  }
}
