export const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const WORKSPACE_ID = parseInt(process.env.REACT_APP_WORKSPACE_ID ?? '0');
export const WORKSPACE_CODE = process.env.REACT_APP_WORKSPACE_CODE;
export const DEFAULT_PAGE_SIZE = 10;

export const ENDPOINTS = {
  // Accounts
  userInfo: '/userInfo',

  // Client
  getClient: '/api/client',
  updateClient: '/api/client',
  getChoosenStyle: '/api/client/choosenStyle',
  getStatusDropdown: '/api/client/stausDropdown',
  editChoosenStyle: '/api/client/editChoosenStyle',
  getInviteAll: '/api/client/inviteAll',
  getClientMaterialColor: '/api/client/materialColor',
  createQuote: '/api/client/{id}/quote',
  appointmentDetails: '/api/client/{id}/appointment',
  getMasterColorDropDown: '/api/client/masterColorDropDown',
  getProductTypeDropDown: '/api/client/productTypeDropDown',
  getClientQuote: '/api/client/{id}/quote',
  postClientQuote: '/api/client/{id}/sendQuote',
  uploadEditTimeSlot: '/api/client/{id}/event',
  deleteTimeSlot: '/api/client/appointment/{id}',
  postChosenStyles: '/api/client/choosenStyle',
  getClientStatus: '/api/client/status',
  deleteTemplateWindow: '/api/Lots/DeleteWindow',
  getMaterialPrice: '/api/Client/ChoosenMaterialPrice',

  // Lots
  selectTemplate: '/api/lots/selectTemplete',
  updateLot: '/api/lots/{lotTypeId}',

  // Products
  getProducts: '/api/products',
  syncProducts: '/api/products/exact/syncProduct',
  syncSingleProduct: '/api/products/exact/{id}/syncSingleProduct',
  getProductType: '/api/products/type',
  getProductGroup: '/api/products/group',
  getProductMaterialColor: '/api/products/{id}/materialColor',
  uploadProductColorImage: '/api/products/color/uploadImage',
  getProductColorImage: '/api/products/colot/{id}/downloadImage',
  uploadProductGroupImage: '/api/products/{id}/image',
  uploadProductBrochure: '/api/Products/{id}/Brochure',
  getProductGroupImage: '/api/products/{id}/image',
  deleteGroupImage: '/api/Products/{id}/Image',
  deleteGroupBrochure: '/api/products/{id}/brochure',
  getProductBrochure: '/api/products/{id}/brochure',
  deleteProductColorImage: '/api/Products/{id}',

  // Project
  getProjects: '/api/project',
  syncWithExact: '/api/project/syncWithExact',
  getProjectLotTypes: '/api/project/{id}/lotTypes',
  getProjectMaterialPrice: '/api/project/materialPrice',
  updateProjectMaterialPrice: '/api/project/updatePrice',
  updateTimeSlots: '/api/project/bookTimeSlot',
  syncLotsWithExact: '/api/Lots/SyncLotsWithExact',
  deleteBookedTimeSlot: '/api/project/timeSlot',

  // SendGrid
  postSendgrid: '/api/sendGrid',

  // Templates
  getTemplates: '/api/templates',
  createTemplate: '/api/templates',
  getTemplateMasterData: '/api/templates/masterData',
  updateTemplate: '/api/templates/{id}',
  deleteTemplate: '/api/templates/{id}',
  getTemplateDropdown: '/api/templates/dropdown',
  getExistTemplates: '/api/templates/existTemplates',
  getTemplateMaterials: '/api/templates/materials',
  getCompletedMaterials: '/api/templates/CompletedMaterials',
  getExistMaterials: '/api/templates/getExistMaterials',
  deleteFloorImage: '/api/Lots/DeleteFloorImage',

  // Token
  getToken: '/api/token',

  // User
  getUser: '/api/user',
  createUser: '/api/user',
  updateUser: '/api/user/{id}',
  deleteUser: '/api/user/{id}',

  uploadFloorImage: '/api/Lots/UploadFloorImage',
};
