import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Template } from '../types';
import CustomDialog from '../../../shared/components/modal/CustomDialog';
import { useAppSelector } from '../../../store/types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { deleteTemplateIsLoadingSelector, deleteTemplateIsSuccessSelector } from '../store/selectors/productsSelector';
import { productActions } from '../store/slice/productsSlice';

const StyledTitle = styled('p')`
  color: #222;
  font-size: 16px;
  font-weight: 300;
  line-height: 110%;
  letter-spacing: 0.64px;
  text-align: center;
`;

const StyledTemplateName = styled('p')`
  color: #0c1b2a;
  font-size: 16px;
  font-weight: 700;
  line-height: 110%;
`;

interface DeleteTemplateModalProps {
  openModal: boolean;
  closeModal: () => void;
  selectedTemplate: Template;
  fetchTemplates: () => void;
}

export default function DeleteTemplateModal(props: DeleteTemplateModalProps) {
  const { openModal, closeModal, selectedTemplate, fetchTemplates } = props;
  const dispatch = useDispatch();

  const deleteTemplateIsLoading = useAppSelector(deleteTemplateIsLoadingSelector);
  const deleteTemplateIsSuccess = useAppSelector(deleteTemplateIsSuccessSelector);

  useEffect(() => {
    if (deleteTemplateIsSuccess) {
      closeModal();
      fetchTemplates();
      dispatch(productActions.resetDeleteTemplate());
    }
  }, [closeModal, dispatch, deleteTemplateIsSuccess, fetchTemplates]);

  if (!openModal) return null;

  function handleSubmit() {
    dispatch(productActions.deleteTemplate(selectedTemplate.idTemplate));
  }

  return (
    <CustomDialog
      open={openModal}
      handleClose={closeModal}
      cancelButtonText={'Annuleren'}
      handleCancelButtonPress={closeModal}
      primaryButtonText={'Verwijderen'}
      primaryButtonDisabled={deleteTemplateIsLoading}
      handlePrimaryButtonPress={handleSubmit}
      footerAlign={'center'}
      showCloseButton={false}
    >
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <StyledTitle>Weet je zeker dat je het volgende wilt verwijderen</StyledTitle>
        <StyledTemplateName>{selectedTemplate?.name}</StyledTemplateName>
      </Box>
    </CustomDialog>
  );
}
