import { call, put } from 'redux-saga/effects';
import { PostTimeSlot, PostTimeSlotResp } from '../../types';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { Method } from '../../../../api/types';

async function callApi(props: PostTimeSlot) {
  const pathParam = {
    id: props.id,
  };
  const payload = {
    bookedDate: props.bookedDate,
    bookedTimeSlots: props.bookedTimeSlots,
  };
  return ApiRequest(Method.PATCH, ENDPOINTS.uploadEditTimeSlot, pathParam, undefined, payload);
}

export default function* callUploadEditTimeSlotSaga({ payload }: PayloadAction<PostTimeSlot>) {
  try {
    const postResponse: PostTimeSlotResp = yield call(callApi, payload);
    yield put(clientActions.uploadEditTimeSlotSuccess(postResponse));
    if (postResponse?.isSucess) {
      SuccessToast('Succes!', 'Er is een e-mail verzonden naar de klant');
    }
  } catch (error: unknown) {
    console.error('callUploadEditTimeSlotSaga', error);
    yield put(clientActions.uploadEditTimeSlotFailure());
    ErrorToast(undefined, error.toString());
  }
}
