import { call, put } from 'redux-saga/effects';
import { FetchTemplatesParams, FetchTemplatesResponse } from '../../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SIZE, ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { productActions } from '../slice/productsSlice';
import { ApiRequest } from '../../../../api/ApiService';

async function callApi(props: FetchTemplatesParams) {
  const queryParam = {
    searchKey: props?.searchKey,
    name: props?.name,
    material: props?.material,
    status: props?.status,
    KeyProperty: props?.KeyProperty,
    IsAscending: props?.IsAscending,
    pageSize: props?.pageSize ?? DEFAULT_PAGE_SIZE,
    pageNumber: props?.pageNumber ?? 1,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getTemplates, undefined, queryParam);
}

export default function* callFetchTemplatesSaga({ payload }: PayloadAction<FetchTemplatesParams>) {
  try {
    const templates: FetchTemplatesResponse = yield call(callApi, payload);
    yield put(productActions.fetchTemplatesSuccess(templates));
  } catch (error: unknown) {
    console.error('callFetchTemplatesSaga', error);
    yield put(productActions.fetchTemplatesFailure());
  }
}
