import { RootState } from '../../../../store/types';

export const fetchProjectsIsLoadingSelector = (state: RootState) => state.projects.fetchProjectsIsLoading;
export const projectsSelector = (state: RootState) => state.projects.projects.data;
export const projectsTotalPagesSelector = (state: RootState) => state.projects.projects.totalPages;
export const projectsCountSelector = (state: RootState) => state.projects.projects.count;
export const fetchProjectsHasErrorSelector = (state: RootState) => state.projects.fetchProjectsHasError;

export const fetchLotTypesIsLoadingSelector = (state: RootState) => state.projects.fetchLotTypesIsLoading;
export const lotTypesSelector = (state: RootState) => state.projects.lotTypes.data;
export const lotTypesTotalPagesSelector = (state: RootState) => state.projects.lotTypes.totalPages;
export const lotTypesCountSelector = (state: RootState) => state.projects.lotTypes.count;
export const fetchLotTypesHasErrorSelector = (state: RootState) => state.projects.fetchLotTypesHasError;

export const updateFloorAmountIsLoadingSelector = (state: RootState) => state.projects.updateFloorAmountIsLoading;
export const updateFloorAmountIsSuccessSelector = (state: RootState) => state.projects.updateFloorAmountIsSuccess;
export const updateFloorAmountHasErrorSelector = (state: RootState) => state.projects.updateFloorAmountHasError;

export const fetchMaterialPriceIsLoadingSelector = (state: RootState) => state.projects.fetchMaterialPriceIsLoading;
export const materialPriceSelector = (state: RootState) => state.projects.materialPrice;
export const fetchMaterialPriceHasErrorSelector = (state: RootState) => state.projects.fetchMaterialPriceHasError;

export const updateMaterialPriceIsLoadingSelector = (state: RootState) => state.projects.updateMaterialPriceIsLoading;
export const updateMaterialPriceIsSuccessSelector = (state: RootState) => state.projects.updateMaterialPriceIsSuccess;
export const updateMaterialPriceHasErrorSelector = (state: RootState) => state.projects.updateMaterialPriceHasError;

export const updateTimeSlotIsSuccessSelector = (state: RootState) => state.projects.updateTimeSlotIsSuccess;
export const postTimeSlotIsValidSelector = (state: RootState) => state.projects.postTimeSlotIsValid;
export const postTimeSlotMessageSelector = (state: RootState) => state.projects.postTimeSlotMessage;

export const deleteTimeSlotIsSuccessSelector = (state: RootState) => state.projects.deleteTimeSlotIsSuccess;
export const deleteTimeSlotIsLoadingSelector = (state: RootState) => state.projects.deleteTimeSlotIsLoading;

export const projectSyncWithExactSuccessSelector = (state: RootState) => state.projects.projectSyncWithExactSuccess;
export const projectSyncWithExactHasErrorSelector = (state: RootState) => state.projects.projectSyncWithExactHasError;
export const projectSyncWithExactIsLoadingSelector = (state: RootState) => state.projects.projectSyncWithExactIsLoading;
export const projectSyncLotsWithExactIsLoadingSelector = (state: RootState) =>
  state.projects.projectSyncLotsWithExactIsLoading;
export const projectSyncLotsWithExactIsSuccessSelector = (state: RootState) =>
  state.projects.projectSyncLotsWithExactIsSuccess;
export const projectSyncLotsWithExactHasErrorSelector = (state: RootState) =>
  state.projects.projectSyncLotsWithExactHasError;
