import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateTemplatePayload,
  DownloadBrochureResponse,
  DownloadImageDataResponse,
  EditTemplatePayload,
  FetchMaterialColorParams,
  FetchMaterialColorResponse,
  FetchProductsParams,
  FetchProductsResponse,
  FetchTemplatesParams,
  FetchTemplatesResponse,
  ProductGroup,
  ProductsState,
  ProductType,
  SyncSingleProductParams,
  UploadColorImage,
  UploadImageDataParams,
} from '../../types';
import { Material } from '../../../clients/types';

const initialState: ProductsState = {
  fetchProductsIsLoading: false,
  products: {
    count: 0,
    data: [],
    totalPages: 0,
  },
  fetchProductsHasError: false,

  syncProductsWithExactIsLoading: false,
  syncProductsWithExactIsSuccess: false,
  syncProductsWithExactHasError: false,

  syncSingleProductWithExactIsLoading: false,
  syncSingleProductWithExactIsSuccess: false,
  syncSingleProductWithExactHasError: false,

  fetchProductTypesIsLoading: false,
  productTypes: [],
  fetchProductTypesHasError: false,

  fetchProductGroupsIsLoading: false,
  productGroups: [],
  fetchProductGroupsHasError: false,

  fetchTemplatesIsLoading: false,
  templates: {
    count: 0,
    data: [],
    totalPages: 0,
  },
  fetchTemplatesHasError: false,

  fetchExistingMaterialsIsLoading: false,
  existingMaterials: [],
  fetchExistingMaterialsHasError: false,

  createTemplateHasError: false,
  createTemplateIsLoading: false,
  createTemplateIsSuccess: false,

  editTemplateIsLoading: false,
  editTemplateIsSuccess: false,
  editTemplateHasError: false,

  deleteTemplateIsLoading: false,
  deleteTemplateIsSuccess: false,
  deleteTemplateHasError: false,

  fetchMaterialColorsIsLoading: false,
  materialColors: {
    count: 0,
    data: [],
    totalPages: 0,
  },
  fetchMaterialColorsHasError: false,

  uploadProductGroupImageIsLoading: false,
  uploadProductGroupImageIsSuccess: false,
  uploadProductGroupImageHasError: false,

  uploadProductBrochureIsLoading: false,
  uploadProductBrochureIsSuccess: false,
  uploadProductBrochureHasError: false,

  downloadProductGroupImageIsLoading: false,
  downloadProductGroupImage: null,
  downloadProductGroupImageHasError: false,

  downloadProductBrochureIsLoading: false,
  downloadProductBrochure: null,
  downloadProductBrochureHasError: false,

  uploadProductColorImageIsLoading: false,
  uploadProductColorImageIsSuccess: false,
  uploadProductColorImageHasError: false,

  downloadProductColorImageIsLoading: false,
  downloadProductColorImage: null,
  downloadProductColorImageHasError: false,

  deleteGroupImageIsLoading: false,
  deleteGroupImageIsSuccess: false,
  deleteGroupImageHasError: false,

  deleteGroupBrochureIsLoading: false,
  deleteGroupBrochureIsSuccess: false,
  deleteGroupBrochureHasError: false,

  deleteProductColorImageIsLoading: false,
  deleteProductColorImageIsSuccess: false,
  deleteProductColotImageHasError: false,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchProducts(state, action: PayloadAction<FetchProductsParams>) {
      state.fetchProductsIsLoading = true;
      state.fetchProductsHasError = false;
    },
    fetchProductsSuccess(state, action: PayloadAction<FetchProductsResponse>) {
      state.fetchProductsIsLoading = false;
      state.products = {
        count: action.payload.count,
        data: action.payload.getProducts,
        totalPages: action.payload.totalPages,
      };
    },
    fetchProductsFailure(state) {
      state.fetchProductsIsLoading = false;
      state.fetchProductsHasError = true;
    },

    syncProductsWithExact(state) {
      state.syncProductsWithExactIsLoading = true;
      state.syncProductsWithExactIsSuccess = false;
      state.syncProductsWithExactHasError = false;
    },
    syncProductsWithExactSuccess(state) {
      state.syncProductsWithExactIsLoading = false;
      state.syncProductsWithExactIsSuccess = true;
    },
    syncProductsWithExactFailure(state) {
      state.syncProductsWithExactIsLoading = false;
      state.syncProductsWithExactHasError = true;
    },
    resetSyncProductsWithExact(state) {
      state.syncProductsWithExactIsLoading = false;
      state.syncProductsWithExactIsSuccess = false;
      state.syncProductsWithExactHasError = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    syncSingleProductWithExact(state, action: PayloadAction<SyncSingleProductParams>) {
      state.syncSingleProductWithExactIsLoading = true;
      state.syncSingleProductWithExactIsSuccess = false;
      state.syncSingleProductWithExactHasError = false;
    },
    syncSingleProductWithExactSuccess(state) {
      state.syncSingleProductWithExactIsLoading = false;
      state.syncSingleProductWithExactIsSuccess = true;
    },
    syncSingleProductWithExactFailure(state) {
      state.syncSingleProductWithExactIsLoading = false;
      state.syncSingleProductWithExactHasError = true;
    },
    resetSyncSingleProductWithExact(state) {
      state.syncSingleProductWithExactIsLoading = false;
      state.syncSingleProductWithExactIsSuccess = false;
      state.syncSingleProductWithExactHasError = false;
    },

    fetchProductTypes(state) {
      state.fetchProductTypesIsLoading = true;
      state.fetchProductTypesHasError = false;
    },
    fetchProductTypesSuccess(state, action: PayloadAction<ProductType[]>) {
      state.fetchProductTypesIsLoading = false;
      state.productTypes = action.payload;
    },
    fetchProductTypesFailure(state) {
      state.fetchProductTypesIsLoading = false;
      state.fetchProductTypesHasError = true;
    },

    fetchProductGroups(state) {
      state.fetchProductGroupsIsLoading = true;
      state.fetchProductGroupsHasError = false;
    },
    fetchProductGroupsSuccess(state, action: PayloadAction<ProductGroup[]>) {
      state.fetchProductGroupsIsLoading = false;
      state.productGroups = action.payload;
    },
    fetchProductGroupsFailure(state) {
      state.fetchProductGroupsIsLoading = false;
      state.fetchProductGroupsHasError = true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchTemplates(state, action: PayloadAction<FetchTemplatesParams>) {
      state.fetchTemplatesIsLoading = true;
      state.fetchTemplatesHasError = false;
    },
    fetchTemplatesSuccess(state, action: PayloadAction<FetchTemplatesResponse>) {
      state.fetchTemplatesIsLoading = false;
      state.templates = {
        count: action.payload.count,
        data: action.payload.templete,
        totalPages: action.payload.totalPages,
      };
    },
    fetchTemplatesFailure(state) {
      state.fetchTemplatesIsLoading = false;
      state.fetchTemplatesHasError = true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchExistingMaterials(state, action: PayloadAction<number>) {
      state.fetchExistingMaterialsIsLoading = true;
      state.existingMaterials = [];
      state.fetchExistingMaterialsHasError = false;
    },
    fetchExistingMaterialsSuccess(state, action: PayloadAction<Material[]>) {
      state.fetchExistingMaterialsIsLoading = false;
      state.existingMaterials = action.payload;
    },
    fetchExistingMaterialsFailure(state) {
      state.fetchExistingMaterialsIsLoading = false;
      state.fetchExistingMaterialsHasError = true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createTemplate(state, action: PayloadAction<CreateTemplatePayload>) {
      state.createTemplateIsLoading = true;
      state.createTemplateIsSuccess = false;
      state.createTemplateHasError = false;
    },
    createTemplateSuccess(state) {
      state.createTemplateIsLoading = false;
      state.createTemplateIsSuccess = true;
    },
    createTemplateFailure(state) {
      state.createTemplateIsLoading = false;
      state.createTemplateHasError = true;
    },
    resetCreateTemplate(state) {
      state.createTemplateIsLoading = false;
      state.createTemplateIsSuccess = false;
      state.createTemplateHasError = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    editTemplate(state, action: PayloadAction<EditTemplatePayload>) {
      state.editTemplateIsLoading = true;
      state.editTemplateIsSuccess = false;
      state.editTemplateHasError = false;
    },
    editTemplateSuccess(state) {
      state.editTemplateIsLoading = false;
      state.editTemplateIsSuccess = true;
    },
    editTemplateFailure(state) {
      state.editTemplateIsLoading = false;
      state.editTemplateHasError = true;
    },
    resetEditTemplate(state) {
      state.editTemplateIsLoading = false;
      state.editTemplateIsSuccess = false;
      state.editTemplateHasError = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteTemplate(state, action: PayloadAction<number>) {
      state.deleteTemplateIsLoading = true;
      state.deleteTemplateIsSuccess = false;
      state.deleteTemplateHasError = false;
    },
    deleteTemplateSuccess(state) {
      state.deleteTemplateIsLoading = false;
      state.deleteTemplateIsSuccess = true;
    },
    deleteTemplateFailure(state) {
      state.deleteTemplateIsLoading = false;
      state.deleteTemplateHasError = true;
    },
    resetDeleteTemplate(state) {
      state.deleteTemplateIsLoading = false;
      state.deleteTemplateIsSuccess = false;
      state.deleteTemplateHasError = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchProductMaterialColors(state, action: PayloadAction<FetchMaterialColorParams>) {
      state.fetchMaterialColorsIsLoading = true;
      state.fetchMaterialColorsHasError = false;
    },
    fetchProductMaterialColorsSuccess(state, action: PayloadAction<FetchMaterialColorResponse>) {
      state.fetchMaterialColorsIsLoading = false;
      state.materialColors = {
        count: action.payload.count,
        data: action.payload.color,
        totalPages: action.payload.totalPages,
      };
    },
    fetchProductMaterialColorsFailure(state) {
      state.fetchMaterialColorsIsLoading = false;
      state.fetchMaterialColorsHasError = true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uploadProductGroupImage(state, action: PayloadAction<UploadImageDataParams>) {
      state.uploadProductGroupImageIsLoading = true;
      state.uploadProductGroupImageIsSuccess = false;
      state.uploadProductGroupImageHasError = false;
    },
    uploadProductGroupImageSuccess(state) {
      state.uploadProductGroupImageIsLoading = false;
      state.uploadProductGroupImageIsSuccess = true;
    },
    uploadProductGroupImageFailure(state) {
      state.uploadProductGroupImageIsLoading = false;
      state.uploadProductGroupImageHasError = true;
    },
    resetUploadProductGroupImage(state) {
      state.uploadProductGroupImageIsLoading = false;
      state.uploadProductGroupImageIsSuccess = false;
      state.uploadProductGroupImageHasError = false;
    },
    uploadProductBrochure(state, action: PayloadAction<UploadImageDataParams>) {
      state.uploadProductBrochureIsLoading = true;
      state.uploadProductBrochureIsSuccess = false;
      state.uploadProductBrochureHasError = false;
    },
    uploadProductBrochureSuccess(state) {
      state.uploadProductBrochureIsLoading = false;
      state.uploadProductBrochureIsSuccess = true;
    },
    uploadProductBrochureFailure(state) {
      state.uploadProductBrochureIsLoading = false;
      state.uploadProductBrochureHasError = true;
    },
    resetUploadProductBrochure(state) {
      state.uploadProductBrochureIsLoading = false;
      state.uploadProductBrochureIsSuccess = false;
      state.uploadProductBrochureHasError = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    downloadProductGroupImage(state, action: PayloadAction<number>) {
      state.downloadProductGroupImageIsLoading = true;
      state.downloadProductGroupImageHasError = false;
    },
    downloadProductGroupImageSuccess(state, action: PayloadAction<DownloadImageDataResponse>) {
      state.downloadProductGroupImageIsLoading = false;
      state.downloadProductGroupImage = action.payload.imageData;
    },
    downloadProductGroupImageFailure(state) {
      state.downloadProductGroupImageIsLoading = false;
      state.downloadProductGroupImageHasError = true;
    },
    resetDownloadProductGroupImage(state) {
      state.downloadProductGroupImageIsLoading = false;
      state.downloadProductGroupImage = null;
      state.downloadProductGroupImageHasError = false;
    },
    downloadProductBrochure(state, action: PayloadAction<number>) {
      state.downloadProductBrochureIsLoading = true;
      state.downloadProductBrochureHasError = false;
    },
    downloadProductBrochureSuccess(state, action: PayloadAction<DownloadBrochureResponse>) {
      state.downloadProductBrochureIsLoading = false;
      state.downloadProductBrochure = action.payload.brochure;
    },
    downloadProductBrochureFailure(state) {
      state.downloadProductBrochureIsLoading = false;
      state.downloadProductBrochureHasError = true;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uploadProductColorImage(state, action: PayloadAction<UploadColorImage[]>) {
      state.uploadProductColorImageIsLoading = true;
      state.uploadProductColorImageIsSuccess = false;
      state.uploadProductColorImageHasError = false;
    },
    uploadProductColorImageSuccess(state) {
      state.uploadProductColorImageIsLoading = false;
      state.uploadProductColorImageIsSuccess = true;
    },
    uploadProductColorImageFailure(state) {
      state.uploadProductColorImageIsLoading = false;
      state.uploadProductColorImageHasError = true;
    },
    resetUploadProductColorImage(state) {
      state.uploadProductColorImageIsLoading = false;
      state.uploadProductColorImageIsSuccess = false;
      state.uploadProductColorImageHasError = false;
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    downloadProductColorImage(state, action: PayloadAction<number>) {
      state.downloadProductColorImageIsLoading = true;
      state.downloadProductColorImageHasError = false;
    },
    downloadProductColorImageSuccess(state, action: PayloadAction<DownloadImageDataResponse>) {
      state.downloadProductColorImageIsLoading = false;
      state.downloadProductColorImage = action.payload.imageData;
    },
    downloadProductColorImageFailure(state) {
      state.downloadProductColorImageIsLoading = false;
      state.downloadProductColorImageHasError = true;
    },
    resetDownloadProductColorImage(state) {
      state.downloadProductColorImageIsLoading = false;
      state.downloadProductColorImage = null;
      state.downloadProductColorImageHasError = false;
    },
    deleteGroupImage(state, action: PayloadAction<number>) {
      state.deleteGroupImageIsLoading = true;
      state.deleteGroupImageIsSuccess = false;
      state.deleteGroupImageHasError = false;
    },
    deleteGroupImageSuccess(state) {
      state.deleteGroupImageIsLoading = false;
      state.deleteGroupImageIsSuccess = true;
    },
    deleteGroupImageFailure(state) {
      state.deleteGroupImageIsLoading = false;
      state.deleteGroupImageHasError = true;
    },
    resetDeleteGroupImage(state) {
      state.deleteGroupImageIsLoading = false;
      state.deleteGroupImageIsSuccess = false;
      state.deleteGroupImageHasError = false;
    },
    deleteGroupBrochure(state, action: PayloadAction<number>) {
      state.deleteGroupBrochureIsLoading = true;
      state.deleteGroupBrochureIsSuccess = false;
      state.deleteGroupBrochureHasError = false;
    },
    deleteGroupBrochureSuccess(state) {
      state.deleteGroupBrochureIsLoading = false;
      state.deleteGroupBrochureIsSuccess = true;
    },
    deleteGroupBrochureFailure(state) {
      state.deleteGroupBrochureIsLoading = false;
      state.deleteGroupBrochureHasError = true;
    },
    resetDeleteGroupBrochure(state) {
      state.deleteGroupBrochureIsLoading = false;
      state.deleteGroupBrochureIsSuccess = false;
      state.deleteGroupBrochureHasError = false;
    },
    deleteProductColorImage(state, action: PayloadAction<number>) {
      state.deleteProductColorImageIsLoading = true;
      state.deleteProductColotImageHasError = false;
      state.deleteProductColorImageIsSuccess = false;
    },
    deleteProductColorImageIsSuccess(state) {
      state.deleteProductColorImageIsLoading = false;
      state.deleteProductColorImageIsSuccess = true;
      state.deleteProductColotImageHasError = false;
    },
    deleteProductColorImageFailed(state) {
      state.deleteProductColorImageIsLoading = false;
      state.deleteProductColorImageIsSuccess = false;
      state.deleteProductColotImageHasError = true;
    },
    resetDeleteProductColorImage(state) {
      state.deleteProductColorImageIsLoading = false;
      state.deleteProductColorImageIsSuccess = false;
      state.deleteProductColotImageHasError = false;
    },
  },
});

// Actions
export const productActions = productsSlice.actions;

// Reducer
const productReducer = productsSlice.reducer;
export default productReducer;
