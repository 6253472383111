import React from 'react';

export enum FilterType {
  none,
  search,
  select,
}

export interface TableHeaders {
  label: string;
  isSortable?: boolean;
  filterPlaceHolder?: string;
  filterType?: FilterType;
  filterValue?: string | number | null;
  onFilterChange?: (a: any) => void;
  filterOptions?: { value: string | number; label: string }[];
  columnName: string;
  align?: 'left' | 'center' | 'right';
  width?: string;
  type?: string;
}

export interface TableBodyRow {
  id: string;
  tableCell: React.ReactNode;
}

export enum TableColumnSortDirection {
  'ASC' = 'asc',
  'DESC' = 'desc',
}

export const noop = () => {};
