import { call, put } from 'redux-saga/effects';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { PayloadAction } from '@reduxjs/toolkit';

async function callApi(idDefaultTemplate: string) {
  const queryParam = {
    idDefaultTemplate,
  };

  return ApiRequest(Method.DELETE, ENDPOINTS.deleteFloorImage, undefined, queryParam);
}

export default function* calldeleteFloorImageSaga({ payload }: PayloadAction<string>) {
  try {
    yield call(callApi, payload);
    yield put(clientActions.deleteFloorImageSuccess());
    SuccessToast('Succes!', 'Floor image deleted');
  } catch (error: unknown) {
    console.error('callCreateQouteSaga', error);
    yield put(clientActions.deleteFloorImageFailure());
    ErrorToast(undefined, error.toString());
  }
}
