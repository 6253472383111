import axios from 'axios';

import { getWithExpiry } from '@shared/services/LocalStorage';
import { BASE_URL } from './constants';
import omitBy from 'lodash/omitBy';
import { Method } from './types';

type ParamType = Record<string, unknown>;
type BodyType = Record<string, any>;

const authorizationString = (authRequired = true): string | undefined => {
  const token = getWithExpiry('console_admin_token');
  return authRequired ? `Bearer ${token}` : undefined;
};

const handleResponse = (response: any): any => {
  try {
    const status = parseInt(response.status, 10);
    if (status === 200 || status === 201) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const GET = async (url: string, params: ParamType = {}, authRequired = true): Promise<any> => {
  return axios
    .get(url, {
      params,
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorizationString(authRequired),
      },
    })
    .then((res) => {
      return handleResponse(res);
    })
    .catch((error) => {
      throw error;
    });
};

export function getOptimizeQueryParam(obj: { [key: string]: unknown }) {
  return omitBy(obj, (v) => v === undefined || v === null || v === '');
}

function getUrlWithPathParam(url: string, pathParam: ParamType) {
  const spited = url.split(/{|}/);
  return spited.reduce((acc, i) => {
    if (pathParam[i]) {
      return acc + `${pathParam[i]}`;
    } else {
      return acc + `${i}`;
    }
  }, '');
}

export async function ApiRequest(
  method: Method,
  url: string,
  pathParam: ParamType = {},
  queryParam: ParamType = {},
  payload: ParamType = {},
  authRequired = true
): Promise<BodyType> {
  return axios({
    method,
    url: getUrlWithPathParam(url, pathParam),
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: authorizationString(authRequired),
    },
    params: getOptimizeQueryParam(queryParam),
    data: payload,
  })
    .then((res) => {
      return handleResponse(res);
    })
    .catch((error) => {
      throw error;
    });
}
