import { spawn } from 'redux-saga/effects';

import authenticationSaga from '@authentication/store/authentication.saga';
import projectsSaga from '../modules/projects/store/sagas/projectsSaga';
import productsSaga from '../modules/products/store/sagas/productsSaga';
import UsersSaga from '../modules/users/store/sagas/usersSaga';
import clientSagas from '@clients/store/sagas/clientSagas';

export default function* rootSaga() {
  yield spawn(authenticationSaga);
  yield spawn(projectsSaga);
  yield spawn(productsSaga);
  yield spawn(UsersSaga);
  yield spawn(clientSagas);
}
