import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { Field } from 'formik';
import 'react-quill/dist/quill.snow.css'; // Import Quill's styles
import './RichTextBox.css';

interface Props {
  name: string | ``;
}

const RichTextBox: React.FC<Props> = ({ name }) => {
  const [editorHtml, setEditorHtml] = useState<string>(''); // State to store the rich text content

  // const handleEditorChange = (html: string) => {
  //   setEditorHtml(html);
  // };

  return (
    <div>
      <Field name={name}>
        {({ field }) => (
          <ReactQuill
            theme="snow" // Use the "snow" theme for a clean and simple interface
            value={field.value}
            onChange={field.onChange(field.name)}
            className="quill-container "
          />
        )}
      </Field>
    </div>
  );
};

export default RichTextBox;
