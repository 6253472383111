import { call, put } from 'redux-saga/effects';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { productActions } from '../slice/productsSlice';
import { ApiRequest } from '../../../../api/ApiService';
import { ProductType } from '../../types';

async function callApi() {
  return ApiRequest(Method.GET, ENDPOINTS.getProductType);
}

export default function* callFetchProductTypesSaga() {
  try {
    const productTypes: ProductType[] = yield call(callApi);
    yield put(productActions.fetchProductTypesSuccess(productTypes));
  } catch (error: unknown) {
    console.error('callFetchProductTypesSaga', error);
    yield put(productActions.fetchProductTypesFailure());
  }
}
