import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { styled, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import App from './app/App';
import { store } from '@store/store';
import baseTheme from './theme';
import reportWebVitals from './reportWebVitals';
import './app/utils/i18n';

const StyledDiv = styled('div')`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #25334f;
`;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Suspense
    fallback={
      <StyledDiv>
        <p>Almost there...</p>
      </StyledDiv>
    }
  >
    <Provider store={store}>
      <ThemeProvider theme={baseTheme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </Provider>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
