import { TableColumnSortDirection, TableHeaders } from '@shared/types';
import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import TableColumnSortIcon from '@assets/TableColumnSortIcon';

interface HeaderColumnContentProps {
  tableColumn: TableHeaders;
  sortBy: string | undefined;
  setSortBy: (a: string | undefined) => void;
  sortDirection: TableColumnSortDirection;
  setSortDirection: (a: TableColumnSortDirection) => void;
}

export default function HeaderColumnContent({
  tableColumn,
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
}: HeaderColumnContentProps) {
  const sortActive = tableColumn.columnName === sortBy;
  const lastSortDirection = useRef<TableColumnSortDirection | null>(null);

  function handleSortClick() {
    if (sortBy !== tableColumn.columnName) {
      setSortBy(tableColumn.columnName);
      setSortDirection(TableColumnSortDirection.ASC);
      lastSortDirection.current = TableColumnSortDirection.ASC;
    } else {
      if (lastSortDirection.current === TableColumnSortDirection.ASC) {
        setSortDirection(TableColumnSortDirection.DESC);
        lastSortDirection.current = TableColumnSortDirection.DESC;
      } else {
        setSortBy(tableColumn.columnName);
        setSortDirection(TableColumnSortDirection.ASC);
        lastSortDirection.current = TableColumnSortDirection.ASC;
      }
    }
  }

  return (
    <Box display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}>
      {tableColumn.label}
      <IconButton disableRipple onClick={handleSortClick} sx={{ padding: 0 }}>
        {tableColumn.isSortable && <TableColumnSortIcon sortActive={sortActive} sortDirection={sortDirection} />}
      </IconButton>
    </Box>
  );
}
