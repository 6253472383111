import React, { memo, useEffect, useRef, useState } from 'react';
import { ReactComponent as FilterIcon } from '../../../../assets/images/filter.svg';
import { Button, IconButton } from '@mui/material';
import { useDebounce } from 'use-debounce';
import Box from '@mui/material/Box';
import SearchField from '@shared/components/searchField/SearchField';

interface TableSearchBarProps {
  buttonStartIcon?: React.ReactNode;
  buttonEndIcon?: React.ReactNode;
  buttonText?: string;
  placeHolder: string;
  style?: React.CSSProperties;
  onSearch: (str: string) => void;
  showFilterToggle?: boolean;
  showFilter?: boolean;
  toggleFilterHandler?: () => void;
  buttonClickHandler?: () => void;
}

function TableSearchBar(props: TableSearchBarProps) {
  const {
    buttonText,
    buttonStartIcon,
    buttonEndIcon,
    placeHolder,
    onSearch,
    showFilterToggle,
    toggleFilterHandler,
    buttonClickHandler,
    showFilter,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [value] = useDebounce(inputValue, 600);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) {
      onSearch(value);
    }
  }, [value]);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  function onFilterClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (toggleFilterHandler) {
      toggleFilterHandler();
    }
  }

  return (
    <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
      <SearchField value={inputValue} handleOnChange={setInputValue} placeholder={placeHolder} />
      <Box gap={2} display={'flex'} flexDirection={'row'}>
        {showFilterToggle && (
          <IconButton
            onClick={onFilterClick}
            sx={{
              width: 32,
              height: 32,
              backgroundColor: showFilter ? 'rgba(189, 136, 144, 0.10)' : '',
              padding: 0,
            }}
          >
            <FilterIcon width={24} height={24} />
          </IconButton>
        )}
        {buttonText && (
          <Button
            disableElevation
            startIcon={buttonStartIcon}
            endIcon={buttonEndIcon}
            variant="contained"
            sx={{
              minWidth: '120px',
              height: '36px',
              fontSize: '13px',
              whiteSpace: 'nowrap',
              // textTransform: 'capitalize',
            }}
            onClick={buttonClickHandler!}
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </div>
  );
}

export default memo(TableSearchBar);
