import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import PageLayout from '../../../layout/PageLayout';
import TableSearchBar from '../../../shared/components/tableSearchBar/TableSearchBar';
import CommonHeader from '../../../shared/components/commonHeader/CommonHeader';
import { User } from '../types';
import { ReactComponent as AddUserIcon } from '../../../../assets/images/addUser.svg';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import CustomTable from '../../../shared/components/customTable/CustomTable';
import { TableColumnSortDirection } from '../../../shared/types';
import userTableHeaders from '../components/userTableHeaders';
import userTableData from '../components/userTableData';
import { userActions } from '../store/slice/usersSlice';
import { fetchUsersIsLoadingSelector, usersSelector, usersTotalPagesSelector } from '../store/selectors/userSelectors';
import CreateEditUserModal from '../components/CreateEditUserModal';
import DeleteUserModal from '../components/DeleteUserModal';

function UsersPage() {
  const dispatch = useAppDispatch();
  const users = useAppSelector(usersSelector);
  const totalNumberOfPages = useAppSelector(usersTotalPagesSelector);
  const fetchAllClientIsLoading = useAppSelector(fetchUsersIsLoadingSelector);

  const [selectedUser, setSelectedUser] = React.useState<User | null>();
  const [openCreateEditUserModal, setOpenCreateEditUserModal] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const [sortBy, setSortBy] = useState<string | undefined>('Name');
  const [sortDirection, setSortDirection] = useState<TableColumnSortDirection>(TableColumnSortDirection.ASC);

  const [searchKeyFilter, setSearchKeyFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [authFilter, setAuthFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  function resetAllFilters() {
    if (currentPage === 1) {
      fetchUsers();
    }
    setSearchKeyFilter('');
    setNameFilter('');
    setEmailFilter('');
    setAuthFilter('');
    setCurrentPage(1);
  }

  function fetchUsers() {
    dispatch(
      userActions.fetchUsers({
        searchKey: searchKeyFilter,
        name: nameFilter,
        email: emailFilter,
        autherization: authFilter,
        KeyProperty: sortBy,
        IsAscending: sortDirection === 'asc' ? true : false,
        pageNumber: searchKeyFilter
          ? 1
          : nameFilter === '' && emailFilter === '' && authFilter === ''
          ? currentPage
          : 1,
      })
    );
  }

  useEffect(() => {
    fetchUsers();
  }, [searchKeyFilter, nameFilter, emailFilter, authFilter, currentPage, dispatch, sortBy, sortDirection]);

  function addUserHandler() {
    setSelectedUser(null);
    setOpenCreateEditUserModal(true);
  }

  function toggleFilters() {
    setShowFilters((prevVal) => !prevVal);
  }

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
  }

  const tableHeaders = userTableHeaders(
    nameFilter,
    setNameFilter,
    emailFilter,
    setEmailFilter,
    authFilter,
    setAuthFilter
  );

  // const modifiedUserData = orderBy(users, [(client) => `${client[sortBy ?? 'fullName']}`.toLowerCase()], sortDirection);

  function handleEditUserClick(user: User) {
    setSelectedUser(user);
    setOpenCreateEditUserModal(true);
  }

  function handleDeleteUserClick(user: User) {
    setSelectedUser(user);
    setOpenDeleteUserModal(true);
  }

  const tableData = userTableData(
    users,
    tableHeaders.map((i) => i.width),
    handleEditUserClick,
    handleDeleteUserClick
  );

  function handleCloseCreateEditUserModal() {
    setSelectedUser(null);
    setOpenCreateEditUserModal(false);
  }

  function handleCloseDeleteUserModal() {
    setSelectedUser(null);
    setOpenDeleteUserModal(false);
  }

  return (
    <PageLayout>
      <CommonHeader header={'Gebruikers'} subHeader={'Beheer gebruikers'} />
      <Paper className="common-paper">
        <TableSearchBar
          buttonStartIcon={<AddUserIcon />}
          buttonText={'Gebruikers toevoegen'}
          placeHolder={'Zoek naam of e-mailadres...'}
          onSearch={setSearchKeyFilter}
          buttonClickHandler={addUserHandler}
          showFilterToggle
          toggleFilterHandler={toggleFilters}
          showFilter={showFilters}
        />
        <CustomTable
          tableHeaders={tableHeaders}
          showFilters={showFilters}
          tableData={tableData}
          totalNumberOfPages={totalNumberOfPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          isLoading={fetchAllClientIsLoading}
        />
      </Paper>
      <CreateEditUserModal
        openModal={openCreateEditUserModal}
        closeModal={handleCloseCreateEditUserModal}
        selectedUser={selectedUser!}
        fetchUsers={!selectedUser ? resetAllFilters : fetchUsers}
      />
      <DeleteUserModal
        openModal={openDeleteUserModal}
        closeModal={handleCloseDeleteUserModal}
        selectedUser={selectedUser!}
        fetchUsers={resetAllFilters}
      />
    </PageLayout>
  );
}

export default UsersPage;
