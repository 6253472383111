import React, { useEffect, useMemo, useState } from 'react';
import Card from '@mui/material/Card';
import { useDispatch } from 'react-redux';
import ChangeStyleModal from '../ChangeStyleModal';
import { clientActions } from '../../store/slice/clientSlice';
import { useAppSelector } from '../../../../store/types';
import {
  chosenStyleSelector,
  createQuoteIsSuccessSelector,
  fetchClientQuoteSelector,
  sendQuoteIsSuccessSelector,
} from '../../store/selectors/clientSelector';
import { getReadableFloor } from '../../../../utils/getReadableFloorName';
import { styled } from '@mui/material/styles';
import { CreateQuote, SelectedMaterial } from '../../types';
import priceFormatter from '../../../../utils/priceFormatter';
import Box from '@mui/material/Box';
import SendEmailModal from '../SendEmailModal';

const FloorLabel = styled('label')`
  font-weight: 400;
  font-size: 18px;
  font-style: italic;
  font-family: NeulisAlt, sans-serif;
  color: #45525e;
  margin-bottom: 8px;
  line-height: normal;
`;

const TotalPriceLabel = styled('p')`
  font-weight: 400;
  font-size: 18px;
  font-style: italic;
  font-family: NeulisAlt, sans-serif;
  color: #45525e;
  line-height: normal;
`;

const Label = styled('p')`
  font-size: 16px;
  font-weight: 700;
  color: #343e47;
  margin-top: 8px;
  line-height: normal;
`;

const Value = styled('p')`
  font-size: 16px;
  font-weight: 300;
  color: #343e47;
  line-height: normal;
`;

const Price = styled('p')`
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
`;

const ValueDiv = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const BtnsDiv = styled('div')`
  margin: 15px 0;
  display: flex;
  gap: 15px;
`;

const QuoteBtn = styled('button')`
  text-transform: uppercase;
  background-color: #bd8890;
  border: none;
  border-radius: 5px;
  color: #fffffe;
  cursor: pointer;
  padding: 8px 10px;
  font-family: Public-sans;
`;

const StyleBtn = styled('button')`
  text-transform: uppercase;
  border: 1px solid #bd8890;
  border-radius: 5px;
  color: #bd8890;
  cursor: pointer;
  padding: 8px 10px;
  background-color: #fffffe;
  font-family: Public-sans;
`;

// const UnderText = styled('p')`
//   font-weight: 300;
//   font-size: 12px;
//   color: #45525e;
// `;

interface MaterialDetails {
  type: string;
  values: { name: string; price: number | string }[];
}

interface FloorWiseMaterialDetails {
  [key: string]: MaterialDetails[];
}

function ExistingChoosenStyle({ materialStyles }: { materialStyles: FloorWiseMaterialDetails }) {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={4}>
      {Object.keys(materialStyles).map((key) => {
        const itemList: MaterialDetails[] = materialStyles[key];
        return (
          <Box display={'flex'} flexDirection={'column'}>
            <FloorLabel>{getReadableFloor(parseInt(key) + 1)}</FloorLabel>
            {itemList.map((item) => (
              <>
                <Label>
                  {item.type === 'floor'
                    ? 'Vloerdecoratie'
                    : item.type === 'window'
                    ? 'Raamdecoratie'
                    : item.type === 'curtain'
                    ? 'Gordijnen'
                    : item.type === 'material'
                    ? 'Materiaal kiezen'
                    : 'Selecteer template'}
                </Label>
                {item.values.map((val) => (
                  <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                    <Value>{val.name}</Value>
                    {(item.type === 'floor' || item.type === 'window' || item.type === 'curtain') &&
                      (val.price === 0 || val.price === null) && <Price>€ 0</Price>}
                    {val.price !== 0 && <Price>{priceFormatter(val.price.toString())}</Price>}
                  </Box>
                ))}
              </>
            ))}
          </Box>
        );
      })}
    </Box>
  );
}

interface ChosenStyleCardProps {
  selectedClientId: number;
  selectedClientEmail: string;
  selectedClientProjectId: number;
  clientName: string;
  floors: number;
  idTemplete: number;
  idDefaultTemplete: number;
  idLots: number;
}

export default function ChosenStyleCard({
  selectedClientId,
  selectedClientEmail,
  selectedClientProjectId,
  clientName,
  floors,
  idTemplete,
  idDefaultTemplete,
  idLots,
}: ChosenStyleCardProps) {
  const dispatch = useDispatch();
  const [openChangeStyleModal, setOpenChangeStyleModal] = useState(false);
  const [openSendEmailModal, setOpenSendEmailModal] = useState(false);
  const [hasTocreateQuote, setHasToCreateQuote] = useState(false);
  const [quoteSent, setQuoteSent] = useState(false);
  const chosenStyle = useAppSelector(chosenStyleSelector);
  const createQuoteIsSuccess = useAppSelector(createQuoteIsSuccessSelector);
  const selectedMaterials = chosenStyle?.selectedMaterials ?? [];
  const floorStatus = chosenStyle?.flooeStatus ?? [];
  const getClientQuote = useAppSelector(fetchClientQuoteSelector);
  const sendQuoteIsSuccess = useAppSelector(sendQuoteIsSuccessSelector);

  useEffect(() => {
    if (selectedClientId) {
      dispatch(clientActions.fetchChosenStyle(selectedClientId));
      dispatch(clientActions.fetchClientQuote(selectedClientId));
    }
  }, [selectedClientId, dispatch]);

  useEffect(() => {
    if (createQuoteIsSuccess) {
      dispatch(clientActions.fetchClientQuote(selectedClientId));
      setHasToCreateQuote(false);
    }
  }, [createQuoteIsSuccess, dispatch, selectedClientId]);

  useEffect(() => {
    if (sendQuoteIsSuccess) {
      setQuoteSent(true);
    }
  }, [sendQuoteIsSuccess]);

  const materialStyles = useMemo(() => {
    function generateArray(i: number) {
      const modifiedArry: MaterialDetails[] = [];
      const materials = selectedMaterials.filter((j: SelectedMaterial) => j.floors === i);
      const floorSts = floorStatus?.find((j) => j.floor === i);
      if (materials.length > 0) {
        const floorMaterial = materials.filter((item: SelectedMaterial) => item.productGroupCode === 1);
        const WindowMaterial = materials.filter((item: SelectedMaterial) => item.productGroupCode === 2);
        const curtainMaterial = materials.filter((item: SelectedMaterial) => item.productGroupCode === 3);
        if (floorMaterial.length > 0) {
          modifiedArry.push({
            type: 'floor',
            values: [
              {
                name: floorMaterial[0].productName,
                price: floorMaterial[0].price,
              },
            ],
          });
        }
        if (WindowMaterial.length > 0) {
          modifiedArry.push({
            type: 'window',
            values: [
              {
                name: WindowMaterial[0].productName,
                price: WindowMaterial[0].price,
              },
            ],
          });
        }
        if (curtainMaterial.length > 0) {
          modifiedArry.push({
            type: 'curtain',
            values: [
              {
                name: curtainMaterial[0].productName,
                price: curtainMaterial[0].price,
              },
            ],
          });
        }
      } else {
        modifiedArry.push({
          type: floorSts?.status === 2 ? 'material' : 'template',
          values: [
            {
              name:
                floorSts?.status === 2
                  ? 'Er is geen materiaal geselecteerd voor deze vloer'
                  : 'Er is geen template geselecteerd voor deze verdieping',
              price: 0,
            },
          ],
        });
      }
      return modifiedArry;
    }

    return [...Array(floors).keys()].reduce(
      (ac, i) => ({
        ...ac,
        [i]: generateArray(i + 1),
      }),
      {}
    );
  }, [floors, selectedMaterials, floorStatus]);

  function handleCreateNewQuote() {
    if (getClientQuote && !hasTocreateQuote) {
      setOpenSendEmailModal(true);
    } else {
      if (selectedClientId && selectedClientProjectId) {
        const payload: CreateQuote = {
          id: selectedClientId,
          idProject: selectedClientProjectId,
        };
        dispatch(clientActions.createQuoteInChangeStyle(payload));
      }
    }
  }

  function handleChangeStyle() {
    setOpenChangeStyleModal(true);
  }

  function handleCloseChangeStyleModal() {
    setOpenChangeStyleModal(false);
  }

  return (
    <Card style={{ padding: '20px 12px', boxShadow: '0px 10px 24px 0px rgba(12, 27, 42, 0.06)' }}>
      <ChangeStyleModal
        openModal={openChangeStyleModal}
        closeModal={handleCloseChangeStyleModal}
        selectedClientId={selectedClientId}
        floors={floors}
        idTemplete={idTemplete}
        idDefaultTemplete={idDefaultTemplete}
        setHasToCreateQuote={() => setHasToCreateQuote(true)}
        idLots={idLots}
      />
      <SendEmailModal
        openModal={openSendEmailModal}
        closeModal={() => setOpenSendEmailModal(false)}
        selectedClientEmail={selectedClientEmail}
        clientName={clientName}
        selectedClientId={selectedClientId}
        idLots={idLots}
      />
      {selectedMaterials?.length === 0 ? (
        <div style={{ margin: '15px 50px' }}>
          <FloorLabel>Oh nee... er is geen stijl geselecteerd</FloorLabel>
          <Value>
            Als de deadline urgent wordt, neem dan contact op met de klant of klik op de knop om een stijl te selecteren
            en een offerte te versturen
          </Value>
          <QuoteBtn onClick={handleChangeStyle} style={{ marginTop: '30px' }}>
            Kies producten
          </QuoteBtn>
        </div>
      ) : (
        <>
          <ExistingChoosenStyle materialStyles={materialStyles} />
          <Box mt={4} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
            <TotalPriceLabel>Totaalprijs</TotalPriceLabel>
            {chosenStyle.totalPrice && (
              <Price style={{ fontSize: '20px' }}>{priceFormatter(chosenStyle.totalPrice.toString())}</Price>
            )}
          </Box>
          <Box mt={4} mb={4} display={'flex'} flexDirection={'row'} columnGap={2}>
            <StyleBtn onClick={handleChangeStyle}>Stijl wijzigen</StyleBtn>
            <QuoteBtn onClick={handleCreateNewQuote}>
              {!getClientQuote || hasTocreateQuote
                ? 'Offerte maken'
                : quoteSent || chosenStyle?.emailStatus
                ? 'E-mail verstuurd'
                : 'E-mail verzenden'}
            </QuoteBtn>
          </Box>
        </>
      )}
    </Card>
  );
}
