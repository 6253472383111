import { ApiRequest } from '../../../../api/ApiService';
import { Method } from '../../../../api/types';
import { ENDPOINTS } from '../../../../api/constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { UploadImageDataParams } from '../../types';
import { productActions } from '../slice/productsSlice';

async function callApi(props: UploadImageDataParams) {
  const payload = {
    fileName: props.uploadPhotoParams.fileName,
    fileData: props.uploadPhotoParams.fileData,
  };
  return ApiRequest(Method.POST, ENDPOINTS.uploadProductGroupImage, { id: props.id }, undefined, payload);
}

export default function* callUploadProductGroupImageSaga({ payload }: PayloadAction<UploadImageDataParams>) {
  try {
    yield call(callApi, payload);
    yield put(productActions.uploadProductGroupImageSuccess());
    SuccessToast('Succes!', 'Afbeelding succesvol geüpload');
  } catch (error: unknown) {
    console.error('callUploadProductGroupImageSaga', error);
    yield put(productActions.uploadProductGroupImageFailure());
    // @ts-ignore
    ErrorToast(undefined, error.toString());
  }
}
