import * as React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Box from '@mui/material/Box';

import PrivateRoutes from './Private.route';
import SSOValidator from '@authentication/components/ssoValidator/SSOValidator';
import { ROUTE_CLIENTS, ROUTE_LOGIN, ROUTE_PRODUCTS, ROUTE_PROJECTS, ROUTE_USERS } from '../utils/routes';
import LoginPage from '../modules/authentication/pages/LoginPage';
import ClientsPage from '../modules/clients/pages/ClientsPage';
import ProjectsPage from '../modules/projects/pages/Projects';
import ProductsPage from '../modules/products/pages/ProductsPage';
import UsersPage from '../modules/users/pages/UsersPage';
import ClientDetailsPage from '../modules/clients/pages/ClientDetailsPage';
import ProjectOverview from '../modules/projects/pages/ProjectOverviewPage';
import NotFoundPage from '../shared/components/notFound/NotFoundPage';

const BASE_PATH = process.env.REACT_APP_BASE_PATH;

function MainRoutes() {
  return (
    <BrowserRouter basename={BASE_PATH}>
      <Box sx={{ display: 'flex' }}>
        <Routes>
          <Route element={<SSOValidator />} path="/" />
          <Route element={<PrivateRoutes />}>
            <Route element={<Outlet />} path={ROUTE_CLIENTS}>
              <Route index element={<ClientsPage />} />
              <Route path=":clientId/:lotsId" element={<ClientDetailsPage />} />
            </Route>
            <Route element={<Outlet />} path={ROUTE_PROJECTS}>
              <Route index element={<ProjectsPage />} />
              <Route path=":projectId" element={<Outlet />}>
                <Route path="lotTypes" element={<Outlet />}>
                  <Route index element={<ProjectOverview />} />
                  <Route path=":idLotType" element={<Outlet />}>
                    <Route path="clients" element={<ClientsPage />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route element={<Outlet />} path={ROUTE_PRODUCTS}>
              <Route index element={<Navigate to={`${ROUTE_PRODUCTS}/overview`} replace />} />
              <Route path="overview" element={<ProductsPage />} />
              <Route path="templates" element={<ProductsPage />} />
            </Route>
            <Route element={<UsersPage />} path={ROUTE_USERS} />
          </Route>
          <Route element={<LoginPage />} path={ROUTE_LOGIN} />
          <Route element={<NotFoundPage />} path="*" />
        </Routes>
      </Box>
    </BrowserRouter>
  );
}

export default MainRoutes;
