import { ApiRequest } from '../../../../api/ApiService';
import { Method } from '../../../../api/types';
import { ENDPOINTS, WORKSPACE_ID } from '../../../../api/constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { userActions } from '../slice/usersSlice';

async function callApi(userId: number) {
  const queryParam = { workspaceId: WORKSPACE_ID };
  const pathParam = { id: userId };
  return ApiRequest(Method.DELETE, ENDPOINTS.deleteUser, pathParam, queryParam);
}

export default function* callDeleteUserSaga({ payload }: PayloadAction<number>) {
  try {
    yield call(callApi, payload);
    yield put(userActions.deleteUserSuccess());
    SuccessToast('Succes!', 'Gebruiker succesvol verwijderd');
  } catch (error: unknown) {
    console.error('callDeleteUserSaga', error);
    yield put(userActions.deleteUserFailure());
    ErrorToast(undefined, error.toString());
  }
}
