import { FilterType, TableHeaders } from '../../../shared/types';

export default function lotTypeTableHeaders(
  lotTypeFilter: string | undefined,
  setLotTypeFilter: (a: string | undefined) => void,
  styledLotFilter: number,
  setStyledLotFilter: (a: number) => void,
  totalMeterFilter: number,
  setTotalMeterFilter: (value: number) => void,
  floorFilter: number,
  setFloorFilter: (value: number) => void
): TableHeaders[] {
  return [
    {
      label: 'Type woningen',
      isSortable: true,
      filterPlaceHolder: 'Filter Type woningen...',
      filterType: FilterType.search,
      filterValue: lotTypeFilter,
      onFilterChange: setLotTypeFilter,
      columnName: 'lottype',
    },
    {
      label: 'Aantal bouwnummers',
      isSortable: true,
      filterPlaceHolder: 'Filter aantal bouwnummers...',
      filterType: FilterType.search,
      filterValue: styledLotFilter,
      onFilterChange: setStyledLotFilter,
      columnName: 'styleslot',
      width: '18%',
    },
    {
      label: 'Totaal m2',
      isSortable: true,
      filterPlaceHolder: 'Filter totaal m2...',
      filterType: FilterType.search,
      filterValue: totalMeterFilter,
      onFilterChange: setTotalMeterFilter,
      columnName: 'totalMeter',
      width: '10%',
    },
    {
      label: 'Verdiepingen',
      filterPlaceHolder: 'Filter verdiepingen...',
      filterType: FilterType.select,
      filterValue: floorFilter,
      onFilterChange: setFloorFilter,
      filterOptions: [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
      ],
      columnName: 'floors',
      align: 'center',
      width: '92px',
    },
    {
      label: '',
      columnName: 'view',
      align: 'center',
      width: '34px',
    },
    {
      label: '',
      columnName: 'view',
      align: 'center',
      width: '34px',
    },
    {
      label: '',
      columnName: 'view',
      align: 'center',
      width: '34px',
    },
  ];
}
