import React from 'react';
import Button from '@mui/material/Button';

import classes from './GoBackButton.module.css';
import { ReactComponent as GoBackIcon } from '../../../../assets/images/goBack.svg';

interface GoBackButtonProps {
  onClick: () => void;
}

function GoBackButton({ onClick }: GoBackButtonProps) {
  return (
    <Button className={classes.rootButton} onClick={onClick}>
      <GoBackIcon />
      <span className={classes.spanText}>Ga terug naar overzicht</span>
    </Button>
  );
}

export default GoBackButton;
