import React from 'react';
import { Autocomplete } from '@mui/material';
import Chip from '@mui/material/Chip';
import { Field, useFormikContext } from 'formik';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '../../../store/types';
import { materialsSelector } from '../../../modules/clients/store/selectors/clientSelector';

const StyledLabel = styled('p')<{ $labelColor: string; $disabled: boolean }>`
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  color: ${({ $labelColor, $disabled }) => ($disabled ? '#50606B' : $labelColor)};
  margin-bottom: 4px;
  line-height: 110%;
`;

interface MultiSelectProps {
  name: string;
  label?: string;
  labelColor?: string;
  placeholder?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

export default function MultiSelect(props: MultiSelectProps) {
  const { name, label, labelColor = '#0c1b2a', placeholder, disabled = false, isLoading = false } = props;
  const { setFieldValue, values } = useFormikContext();
  const materials = useAppSelector(materialsSelector);

  const windowDecorationMaterials = materials.reduce((acc, i) => {
    if (i.materialGroupCode === 2) {
      acc.push({ idMaterial: i.idMaterialType, materialName: i.materialTypeName });
    }
    return acc;
  }, []);

  const floorDecorationMaterials = materials.reduce((acc, i) => {
    if (i.materialGroupCode === 1) {
      acc.push({ idMaterial: i.idMaterialType, materialName: i.materialTypeName });
    }
    return acc;
  }, []);

  const curtainTemplateMaterials = materials.reduce((acc, i) => {
    if (i.materialGroupCode === 3) {
      acc.push({ idMaterial: i.idMaterialType, materialName: i.materialTypeName });
    }
    return acc;
  }, []);

  const options = {
    windowTemplateMaterials: windowDecorationMaterials,
    floorTemplateMaterials: floorDecorationMaterials,
    curtainTemplateMaterials: curtainTemplateMaterials,
  };

  return (
    <>
      {label && (
        <StyledLabel $labelColor={labelColor} $disabled={disabled || isLoading}>
          {label}
        </StyledLabel>
      )}
      <Autocomplete
        disabled={isLoading || disabled}
        multiple
        value={values[name]}
        onChange={(event, newValue) => {
          setFieldValue(name, [...newValue]);
        }}
        sx={{
          '& .MuiFilledInput-root': {
            '& .MuiAutocomplete-endAdornment': {
              display: 'none',
            },
          },
        }}
        options={options[name]}
        isOptionEqualToValue={(option, value) => option.idMaterial === value.idMaterial}
        getOptionLabel={(option) => option.materialName}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              variant="filled"
              sx={{
                borderRadius: '3px',
                backgroundColor: '#4B7C8A',
                color: '#FFFFFE',
                maxHeight: '25px',
                '& .MuiChip-deleteIcon': {
                  fontSize: '15px',
                  color: '#FFFFFE',
                },
              }}
              label={option.materialName}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <Field
            as={TextField}
            placeholder={placeholder}
            {...params}
            variant="filled"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: {
                background: 'transparent',
                border: '1px solid #9a9fa5',
                padding: '4px',
                borderRadius: '5px',
              },
            }}
          />
        )}
      />
    </>
  );
}
