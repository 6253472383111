import React from 'react';
import { Typography } from '@mui/material';

interface TitleProps {
  text: string;
}

export default function Title({ text }: TitleProps) {
  return (
    <Typography
      variant="h1"
      sx={{
        color: '#343E47',
        fontFamily: 'NeulisAlt',
        fontWeight: 600,
        fontStyle: 'italic',
        fontSize: 32,
        lineHeight: '40px',
        letterSpacing: '1px',
      }}
    >
      {text}
    </Typography>
  );
}
