import React from 'react';
import { List } from '@mui/material';
import ClientsIcon from '../../assets/images/menu/clients.svg';
import ProjectsIcon from '../../assets/images/menu/projects.svg';
import ProductIcon from '../../assets/images/menu/products.svg';
import UsersIcon from '../../assets/images/menu/users.svg';
import { ROUTE_CLIENTS, ROUTE_PRODUCTS, ROUTE_PROJECTS, ROUTE_USERS } from '../utils/routes';
import PageNavigationLink from './PageNavigationLink';

const menuItems = [
  {
    label: 'Klanten',
    icon: ClientsIcon,
    route: ROUTE_CLIENTS,
  },
  {
    label: 'Projecten',
    icon: ProjectsIcon,
    route: ROUTE_PROJECTS,
  },
  {
    label: 'Producten',
    icon: ProductIcon,
    route: ROUTE_PRODUCTS,
  },
  {
    label: 'Gebruikers',
    icon: UsersIcon,
    route: ROUTE_USERS,
  },
];

interface MainMenuProps {
  open: boolean;
}

function MainMenu(props: MainMenuProps) {
  const { open } = props;

  return (
    <div style={{ height: '100%' }}>
      <List>
        {menuItems.map((item) => (
          <PageNavigationLink key={item.route} item={item} open={open} />
        ))}
      </List>
    </div>
  );
}

export default MainMenu;
