import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import PageLayout from '../../../layout/PageLayout';
import CommonHeader from '../../../shared/components/commonHeader/CommonHeader';
import TableSearchBar from '../../../shared/components/tableSearchBar/TableSearchBar';
import { useAppSelector } from '../../../store/types';
import { LotType, SyncLotsWithExactParam } from '../types';
import { projectActions } from '../store/slice/projectsSlice';
import GoBackButton from '../../../shared/components/goBackButton/GoBackButton';
import EditFloorAmount from '../components/EditFloorAmount';
import { ROUTE_PROJECTS } from '../../../utils/routes';
import { TableColumnSortDirection } from '../../../shared/types';
import CustomTable from '../../../shared/components/customTable/CustomTable';
// import orderBy from 'lodash/orderBy';
import lotTypeTableData from '../components/lotTypeTableData';
import lotTypeTableHeaders from '../components/lotTypeTableHeaders';
import {
  fetchLotTypesIsLoadingSelector,
  lotTypesSelector,
  lotTypesTotalPagesSelector,
  projectsSelector,
  projectSyncLotsWithExactHasErrorSelector,
  projectSyncLotsWithExactIsLoadingSelector,
  projectSyncLotsWithExactIsSuccessSelector,
} from '../store/selectors/projectsSelectors';
import CreateEditTemplateModal from '../../clients/components/CreateEditTemplateModal';
import MaterialOverviewModal from '../components/MaterialOverviewModal';

function ProjectOverview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lotTypes = useAppSelector(lotTypesSelector);
  const fetchAllClientIsLoading = useAppSelector(fetchLotTypesIsLoadingSelector);
  const totalNumberOfPages = useAppSelector(lotTypesTotalPagesSelector);
  const projectSyncLotsWithExactIsSuccess = useAppSelector(projectSyncLotsWithExactIsSuccessSelector);
  const projectSyncLotsWithExactHasError = useAppSelector(projectSyncLotsWithExactHasErrorSelector);
  const projectSyncLotsWithExactIsLoading = useAppSelector(projectSyncLotsWithExactIsLoadingSelector);

  const { projectId: selectedProjectId } = useParams();
  const projects = useAppSelector(projectsSelector);
  const selectedProject = (projects || []).find((i) => `${i.idProject}` === selectedProjectId);
  const selectedProjectName = selectedProject?.projectName;

  const [selectedLotType, setSelectedLotType] = useState<LotType>();
  const [openEditFloorModal, setOpenEditFloorModal] = useState(false);
  const [openMaterialOverviewModal, setOpenMaterialOverviewModal] = useState(false);
  const [openAddTemplateModal, setOpenAddTemplateModal] = useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const [sortBy, setSortBy] = useState<string | undefined>('lottype');
  const [sortDirection, setSortDirection] = useState<TableColumnSortDirection>(TableColumnSortDirection.ASC);

  const [selectedProjectIdFilter, setSelectedProjectIdFilter] = useState<number>(null);
  const [searchKeyFilter, setSearchKeyFilter] = useState<string>('');
  const [lotTypeFilter, setLotTypeFilter] = useState<string>('');
  const [styledLotFilter, setStyledLotFilter] = useState<number>();
  const [totalMeterFilter, setTotalMeterFilter] = useState<number>();
  const [floorFilter, setFloorFilter] = useState<number>();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setSelectedProjectIdFilter(parseInt(selectedProjectId));
  }, [selectedProjectId]);

  useEffect(() => {
    if (selectedProjectId) {
      dispatch(projectActions.fetchProjects({ pageSize: 1000 }));
    }
  }, [dispatch, selectedProjectId]);

  useEffect(() => {
    // if (selectedProjectIdFilter) {
    //   const payload: SyncLotsWithExactParam = {
    //     idProject: selectedProjectIdFilter,
    //   };
    //   dispatch(projectActions.projectSyncLotsWithExact(payload));
    // }
    fetchLotTypes();
  }, [
    selectedProjectIdFilter,
    searchKeyFilter,
    lotTypeFilter,
    styledLotFilter,
    totalMeterFilter,
    floorFilter,
    currentPage,
    dispatch,
    sortBy,
    sortDirection,
  ]);

  useEffect(() => {
    if (projectSyncLotsWithExactIsLoading) {
      const timer = setTimeout(() => {
        dispatch(projectActions.resetProjectSyncLotsWithExact());
        fetchLotTypes();
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [projectSyncLotsWithExactIsLoading, dispatch]);

  function toggleFilters() {
    setShowFilters((prevVal) => !prevVal);
  }

  function fetchLotTypes() {
    if (selectedProjectIdFilter) {
      dispatch(
        projectActions.fetchLotTypes({
          id: selectedProjectIdFilter,
          searchKey: searchKeyFilter,
          lotType: lotTypeFilter,
          styleSlot: styledLotFilter,
          totalMeter: totalMeterFilter,
          floors: floorFilter,
          KeyProperty: sortBy,
          IsAscending: sortDirection === 'asc' ? true : false,
          pageNumber: searchKeyFilter
            ? 1
            : lotTypeFilter === '' && !styledLotFilter && !totalMeterFilter && !floorFilter
            ? currentPage
            : 1,
        })
      );
    }
  }

  useEffect(() => {
    if (projectSyncLotsWithExactIsSuccess || projectSyncLotsWithExactHasError) {
      fetchLotTypes();
      dispatch(projectActions.resetProjectSyncLotsWithExact());
    }
  }, [dispatch, projectSyncLotsWithExactIsSuccess, projectSyncLotsWithExactHasError]);

  function handleEditFloorClick(lotType: LotType) {
    setSelectedLotType(lotType);
    setOpenEditFloorModal(true);
  }

  function handleMaterialOverviewClick(lotType: LotType) {
    if (!lotType.idLotType) {
      alert('Please select templates');
    }
    setSelectedLotType(lotType);
    setOpenMaterialOverviewModal(true);
  }

  function handleAddTemplateClick(lotType: LotType) {
    setSelectedLotType(lotType);
    setOpenAddTemplateModal(true);
  }

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
  }

  function handleCloseEditFloorModal() {
    setOpenEditFloorModal(false);
    setSelectedLotType(null);
  }

  function handleCloseMaterialOverviewModal() {
    setOpenMaterialOverviewModal(false);
    setSelectedLotType(null);
  }

  function handleCloseAddTemplateModal() {
    setOpenAddTemplateModal(false);
    setSelectedLotType(null);
  }

  function handleClickOnRow(idLotType: number) {
    navigate(`${ROUTE_PROJECTS}/${selectedProjectId}/lotTypes/${idLotType}/clients`);
  }

  // @ts-ignore
  // const modifiedLotTypeData = orderBy(
  //   lotTypes,
  //   [(lotType) => `${lotType[sortBy ?? 'lotType1']}`.toLowerCase()],
  //   sortDirection
  // );

  const tableHeaders = lotTypeTableHeaders(
    lotTypeFilter,
    setLotTypeFilter,
    styledLotFilter,
    setStyledLotFilter,
    totalMeterFilter,
    setTotalMeterFilter,
    floorFilter,
    setFloorFilter
  );

  const tableData = lotTypeTableData(
    lotTypes,
    tableHeaders.map((i) => i.width),
    handleEditFloorClick,
    handleMaterialOverviewClick,
    handleAddTemplateClick,
    handleClickOnRow,
    showFilters
  );

  return (
    <PageLayout>
      <CommonHeader
        header={`Bouwtypes projecten '${selectedProjectName}'`}
        subHeader={'Een overzicht van alle verschillende typen huizen en kavels'}
      >
        <GoBackButton onClick={() => navigate(`${ROUTE_PROJECTS}`)} />
      </CommonHeader>
      <Paper className="common-paper">
        <TableSearchBar
          placeHolder={'Zoek type woningen...'}
          showFilterToggle
          toggleFilterHandler={toggleFilters}
          onSearch={setSearchKeyFilter}
          showFilter={showFilters}
        />
        <CustomTable
          tableHeaders={tableHeaders}
          showFilters={showFilters}
          tableData={tableData}
          totalNumberOfPages={totalNumberOfPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          isLoading={fetchAllClientIsLoading || projectSyncLotsWithExactIsLoading}
        />
      </Paper>
      <EditFloorAmount
        openModal={openEditFloorModal}
        closeModal={handleCloseEditFloorModal}
        selectedLotType={selectedLotType}
        selectedProjectName={selectedProjectName}
        refreshLotTypes={fetchLotTypes}
      />
      <CreateEditTemplateModal
        openModal={openAddTemplateModal}
        closeModal={handleCloseAddTemplateModal}
        idLotType={selectedLotType?.idLotType}
        floors={selectedLotType?.floors}
        refreshClients={fetchLotTypes}
        selectedClientName={selectedLotType?.lotType1}
        floorImageUploadAvailable
      />
      <MaterialOverviewModal
        openModal={openMaterialOverviewModal}
        closeModal={handleCloseMaterialOverviewModal}
        idLotType={selectedLotType?.idLotType}
        floors={selectedLotType?.floors}
        refreshClients={fetchLotTypes}
        title={selectedLotType?.lotType1}
      />
    </PageLayout>
  );
}

export default ProjectOverview;
