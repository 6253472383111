import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { FilterType, noop, TableBodyRow, TableColumnSortDirection, TableHeaders } from '@shared/types';
import { styled as materialStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import HeaderColumnContent from '@shared/components/customTable/HeaderColumnContent';
import SearchField from '@shared/components/searchField/SearchField';
import SelectField from '@shared/components/selectField/SelectField';

interface CustomTableDataCellProps {
  width: string;
  fontWeight?: number;
  clickable?: boolean;
  display?: string;
  justify?: string;
}

export const CustomTableDataCell = materialStyled(TableCell)(
  ({ width, fontWeight, clickable, display, justify }: CustomTableDataCellProps) => ({
    padding: '8px 4px',
    fontWeight: fontWeight ? fontWeight : 'normal',
    fontSize: 14,
    color: '#343E47',
    lineHeight: '140%',
    minWidth: width,
    maxWidth: width,
    cursor: clickable ? 'pointer' : 'unset',
    display: display,
    justifyContent: justify,
    height: '100%',
    alignItems: 'center',
  })
);

const CustomTableHeaderCell = materialStyled(TableCell)(({ width }: { width: string }) => ({
  fontWeight: 700,
  fontSize: 14,
  color: '#9B9B9B',
  borderBottom: '1px solid #9B9B9B !important',
  lineHeight: '110%',
  height: '34px',
  padding: '8px 4px',
  minWidth: width,
  maxWidth: width,
})) as typeof TableCell;

interface CustomTableProps {
  tableHeaders: TableHeaders[];
  showFilters: boolean;
  tableData: TableBodyRow[];
  totalNumberOfPages?: number;
  currentPage?: number;
  handlePageChange?: (event: React.ChangeEvent<unknown>, value: number) => void;
  sortBy: string | undefined;
  setSortBy: (a: string | undefined) => void;
  sortDirection: TableColumnSortDirection;
  setSortDirection: (a: TableColumnSortDirection) => void;
  isLoading: boolean;
  hidePagination?: boolean;
  type?: string;
  isMaterialOverview?: boolean;
  appliedFilter?: boolean;
}

function getSelectFieldWidth(width: string) {
  if (width.includes('px')) {
    return `${parseInt(width.slice(0, -2)) - 8}px`;
  }
  return '100%';
}

export default function CustomTable(props: CustomTableProps) {
  const {
    tableHeaders = [],
    showFilters = false,
    tableData = [],
    totalNumberOfPages,
    currentPage,
    handlePageChange = noop,
    sortBy,
    setSortBy,
    sortDirection = TableColumnSortDirection.ASC,
    setSortDirection,
    isLoading,
    hidePagination = false,
    isMaterialOverview = false,
    appliedFilter = false,
  } = props;

  const isTableEmpty = (tableData || []).length === 0;

  return (
    <TableContainer
      sx={{
        marginTop: '24px',
      }}
    >
      <Table
        stickyHeader
        sx={{
          height: 'max-content',
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
          },
        }}
      >
        <TableHead>
          <TableRow>
            {tableHeaders.map((tableColumn: TableHeaders, index) => (
              <CustomTableHeaderCell align={tableColumn.align ?? 'left'} key={index} width={tableColumn.width}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  width={'100%'}
                  height={'100%'}
                  justifyContent={'space-between'}
                >
                  <HeaderColumnContent
                    tableColumn={tableColumn}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    sortDirection={sortDirection}
                    setSortDirection={setSortDirection}
                  />
                  {showFilters && (
                    <Box display={'flex'} mt={'12px'} mb={'8px'}>
                      {tableColumn.filterType === FilterType.select && (
                        <SelectField
                          options={tableColumn.filterOptions!}
                          value={`${tableColumn.filterValue ?? ''}`}
                          handleOnChange={tableColumn.onFilterChange!}
                          placeholder={tableColumn.filterPlaceHolder!}
                          minWidth={getSelectFieldWidth(tableColumn.width)}
                          maxWidth={getSelectFieldWidth(tableColumn.width)}
                        />
                      )}
                      {tableColumn.filterType === FilterType.search && (
                        <SearchField
                          value={`${tableColumn.filterValue ?? ''}`}
                          handleOnChange={tableColumn.onFilterChange!}
                          placeholder={tableColumn.filterPlaceHolder!}
                          type={tableColumn.type}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </CustomTableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{ position: 'relative', opacity: isLoading ? 0.6 : 1 }}>
          {isTableEmpty && (
            <TableRow
              className={'table-body-row'}
              sx={{
                height: '200px',
                borderBottom: '1px solid #F6F7F9',
                '&:hover': {
                  background: 'transparent',
                },
              }}
            >
              <TableCell colSpan={10}>
                <Typography textAlign={'center'}>
                  {isMaterialOverview && !appliedFilter
                    ? 'Er is geen sjabloon geselecteerd. Selecteer een template'
                    : 'Geen gegevens beschikbaar'}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {(tableData || []).map((row) => (
            <TableRow
              className={'table-body-row'}
              key={row.id}
              sx={{
                minHeight: '44px',
                borderBottom: '1px solid #F6F7F9',
                verticalAlign: 'middle',
                '&:hover': {
                  background:
                    'linear-gradient(0deg, rgba(12, 27, 42, 0.06), rgba(12, 27, 42, 0.06)), rgba(255, 255, 255, 0.8);',
                },
              }}
            >
              {row.tableCell}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!isTableEmpty && currentPage && !hidePagination && (
        <Box mt={2} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
          <Pagination count={totalNumberOfPages} page={currentPage} onChange={handlePageChange} />
        </Box>
      )}
    </TableContainer>
  );
}
