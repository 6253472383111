import { call, put } from 'redux-saga/effects';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { MasterColorDropDown } from '../../types';

async function callApi() {
  return ApiRequest(Method.GET, ENDPOINTS.getMasterColorDropDown, undefined, undefined);
}

export default function* callFetchMasterColorDropDownSaga() {
  try {
    const response: MasterColorDropDown[] = yield call(callApi);
    yield put(clientActions.fetchMasterColorDropDownSuccess(response));
  } catch (error: unknown) {
    console.error('callFetchMasterColorDropDownSaga', error);
    yield put(clientActions.fetchMasterColorDropDownFailure());
  }
}
