import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import CustomDialog from '../../../shared/components/modal/CustomDialog';
import { projectActions } from '../store/slice/projectsSlice';
import { LotType, UpdateFloorAmountPayload } from '../types';
import { useAppSelector } from '../../../store/types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import TextInputField from '../../../shared/components/textField/TextInputField';
import SelectInputField from '../../../shared/components/selectField/SelectInputField';
import {
  updateFloorAmountIsLoadingSelector,
  updateFloorAmountIsSuccessSelector,
} from '../store/selectors/projectsSelectors';
import ErrorToast from '../../../shared/components/toast/ErrorToast';

interface CustomEditFloorInitialValue extends UpdateFloorAmountPayload {
  lotType: string;
  project: string;
}

interface EditFloorAmountProps {
  openModal: boolean;
  closeModal: () => void;
  selectedLotType: LotType;
  selectedProjectName: string;
  refreshLotTypes: () => void;
}

function EditFloorAmount(props: EditFloorAmountProps) {
  const { openModal, closeModal, selectedLotType, selectedProjectName, refreshLotTypes } = props;
  const dispatch = useDispatch();
  const updateFloorAmountIsLoading = useAppSelector(updateFloorAmountIsLoadingSelector);
  const updateFloorAmountIsSuccess = useAppSelector(updateFloorAmountIsSuccessSelector);

  useEffect(() => {
    if (updateFloorAmountIsSuccess) {
      refreshLotTypes();
      closeModal();
      dispatch(projectActions.resetUpdateFloorAmount());
    }
  }, [closeModal, dispatch, refreshLotTypes, updateFloorAmountIsSuccess]);

  if (!selectedLotType) return null;
  if (!openModal) return null;

  function handleSubmit(values: UpdateFloorAmountPayload) {
    const noOfFloors = Number(values?.amountOfFloors);
    if (noOfFloors === 0) {
      ErrorToast('Floor not selected', 'Please select floor');
    } else {
      dispatch(projectActions.updateFloorAmount(values));
    }
  }

  const initialValues: CustomEditFloorInitialValue = {
    id: selectedLotType.idLotType,
    amountOfFloors: selectedLotType.floors,
    lotType: selectedLotType.lotType1,
    project: selectedProjectName,
  };

  const validationSchema = Yup.object().shape({
    // portalStatus: Yup.string().required(),
    // noOfFloors: Yup.number().integer().min(1).required(),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ dirty, isValid, values, submitForm }) => {
        return (
          <Form>
            <CustomDialog
              open={openModal}
              handleClose={closeModal}
              title={'Bedrag bewerken van vloeren'}
              cancelButtonText={'annuleren'}
              handleCancelButtonPress={closeModal}
              primaryButtonText={'Opslaan'}
              primaryButtonDisabled={!isValid || !dirty || updateFloorAmountIsLoading}
              handlePrimaryButtonPress={submitForm}
            >
              <Grid container rowGap={2}>
                <Grid item xs={12}>
                  <TextInputField label={'Lot type'} name="lotType" disabled />
                </Grid>
                <Grid item xs={12}>
                  <TextInputField label={'Project'} name="project" disabled />
                </Grid>
                {/* <Grid item xs={12}>
                  <SelectInputField
                    label={'Amount of Floors'}
                    placeholder={'Selecteer het aantal verdiepingen'}
                    placeholderValue={0}
                    name="amountOfFloors"
                    options={[
                      { label: '1', value: 1 },
                      { label: '2', value: 2 },
                      { label: '3', value: 3 },
                      { label: '4', value: 4 },
                      { label: '5', value: 5 },
                    ]}
                  />
                </Grid> */}
              </Grid>
            </CustomDialog>
          </Form>
        );
      }}
    </Formik>
  );
}

export default EditFloorAmount;
