import { call, put } from 'redux-saga/effects';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { productActions } from '../slice/productsSlice';
import { ApiRequest } from '../../../../api/ApiService';
import { ProductGroup } from '../../types';

async function callApi() {
  return ApiRequest(Method.GET, ENDPOINTS.getProductGroup);
}

export default function* callFetchProductGroupsSaga() {
  try {
    const productGroups: ProductGroup[] = yield call(callApi);
    yield put(productActions.fetchProductGroupsSuccess(productGroups));
  } catch (error: unknown) {
    console.error('callFetchProductGroupsSaga', error);
    yield put(productActions.fetchProductGroupsFailure());
  }
}
