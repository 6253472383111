import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react';
import { styled } from '@mui/material/styles';
import DropDownIcon from '../../../../assets/images/chevrong.svg';
import { useAppDispatch } from '../../../store/types';
import { clientActions } from '../store/slice/clientSlice';

const StyledLabel = styled('p')<{ $labelColor: string; $disabled: boolean }>`
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  color: ${({ $labelColor, $disabled }) => ($disabled ? '#50606B' : $labelColor)};
  margin-bottom: 4px;
  line-height: 110%;
`;

const StyledInput = styled('select')<{ $disabled: boolean; $color: string }>`
  padding: 4px 8px;
  width: 100%;
  height: 34px;
  border: 1px solid #9a9fa5;
  border-radius: 5px;
  font-weight: 300;
  font-size: 16px;
  font-family: 'Public-sans';
  color: ${({ $color, $disabled }) => ($disabled ? '#50606b' : $color)};
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 8px;
  margin-right: 2rem;
  background-color: ${({ $disabled }) => ($disabled ? 'rgba(12, 27, 42, 0.06)' : 'white')};
  text-overflow: ellipsis;

  &:focus {
    outline: none;
    opacity: 1;
  }

  &::placeholder {
    color: #25334f;
    opacity: 0.6;
  }
`;

const ErrorText = styled('p')`
  color: #fc8181;
  font-size: 12px;
  font-weight: 300;
  line-height: 160%;
  letter-spacing: 0.24px;
  margin-top: 4px;
`;

const StyledSpan = styled('span')`
  color: #fc8181;
  font-size: 14px;
  margin-bottom: 8px;
  margin-left: 4px;
`;

const StyledLabelContainer = styled('div')<{ $isLoading: boolean }>`
  display: flex;
  flex-direction: row;
  opacity: ${(props) => (props.$isLoading ? '0.6' : '1')};
`;

const StyledHint = styled('p')`
  color: #50606b;
  font-size: 12px;
  font-weight: 300;
  line-height: 160%;
  letter-spacing: 0.24px;
  margin-top: 4px;
`;

interface SelectTemplateInputFieldProps {
  label?: string;
  labelColor?: string;
  name: string;
  required?: boolean;
  markAsRequired?: boolean;
  placeholder?: string | null;
  placeholderValue?: string | number;
  hint?: string;
  disabled?: boolean;
  isLoading?: boolean;
  options: { label: string; value: number | string }[];
  color?: string;
}

export default function SelectTemplateInputField(props: SelectTemplateInputFieldProps) {
  const {
    label,
    labelColor = '#0c1b2a',
    name,
    required = false,
    markAsRequired = false,
    placeholder = '',
    placeholderValue = undefined,
    hint = '',
    disabled = false,
    isLoading = false,
    options,
    color = '#50606b',
  } = props;

  const dispatch = useAppDispatch();

  const renderError = (message: string) => <ErrorText>{message}</ErrorText>;
  const { errors, setFieldValue } = useFormikContext();
  // @ts-ignore
  const hasError = errors[name];

  function onChangeTemplate(e) {
    setFieldValue(name, e.target.value);
    dispatch(clientActions.setSelectTemplateStatusChanged());
  }

  return (
    <>
      {label && (
        <StyledLabelContainer $isLoading={isLoading}>
          <StyledLabel $labelColor={labelColor} $disabled={disabled}>
            {label}
          </StyledLabel>
          {markAsRequired && <StyledSpan>*</StyledSpan>}
        </StyledLabelContainer>
      )}
      <Field
        as={StyledInput}
        required={required}
        onChange={onChangeTemplate}
        name={name}
        disabled={disabled}
        $disabled={disabled}
        $color={color}
        style={{ backgroundImage: `url(${DropDownIcon})`, color, opacity: isLoading ? 0.6 : 1 }}
      >
        {placeholder && <option value={placeholderValue}>{placeholder}</option>}
        {options.map((el) => (
          <option value={el.value} key={el.value}>
            {el.label}
          </option>
        ))}
      </Field>
      {hint && !hasError && <StyledHint>{hint}</StyledHint>}
      <ErrorMessage name={name} render={renderError} />
    </>
  );
}
