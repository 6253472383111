import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import CustomDialog from '@shared/components/modal/CustomDialog';
import SelectInputField from '../../../shared/components/selectField/SelectInputField';
import { PostTimeSlot } from '../types';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import { clientActions } from '../store/slice/clientSlice';
import {
  appointmentDetailsSelector,
  deleteTimeSlotIsSuccessSelector,
  uploadEditTimeSlotIsSuccessSelector,
  uploadedTimeSlotAlreadyExistSelector,
} from '../store/selectors/clientSelector';
import DeleteTimeSlotModal from './DeleteTimeSlotModal';

const UnderText = styled('p')`
  color: #45525e;
  font-family: 'Public-sans';
  font-size: 12px;
  font-weight: 300;
  margin-top: 3px;
`;

const ErrorText = styled('p')`
  color: #fc8181;
  font-family: 'Public-sans';
  font-size: 12px;
  font-weight: 700;
  margin-top: 3px;
`;

const timeOptions = [
  { label: '00 : 00', value: '00:00' },
  { label: '02 : 00', value: '02:00' },
  { label: '04 : 00', value: '04:00' },
  { label: '06 : 00', value: '06:00' },
  { label: '08 : 00', value: '08:00' },
  { label: '10 : 00', value: '10:00' },
  { label: '12 : 00', value: '12:00' },
  { label: '14 : 00', value: '14:00' },
  { label: '16 : 00', value: '16:00' },
  { label: '18 : 00', value: '18:00' },
  { label: '20 : 00', value: '20:00' },
  { label: '22 : 00', value: '22:00' },
];

interface EditTimeSlotProps {
  openModal: boolean;
  handleClose: () => void;
  clientId: number;
}

export default function EditTimeSlotModal(props: EditTimeSlotProps) {
  const { openModal, handleClose, clientId } = props;

  const dispatch = useAppDispatch();

  const [deleteModal, setDeleteModal] = useState(false);
  const [errorText, setErrorText] = useState<string | null>(null);

  const uploadEditTimeSlotIsSuccess = useAppSelector(uploadEditTimeSlotIsSuccessSelector);
  const uploadedTimeSlotAlreadyExist = useAppSelector(uploadedTimeSlotAlreadyExistSelector);
  const deleteTimeSlotIsSuccess = useAppSelector(deleteTimeSlotIsSuccessSelector);
  const AppointmentDetails = useAppSelector(appointmentDetailsSelector);

  useEffect(() => {
    if (uploadEditTimeSlotIsSuccess) {
      handleClose();
      dispatch(clientActions.resetUploadEditTimeSlot());
      dispatch(clientActions.fetchAppointmentDetails(clientId));
    }
  }, [uploadEditTimeSlotIsSuccess, dispatch, handleClose, clientId]);

  useEffect(() => {
    if (deleteTimeSlotIsSuccess) {
      handleClose();
    }
  }, [deleteTimeSlotIsSuccess, handleClose]);

  const dateOptions = AppointmentDetails?.selectedAppointment.map((date) => {
    const originalDate = new Date(date.bookedDate);
    const day = String(originalDate.getDate()).padStart(2, '0');
    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
    const year = originalDate.getFullYear();

    return `${day}-${month}-${year}`;
  });

  function generateInitialDate() {
    if (AppointmentDetails?.date) {
      const [dd, mm, yy] = AppointmentDetails.date.split('-');
      return `${mm}-${dd}-${yy}`;
    }
  }

  function onClosingModal() {
    handleClose();
    setErrorText(null);
    dispatch(clientActions.resetUploadEditTimeSlot());
  }

  if (!openModal) return null;

  function generateEndTime(start: string) {
    const startHour = Number(start.split(':')[0]);
    const endHour = startHour + 0o2;
    if (startHour < 8) {
      return `0${endHour}:00`;
    } else {
      return `${endHour}:00`;
    }
  }

  const initialValue = {
    date: generateInitialDate(),
    startTime: AppointmentDetails?.timeSlot?.split(' - ')[0] ?? '00:00',
    endTime: generateEndTime(AppointmentDetails?.timeSlot?.split(' - ')[0]) ?? '02:00',
  };

  function handleSubmit(values: object) {
    if (values && clientId) {
      const selectedDate = values.date;
      const [newDay, month, year] = selectedDate.split('-');
      const date = new Date(`${year}-${month}-${newDay}`);
      const currentDate = new Date();
      const hours = currentDate.getHours().toString().padStart(2, '0');
      const currentTime = `${hours}:00`;
      const day = date.toISOString().split('T')[0];
      const currentDay = currentDate.toISOString().split('T')[0];
      if (day < currentDay) {
        setErrorText('Kan geen datums uit het verleden selecteren!');
      } else {
        if (date.getDate() === currentDate.getDate() && values.startTime < currentTime) {
          setErrorText('Kan geen verleden tijd selecteren!');
        } else {
          const payload: PostTimeSlot = {
            id: clientId,
            bookedDate: date.toISOString(),
            bookedTimeSlots: `${values.startTime} - ${values.endTime}`,
          };
          dispatch(clientActions.uploadEditTimeSlot(payload));
        }
      }
    }
  }

  function onClickDelete() {
    setDeleteModal(true);
  }

  const validationSchema = Yup.object().shape({
    // portalStatus: Yup.string().required(),
    // noOfFloors: Yup.number().integer().min(1).required(),
  });

  return (
    <Formik initialValues={initialValue} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize>
      {({ dirty, isValid, submitForm, values }) => {
        values['endTime'] = generateEndTime(values['startTime']);
        return (
          <Form>
            <CustomDialog
              open={openModal}
              handleClose={onClosingModal}
              title="Tijdslot bewerken"
              primaryButtonText={'opslaan'}
              cancelButtonText={'annuleren'}
              primaryButtonDisabled={!isValid || !dirty}
              handlePrimaryButtonPress={submitForm}
              handleCancelButtonPress={onClosingModal}
              deleteButtonText={'verwijderen'}
              handleDeleteButtonPress={onClickDelete}
            >
              <DeleteTimeSlotModal
                openModal={deleteModal}
                closeModal={() => setDeleteModal(false)}
                id={AppointmentDetails?.idAppointment}
                clientId={clientId}
              />
              <SelectInputField
                label="Datum"
                name="date"
                options={dateOptions.map((date) => {
                  return {
                    label: date,
                    value: date,
                  };
                })}
              />
              <Grid container pt={3}>
                <Grid item xs={6} pr={4}>
                  <SelectInputField label="Starttijd" name="startTime" options={timeOptions} />
                </Grid>
                <Grid item xs={6} pr={4}>
                  <SelectInputField label="Eindtijd" name="endTime" disabled options={timeOptions} />
                </Grid>
              </Grid>
              {uploadedTimeSlotAlreadyExist && <UnderText>{uploadedTimeSlotAlreadyExist}</UnderText>}
              <ErrorText>{errorText}</ErrorText>
            </CustomDialog>
          </Form>
        );
      }}
    </Formik>
  );
}
