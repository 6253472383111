import React, { useState } from 'react';
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

import fileImg from '../../../../../assets/images/fileImage.svg';
import { useAppSelector } from '../../../../store/types';
import { fetchClientQuoteSelector } from '../../store/selectors/clientSelector';
import ShowBrochureModal from '../../../products/components/ShowBrochureModal';

const Label = styled('p')`
  font-size: 16px;
  font-weight: 700;
  color: #343e47;
`;

const FileName = styled('p')`
  color: #0c1b2a;
  font-size: 16px;
  font-weight: 600;
`;

const StyledFile = styled('div')`
  padding: 10px;
  cursor: pointer;
`;

// const UnderText = styled('p')`
//   color: #45525e;
//   font-size: 12px;
//   font-weight: 300;
// `;

function FilesCard() {
  const getClientQuote = useAppSelector(fetchClientQuoteSelector);

  const [showPdf, setShowPdf] = useState(false);

  return (
    <Card style={{ padding: '20px 12px', boxShadow: '0px 10px 24px 0px rgba(12, 27, 42, 0.06)', minHeight: '275px' }}>
      <Label>
        {getClientQuote ? 'Offerte' : 'Er is nog geen offerte gemaakt. Creëer een stijl en maak een offerte'}
      </Label>
      {getClientQuote && (
        <>
          <StyledFile onClick={() => setShowPdf(true)}>
            <img src={fileImg} alt="fileImage" />
            <FileName>{getClientQuote?.quoteName}</FileName>
          </StyledFile>
        </>
      )}
      <ShowBrochureModal open={showPdf} handleClose={() => setShowPdf(false)} brochure={getClientQuote?.quote ?? ''} />
    </Card>
  );
}

export default FilesCard;
