import { call, put } from 'redux-saga/effects';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { AppointmentResp } from '../../types';
import { clientActions } from '../slice/clientSlice';

async function callApi(id: number) {
  const pathParam = {
    id: id,
  };
  return ApiRequest(Method.GET, ENDPOINTS.appointmentDetails, pathParam, undefined);
}

export default function* callFetchAppointmentDetailsSaga({ payload }: PayloadAction<number>) {
  try {
    const AppointmentDetails: AppointmentResp = yield call(callApi, payload);
    yield put(clientActions.fetchAppointmentDetailsSuccess(AppointmentDetails));
  } catch (error: unknown) {
    console.error('callFetchAppointmentDetailsSaga', error);
    yield put(clientActions.fetchAppointmentDetailsFailure());
  }
}
