import React from 'react';
import { Button, Dialog, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { ReactComponent as CloseIcon } from '../../../../assets/images/close.svg';
import { noop } from '../../types';

interface CustomDialogProps {
  open: boolean;
  handleClose: () => void;
  width?: string;
  title?: string;
  subTitle?: string;
  useSmallTitle?: boolean;
  titleColor?: string;
  titleAlign?: 'flex-start' | 'center' | 'flex-end';
  showCloseButton?: boolean;
  footerAlign?: 'flex-start' | 'center' | 'flex-end';
  cancelButtonText?: string;
  cancelButtonIsLoading?: boolean;
  handleCancelButtonPress?: () => void;
  cancelButtonDisabled?: boolean;
  primaryButtonText?: string;
  primaryButtonIsLoading?: boolean;
  handlePrimaryButtonPress?: () => void;
  handleDeleteButtonPress?: () => void;
  primaryButtonDisabled?: boolean;
  primaryButtonStartIcon?: React.ReactNode;
  primaryButtonEndIcon?: React.ReactNode;
  children: React.ReactNode;
  deleteButtonText?: string;
}

export default function CustomDialog(props: CustomDialogProps) {
  const {
    open,
    handleClose,
    width = '422px',
    title,
    subTitle,
    useSmallTitle = false,
    titleColor = '#343E47',
    titleAlign = 'flex-start',
    showCloseButton = true,
    footerAlign = 'flex-end',
    cancelButtonText,
    cancelButtonIsLoading = false,
    handleCancelButtonPress,
    cancelButtonDisabled = false,
    primaryButtonText,
    primaryButtonIsLoading = false,
    handlePrimaryButtonPress,
    primaryButtonDisabled = false,
    primaryButtonStartIcon = null,
    primaryButtonEndIcon = null,
    deleteButtonText,
    handleDeleteButtonPress,
    children,
  } = props;
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth={'lg'}
      scroll={'body'}
      sx={{
        '& .MuiDialog-paper': { borderRadius: '12px', width, padding: '32px' },
      }}
    >
      <Box display={'flex'} flexDirection={'column'}>
        {(showCloseButton || title) && (
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mb={4}>
            <Box sx={{ width: '100%' }} display={'flex'} flexDirection={'column'} justifyContent={titleAlign}>
              {title && (
                <Typography
                  variant={'h2'}
                  sx={{
                    color: titleColor,
                    fontFamily: 'NeulisAlt',
                    fontWeight: 600,
                    fontStyle: 'italic',
                    fontSize: useSmallTitle ? 24 : 32,
                    lineHeight: 'normal',
                    letterSpacing: '1px',
                  }}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              )}
              {subTitle && (
                <Typography
                  variant={'h3'}
                  sx={{
                    color: titleColor,
                    fontFamily: 'NeulisAlt',
                    fontWeight: 600,
                    fontStyle: 'italic',
                    fontSize: useSmallTitle ? 18 : 24,
                    lineHeight: 'normal',
                    letterSpacing: '1px',
                    marginTop: '4px',
                  }}
                >
                  {subTitle}
                </Typography>
              )}
            </Box>
            {showCloseButton && (
              <IconButton onClick={handleClose} sx={{ padding: 0, marginLeft: 2 }}>
                <CloseIcon stroke={'#50606B'} />
              </IconButton>
            )}
          </Box>
        )}
        {children}
        {(cancelButtonText || primaryButtonText) && (
          <Box mt={4} display={'flex'} justifyContent={footerAlign} gap={1}>
            {cancelButtonText && (
              <Button
                disableElevation
                variant="text"
                onClick={handleCancelButtonPress ?? noop}
                disabled={cancelButtonDisabled || cancelButtonIsLoading}
                sx={{
                  '& .MuiButton-root': {
                    color: '#45525E',
                    fontSize: '14px',
                  },
                  height: '36px',
                  padding: '2px 12px 0',
                  fontWeight: 700,
                  textTransform: 'uppercase',
                }}
              >
                {cancelButtonIsLoading ? 'Loading...' : cancelButtonText}
              </Button>
            )}
            {deleteButtonText && (
              <Button
                disableElevation
                variant="text"
                onClick={handleDeleteButtonPress ?? noop}
                sx={{
                  '& .MuiButton-root': {
                    fontSize: '14px',
                  },
                  height: '36px',
                  padding: '2px 12px 0',
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  border: '1px solid #BD8890',
                  color: '#BD8890',
                }}
              >
                {deleteButtonText}
              </Button>
            )}
            {primaryButtonText && (
              <Button
                disableElevation
                variant="contained"
                onClick={handlePrimaryButtonPress ?? noop}
                disabled={primaryButtonDisabled || primaryButtonIsLoading}
                startIcon={primaryButtonStartIcon}
                endIcon={primaryButtonEndIcon}
                sx={{
                  '& .MuiButton-root': {
                    color: '#FFFFFE',
                    backgroundColor: '#BD8890',
                    fontSize: '14px',
                  },
                  height: '36px',
                  padding: '2px 12px 0',
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  '&:disabled': {
                    color: '#FFFFFE',
                    backgroundColor: '#BD8890',
                    opacity: 0.6,
                  },
                  '&:hover': {
                    backgroundColor: '#BD8890',
                  },
                }}
              >
                {primaryButtonIsLoading ? 'Loading...' : primaryButtonText}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
