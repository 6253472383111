import React, { useEffect, useState } from 'react';

import PageLayout from '../../../layout/PageLayout';

import CommonHeader from '../../../shared/components/commonHeader/CommonHeader';
import TableSearchBar from '../../../shared/components/tableSearchBar/TableSearchBar';
import { TableColumnSortDirection } from '../../../shared/types';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import { Paper } from '@mui/material';
import CustomTable from '../../../shared/components/customTable/CustomTable';
import projectTableHeaders from '../components/projectTableHeaders';
import {
  fetchProjectsIsLoadingSelector,
  projectsSelector,
  projectsTotalPagesSelector,
  projectSyncWithExactHasErrorSelector,
  projectSyncWithExactIsLoadingSelector,
  projectSyncWithExactSuccessSelector,
} from '../store/selectors/projectsSelectors';
import { Project } from '../types';
import { projectActions } from '../store/slice/projectsSlice';
import projectTableData from '../components/projectTableData';
import { ROUTE_PROJECTS } from '../../../utils/routes';
import { useNavigate } from 'react-router-dom';
import TileSlotModal from '../components/TimeSlotModal';

function Projects() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const projects = useAppSelector(projectsSelector);
  const totalNumberOfPages = useAppSelector(projectsTotalPagesSelector);
  const fetchProjectsIsLoading = useAppSelector(fetchProjectsIsLoadingSelector);
  const projectSyncWithExactSuccess = useAppSelector(projectSyncWithExactSuccessSelector);
  const projectSyncWithExactHasError = useAppSelector(projectSyncWithExactHasErrorSelector);
  const projectSyncWithExactIsLoading = useAppSelector(projectSyncWithExactIsLoadingSelector);

  const [SelectedProject, setSelectedProject] = React.useState<Project | null>();

  const [showFilters, setShowFilters] = useState(false);
  const [sortBy, setSortBy] = useState<string | undefined>('project');
  const [sortDirection, setSortDirection] = useState<TableColumnSortDirection>(TableColumnSortDirection.ASC);
  const [openTimeSlot, setOpenTimeSlot] = useState(false);

  const [searchKeyFilter, setSearchKeyFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('');
  const [bookedDateFilter, setBookedDateFilter] = useState('');
  const [lotFilter, setLotFilter] = useState<number>();
  const [currentPage, setCurrentPage] = useState(1);

  function resetAllFilters() {
    setSearchKeyFilter('');
    setNameFilter('');
    setLotFilter(undefined);
    setCurrentPage(1);
  }

  function fetchProjects() {
    dispatch(
      projectActions.fetchProjects({
        searchKey: searchKeyFilter,
        project: nameFilter,
        lots: lotFilter,
        bookedDate: bookedDateFilter,
        KeyProperty: sortBy,
        IsAscending: sortDirection === 'asc' ? true : false,
        pageNumber: searchKeyFilter ? 1 : nameFilter === '' && !lotFilter && bookedDateFilter === '' ? currentPage : 1,
      })
    );
  }

  useEffect(() => {
    if (projectSyncWithExactIsLoading) {
      const timer = setTimeout(() => {
        dispatch(projectActions.resetProjectSyncWithExact());
        fetchProjects();
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [projectSyncWithExactIsLoading, dispatch]);

  useEffect(() => {
    if (projectSyncWithExactSuccess || projectSyncWithExactHasError) {
      dispatch(projectActions.resetProjectSyncWithExact());
      fetchProjects();
    }
  }, [projectSyncWithExactSuccess, dispatch, projectSyncWithExactHasError]);

  useEffect(() => {
    // dispatch(projectActions.projectSyncWithExact());
    fetchProjects();
  }, [searchKeyFilter, nameFilter, lotFilter, currentPage, dispatch, bookedDateFilter, sortBy, sortDirection]);

  function toggleFilters() {
    setShowFilters((prevVal) => !prevVal);
  }

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
  }

  const tableHeaders = projectTableHeaders(
    nameFilter,
    setNameFilter,
    lotFilter,
    setLotFilter,
    bookedDateFilter,
    setBookedDateFilter
  );

  function handleClickOnRow(selectedProjectId: number) {
    navigate(`${ROUTE_PROJECTS}/${selectedProjectId}/lotTypes`);
  }

  function handleTimeSlotClick(project: Project) {
    setSelectedProject(project);
    setOpenTimeSlot(true);
  }

  const tableData = projectTableData(
    projects,
    tableHeaders.map((i) => i.width),
    handleClickOnRow,
    handleTimeSlotClick,
    showFilters
  );

  return (
    <PageLayout>
      <CommonHeader header={'Projecten'} subHeader={'Overzicht van projecten, bouwtypes en bouwnummers'} />
      <Paper className="common-paper">
        <TableSearchBar
          placeHolder={'Zoek projecten...'}
          onSearch={setSearchKeyFilter}
          showFilterToggle
          toggleFilterHandler={toggleFilters}
          showFilter={showFilters}
        />
        <CustomTable
          tableHeaders={tableHeaders}
          showFilters={showFilters}
          tableData={tableData}
          totalNumberOfPages={totalNumberOfPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          isLoading={fetchProjectsIsLoading || projectSyncWithExactIsLoading}
        />
      </Paper>
      <TileSlotModal
        openModal={openTimeSlot}
        closeModal={() => setOpenTimeSlot(false)}
        selectedProject={SelectedProject}
        page={currentPage}
        fetchProjects={fetchProjects}
      />
    </PageLayout>
  );
}

export default Projects;
