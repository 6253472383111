import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import MainLayout from '@layout/MainLayout';
import { getWithExpiry } from '@shared/services/LocalStorage';

const PrivateRoutes: React.FC = () => {
  const token = getWithExpiry('console_admin_token');
  const isSignedIn = !!token;

  if (isSignedIn) {
    return (
      <>
        <MainLayout />
        <Outlet />
      </>
    );
  }

  return <Navigate to="/login" replace />;
};

export default PrivateRoutes;
