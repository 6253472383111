import { call, put } from 'redux-saga/effects';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { DeleteWindowParam } from '../../types';

async function callApi(payload: DeleteWindowParam) {
  const queryParam = payload;
  return ApiRequest(Method.DELETE, ENDPOINTS.deleteTemplateWindow, undefined, queryParam);
}

export default function* callDeleteWindowSaga({ payload }: PayloadAction<DeleteWindowParam>) {
  try {
    yield call(callApi, payload);
    yield put(clientActions.deleteTemplateWindowSuccess());
    SuccessToast('Succes!', 'Raam is verwijderd');
  } catch (error: unknown) {
    console.error('callDeleteWindowSaga', error);
    yield put(clientActions.deleteTemplateWindowFailure());
    ErrorToast(undefined, error.toString());
  }
}
