export default function findDuplicateObjects(arrayOfObjects, propertyName) {
  const seen = new Set();
  const duplicates = [];

  arrayOfObjects.forEach((obj) => {
    const propertyValue = obj[propertyName];

    if (seen.has(propertyValue)) {
      duplicates.push(obj);
    } else {
      seen.add(propertyValue);
    }
  });

  return duplicates;
}
