import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/types';
import CustomDialog from '../../../shared/components/modal/CustomDialog';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Window } from '../types';
import { clientActions } from '../store/slice/clientSlice';
import { deleteTemplateWindowIsSuccessSelector } from '../store/selectors/clientSelector';

const StyledTitle = styled('p')`
  color: #222;
  font-size: 16px;
  font-weight: 300;
  line-height: 110%;
  letter-spacing: 0.64px;
  text-align: center;
`;

const StyledUserName = styled('p')`
  color: #0c1b2a;
  font-size: 16px;
  font-weight: 700;
  line-height: 110%;
`;

interface DeleteWindowModalProps {
  openModal: boolean;
  closeModal: () => void;
  selectedWindow: Window | null;
  idLots: number | undefined;
  idLotType: number | undefined;
  floor: number;
  clearWindow: (id: number | undefined) => void;
}

export default function DeleteWindowModal(props: DeleteWindowModalProps) {
  const { openModal, closeModal, selectedWindow, idLots, idLotType, floor, clearWindow } = props;
  const dispatch = useDispatch();

  const deleteTemplateWindowIsSuccess = useAppSelector(deleteTemplateWindowIsSuccessSelector);

  useEffect(() => {
    if (deleteTemplateWindowIsSuccess) {
      clearWindow(selectedWindow?.idWindows);
      dispatch(clientActions.resetDeleteTemplateWindow());
      closeModal();
    }
  }, [deleteTemplateWindowIsSuccess, closeModal, clearWindow, dispatch, selectedWindow]);

  if (!openModal) return null;

  function handleSubmit() {
    const params = {
      IdLots: idLots,
      IdLotType: idLotType,
      IdWindows: selectedWindow?.idWindows,
      floor: floor,
    };
    dispatch(clientActions.deleteTemplateWindow(params));
  }

  return (
    <CustomDialog
      open={openModal}
      handleClose={closeModal}
      cancelButtonText={'Cancel'}
      handleCancelButtonPress={closeModal}
      primaryButtonText={'Delete'}
      primaryButtonDisabled={false}
      handlePrimaryButtonPress={handleSubmit}
      footerAlign={'center'}
      showCloseButton={false}
    >
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <StyledTitle>Weet je zeker dat je het volgende wilt verwijderen</StyledTitle>
        <StyledUserName>Raam</StyledUserName>
      </Box>
    </CustomDialog>
  );
}
