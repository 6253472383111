import { FilterType, TableHeaders } from '../../../shared/types';

export default function templateTableHeaders(
  nameFilter: string,
  setNameFilter: (a: string) => void,
  materialFilter: number | null,
  setMaterialFilter: (a: number | null) => void,
  statusFilter: string,
  setStatusFilter: (a: string) => void
): TableHeaders[] {
  return [
    {
      label: 'Naam',
      isSortable: true,
      filterPlaceHolder: 'Filter template naam...',
      filterType: FilterType.search,
      filterValue: nameFilter,
      onFilterChange: setNameFilter,
      columnName: 'Name',
    },
    {
      label: 'Producten',
      isSortable: true,
      filterPlaceHolder: 'Filter producten...',
      filterType: FilterType.search,
      filterValue: materialFilter,
      onFilterChange: setMaterialFilter,
      columnName: 'material',
      width: '20%',
    },
    {
      label: 'Status',
      isSortable: true,
      filterPlaceHolder: 'Filter status...',
      filterType: FilterType.select,
      filterValue: statusFilter,
      onFilterChange: setStatusFilter,
      filterOptions: [
        { value: 'Voltooid', label: 'Voltooid' },
        { value: 'Gehandicapt', label: 'Gehandicapt' },
        { value: 'Bevat onvolledige producten', label: 'Bevat onvolledige producten' },
      ],
      columnName: 'status',
      width: '25%',
    },
    {
      label: '',
      columnName: 'edit',
      align: 'center',
      width: '34px',
    },
    {
      label: '',
      columnName: 'create',
      align: 'center',
      width: '34px',
    },
  ];
}
