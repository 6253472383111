import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { GetTokenRequest, GetTokenResponse, GetUserInfoResponse } from '@app-types/Responses.types';
import { AuthenticationState } from '@authentication/store/authentication.types';

const initialState: AuthenticationState = {
  getTokenIsLoading: false,
  tokenData: null,
  userInfo: null,
  getTokenHasError: false,
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    getToken(state, action: PayloadAction<GetTokenRequest>) {
      state.getTokenIsLoading = true;
    },
    getTokenSuccess(state, action: PayloadAction<{ tokenData: GetTokenResponse; userInfo: GetUserInfoResponse }>) {
      state.getTokenIsLoading = false;
      state.tokenData = action.payload.tokenData;
      state.userInfo = action.payload.userInfo;
    },
    getTokenFailure(state) {
      state.getTokenIsLoading = false;
      state.getTokenHasError = true;
    },
  },
});

// Actions
export const authenticationActions = authenticationSlice.actions;

// Reducer
const authenticationReducer = authenticationSlice.reducer;
export default authenticationReducer;
