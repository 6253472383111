import { call, put } from 'redux-saga/effects';
import { PostTimeSlotResp, UploadFloorImageRequest } from '../../types';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { Method } from '../../../../api/types';

async function callApi(props: UploadFloorImageRequest) {
  const queryParam = {
    LotTypeId: props.LotTypeId,
    LotsId: props.LotsId,
  };
  const payload = props.data;
  return ApiRequest(Method.POST, ENDPOINTS.uploadFloorImage, undefined, queryParam, payload);
}

export default function* callUploadFloorImageSaga({ payload }: PayloadAction<UploadFloorImageRequest>) {
  try {
    const postResponse = yield call(callApi, payload);
    yield put(clientActions.uploadFloorImageSuccess());
    if (postResponse?.isSucess) {
      SuccessToast('Succes!', 'Er is een e-mail verzonden naar de klant');
    }
  } catch (error: unknown) {
    yield put(clientActions.uploadFloorImageFailure());
    ErrorToast(undefined, error.toString());
  }
}
