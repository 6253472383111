import React from 'react';
import Dialog from '@mui/material/Dialog';
import PdfViewer from '../../../shared/components/pdfViewer/PdfViewer';
import Close from '../../../../assets/images/closeWithOutline.svg';

interface ShowBrochureModalProps {
  open: boolean;
  handleClose: () => void;
  brochure?: string;
}

export default function ShowBrochureModal(props: ShowBrochureModalProps) {
  const { open, handleClose, brochure } = props;
  if (!open && brochure) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={'lg'}
      scroll={'body'}
      sx={{
        '& .MuiDialog-paper': { width: '70%', padding: '15px' },
      }}
    >
      <div>
        <div
          style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', cursor: 'pointer' }}
          onClick={handleClose}
        >
          <img src={Close} alt="close" />
        </div>
        <PdfViewer pdfData={brochure} />
      </div>
    </Dialog>
  );
}
