import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Loader from '../../../shared/components/loadingSpinner/Loader';
import { styled } from '@mui/material/styles';
import { ImageData } from '../types';
import { ImageBaseUrl } from '../../../utils/constants';

const ModalContainer = styled('div')<{ hasError?: boolean }>`
  min-height: ${(props) => (props.hasError ? '400px' : 'unset')};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const LoaderContainer = styled('div')`
  min-height: 400px;
`;

const StyledImage = styled('img')<{ hasError?: boolean }>`
  width: ${(props) => (props.hasError ? 'unset' : '100%')};
  height: ${(props) => (props.hasError ? 'unset' : '100%')};
  object-fit: contain;
`;

interface ShowImageModalProps {
  open: boolean;
  handleClose: () => void;
  image?: ImageData | null;
  blobId?: string | null;
  isBlobId?: boolean;
}

export default function ShowImageModal(props: ShowImageModalProps) {
  const { open, handleClose, image, blobId, isBlobId } = props;
  const imageFormat = (image?.fileName || '').split('.').pop();
  const [hasError, setError] = useState(false);

  function handleError() {
    setError(true);
  }

  return (
    <Dialog open={open} onClose={handleClose} sx={{ padding: '15px' }} fullWidth>
      <ModalContainer hasError={hasError}>
        <StyledImage
          src={isBlobId ? `${ImageBaseUrl + blobId}` : `data:image/${imageFormat};base64,${image?.fileData}`}
          alt="Image error"
          hasError={false}
          onError={handleError}
        />
      </ModalContainer>
    </Dialog>
  );
}
