import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import CommonHeader from '../../../shared/components/commonHeader/CommonHeader';
import ContactDetailsCard from '../components/clientCards/ContactDetailsCard';
import ProjectDetailsCard from '../components/clientCards/ProjectDetailsCard';
import FilesCard from '../components/clientCards/FilesCard';
import ChosenStyleCard from '../components/clientCards/ChosenStyleCard';
import AppointmentCard from '../components/clientCards/AppointmentCard';
import { ROUTE_CLIENTS } from '../../../utils/routes';
import GoBackButton from '../../../shared/components/goBackButton/GoBackButton';
import {
  clientListSelector,
  createQuoteIsSuccessSelector,
  deleteTimeSlotIsSuccessSelector,
  selectedLotTypeIdSelector,
  selectedProjectIdSelector,
} from '../store/selectors/clientSelector';

import { useAppSelector } from '../../../store/types';
import { clientActions } from '../store/slice/clientSlice';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { ROUTE_PROJECTS } from '../../../utils/routes';

const StyledLabel = styled('label')`
  font-size: 24px;
  font-weight: 400;
  font-style: italic;
  font-family: NeulisAlt;
  color: #788491;
`;

const StyledWrapper = styled('div')`
  padding: 16px 60px;
  height: 100%;
  background-color: #fafafa;
`;

function ClientDetailsPage() {
  const { clientId: selectedClientId, lotsId: selectedLotsId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientData = useAppSelector(clientListSelector);
  const createQuoteIsSuccess = useAppSelector(createQuoteIsSuccessSelector);
  const deleteTimeSlotIsSuccess = useAppSelector(deleteTimeSlotIsSuccessSelector);
  const selectedLotTypeId = useAppSelector(selectedLotTypeIdSelector);
  const selectedProjectId = useAppSelector(selectedProjectIdSelector);
  const selectedClient = clientData?.find(
    (i) => `${i.idclient}` === selectedClientId && `${i.idLots}` === selectedLotsId
  );

  useEffect(() => {
    dispatch(clientActions.fetchAllClients({ pageSize: 1000 }));
  }, [dispatch]);

  useEffect(() => {
    if (createQuoteIsSuccess) {
      dispatch(clientActions.fetchAllClients({ pageSize: 1000 }));
      dispatch(clientActions.resetCreateQuoteInChangeStyle());
    }
  }, [createQuoteIsSuccess, dispatch]);

  useEffect(() => {
    if (deleteTimeSlotIsSuccess) {
      dispatch(clientActions.fetchAllClients({ pageSize: 1000 }));
    }
  }, [deleteTimeSlotIsSuccess, dispatch]);

  function handleGoBack() {
    if (selectedLotTypeId && selectedProjectId) {
      navigate(`${ROUTE_PROJECTS}/${selectedProjectId}/lotTypes/${selectedLotTypeId}/clients`);
    } else {
      navigate(`${ROUTE_CLIENTS}`);
    }
  }

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <CommonHeader header={`Klant '${selectedClient?.client}'`} subHeader={''}>
        <GoBackButton onClick={handleGoBack} />
      </CommonHeader>
      <StyledWrapper>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={4}>
            <StyledLabel>Contact details</StyledLabel>
            <ContactDetailsCard selectedClient={selectedClient} />
          </Grid>
          <Grid item xs={3}>
            <StyledLabel>Project details</StyledLabel>
            <ProjectDetailsCard
              name={selectedClient?.lots}
              floors={selectedClient?.floors}
              status={selectedClient?.status}
              code={selectedClient?.colorCode}
            />
          </Grid>
          <Grid item xs={5}>
            <StyledLabel>Offerte</StyledLabel>
            <FilesCard />
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={2} sx={{ marginTop: '0px' }}>
          <Grid item xs={6.5}>
            <StyledLabel>Gekozen producten</StyledLabel>
            <ChosenStyleCard
              selectedClientId={selectedClient?.idclient}
              selectedClientEmail={selectedClient?.email}
              selectedClientProjectId={selectedClient?.idProject}
              clientName={selectedClient?.client}
              floors={selectedClient?.floors}
              idTemplete={selectedClient?.idTemplete}
              idDefaultTemplete={selectedClient?.idDefaultTemplete}
              idLots={selectedClient?.idLots}
            />
          </Grid>
          <Grid item xs={5.5}>
            <StyledLabel>Afspraak</StyledLabel>
            <AppointmentCard idClient={selectedClient?.idclient} />
          </Grid>
        </Grid>
      </StyledWrapper>
    </div>
  );
}

export default ClientDetailsPage;
