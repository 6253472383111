import React from 'react';
import { TableColumnSortDirection } from '@shared/types';

interface TableColumnSortIconProps {
  sortActive: boolean;
  sortDirection?: TableColumnSortDirection;
}

function TableColumnSortIcon({ sortActive = false, sortDirection }: TableColumnSortIconProps) {
  const topFill = sortActive && sortDirection === TableColumnSortDirection.DESC ? '#BD8890' : '#E0E3EB';
  const bottomFill = sortActive && sortDirection === TableColumnSortDirection.ASC ? '#BD8890' : '#E0E3EB';

  return (
    <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 0L9.33013 7.5H0.669873L5 0Z" fill={topFill} />
      <path d="M5 17L0.669872 9.5H9.33013L5 17Z" fill={bottomFill} />
    </svg>
  );
}

export default TableColumnSortIcon;
