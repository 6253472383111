import { takeLatest } from 'redux-saga/effects';
import { productActions } from '../slice/productsSlice';
import callFetchTemplatesSaga from './callFetchTemplatesSaga';
import callDeleteTemplateSaga from './callDeleteTemplateSaga';
import callCreateTemplateSaga from './callCreateTemplateSaga';
import callEditTemplateSaga from './callEditTemplateSaga';
import callFetchExistingMaterialsSaga from './callFetchExistingMaterialsSaga';
import callFetchProductsSaga from './callFetchProductsSaga';
import callSyncProductsWithExactSaga from './callSyncProductsWithExactSaga';
import callSyncSingleProductWithExactSaga from './callSyncSingleProductWithExactSaga';
import callFetchProductTypesSaga from './callFetchProductTypesSaga';
import callFetchProductGroupsSaga from './callFetchProductGroupsSaga';
import callFetchProductMaterialColorsSaga from './callFetchProductMaterialColorsSaga';
import callDownloadProductColorImageSaga from './callDownloadProductColorImageSaga';
import callDownloadProductGroupImageSaga from './callDownloadProductGroupImageSaga';
import callUploadProductColorImageSaga from './callUploadProductColorImageSaga';
import callUploadProductGroupImageSaga from './callUploadProductGroupImageSaga';
import callUploadProductBrochureSaga from './callUploadProductBrochureSaga';
import callDeleteGroupImageSaga from './callDeleteGroupImageSaga';
import callDeleteGroupBrochureSaga from './callDeleteGroupBrochureSaga';
import callFetchProductBrochure from './callFetchProductBrochureSaga';
import callDeleteProductColorImageSaga from './callDeleteProductColorImageSaga';

export default function* projectsSaga() {
  yield takeLatest(productActions.fetchProducts, callFetchProductsSaga);
  yield takeLatest(productActions.syncProductsWithExact, callSyncProductsWithExactSaga);
  yield takeLatest(productActions.syncSingleProductWithExact, callSyncSingleProductWithExactSaga);
  yield takeLatest(productActions.fetchProductTypes, callFetchProductTypesSaga);
  yield takeLatest(productActions.fetchProductGroups, callFetchProductGroupsSaga);

  yield takeLatest(productActions.fetchTemplates, callFetchTemplatesSaga);
  yield takeLatest(productActions.fetchExistingMaterials, callFetchExistingMaterialsSaga);
  yield takeLatest(productActions.createTemplate, callCreateTemplateSaga);
  yield takeLatest(productActions.editTemplate, callEditTemplateSaga);
  yield takeLatest(productActions.deleteTemplate, callDeleteTemplateSaga);

  yield takeLatest(productActions.fetchProductTypes, callFetchProductTypesSaga);
  yield takeLatest(productActions.fetchProductGroups, callFetchProductGroupsSaga);
  yield takeLatest(productActions.fetchProductMaterialColors, callFetchProductMaterialColorsSaga);
  yield takeLatest(productActions.uploadProductGroupImage, callUploadProductGroupImageSaga);
  yield takeLatest(productActions.uploadProductBrochure, callUploadProductBrochureSaga);
  yield takeLatest(productActions.downloadProductGroupImage, callDownloadProductGroupImageSaga);
  yield takeLatest(productActions.uploadProductColorImage, callUploadProductColorImageSaga);
  yield takeLatest(productActions.downloadProductColorImage, callDownloadProductColorImageSaga);
  yield takeLatest(productActions.deleteGroupImage, callDeleteGroupImageSaga);
  yield takeLatest(productActions.deleteGroupBrochure, callDeleteGroupBrochureSaga);
  yield takeLatest(productActions.downloadProductBrochure, callFetchProductBrochure);
  yield takeLatest(productActions.deleteProductColorImage, callDeleteProductColorImageSaga);
}
