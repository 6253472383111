import CustomDialog from '../../../shared/components/modal/CustomDialog';
import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useDispatch } from 'react-redux';
import { projectActions } from '../store/slice/projectsSlice';
import { useAppSelector } from '../../../store/types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import {
  fetchMaterialPriceIsLoadingSelector,
  materialPriceSelector,
  updateMaterialPriceIsLoadingSelector,
  updateMaterialPriceIsSuccessSelector,
} from '../store/selectors/projectsSelectors';
import getReadableFloorName from '../../../utils/getReadableFloorName';
import CustomTable, { CustomTableDataCell } from '../../../shared/components/customTable/CustomTable';
import { FilterType, noop, TableBodyRow, TableColumnSortDirection, TableHeaders } from '../../../shared/types';
import orderBy from 'lodash/orderBy';
import { MaterialPrice, UpdateMaterialPriceData } from '../types';
import { ReactComponent as WarningHexagonIcon } from '../../../../assets/images/warningHexagon.svg';
import TableStatusLabel, { TableStatusType } from '../../../shared/components/tableTextOptions/TableStatusLabel';
import PriceTextField from '../../../shared/components/textField/PriceTextField';
import flattenNestedObject from '../utils/flattenNestedObject';
import ErrorToast from '../../../shared/components/toast/ErrorToast';
import LightTooltip from '../../../utils/lightTooltip';

interface MaterialOverviewModalProps {
  openModal: boolean;
  closeModal: () => void;
  idLot?: number; // for clients
  idLotType?: number; // for projects
  floors: number;
  refreshClients: () => void;
  title: string;
}

function materialPriceTableHeaders(
  materialFilter: string,
  setMaterialFilter: (value: string) => void,
  priceFilter: string,
  setPriceFilter: (value: string) => void
): TableHeaders[] {
  return [
    {
      label: 'Product',
      isSortable: true,
      filterPlaceHolder: 'Filter product...',
      filterType: FilterType.search,
      filterValue: materialFilter,
      onFilterChange: setMaterialFilter,
      columnName: 'searchkey',
    },
    {
      label: 'Prijs (€)',
      isSortable: true,
      filterPlaceHolder: 'selecteer Prijs...',
      filterType: FilterType.search,
      filterValue: priceFilter,
      onFilterChange: setPriceFilter,
      columnName: 'Price',
      align: 'center',
      width: '200px',
      type: 'number',
    },
    {
      label: '',
      columnName: 'edit',
      align: 'center',
      width: '34px',
    },
  ];
}

function materialPriceTableData(
  materialPriceData: MaterialPrice[],
  tableHeaderWidth: (string | undefined)[],
  values: object,
  selectedFloor: number,
  setFieldValue: (s: string, value) => void
): TableBodyRow[] {
  return materialPriceData.map((price: MaterialPrice) => {
    const effectivePrice = values[selectedFloor][price.idPrice] ?? price.totalPrice;
    return {
      id: `${price.idPrice}`,
      tableCell: (
        <>
          <CustomTableDataCell width={tableHeaderWidth[0] ?? 'unset'} fontWeight={700}>
            {price.material}
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[1] ?? 'unset'} fontWeight={700}>
            {effectivePrice === null ? (
              <TableStatusLabel
                label={'Incomplete'}
                type={TableStatusType.Error}
                handleClick={() => setFieldValue(`${selectedFloor}.${price.idPrice}`, '')}
              />
            ) : (
              <PriceTextField
                value={`${effectivePrice}`}
                onChange={(val) => setFieldValue(`${selectedFloor}.${price.idPrice}`, val)}
              />
            )}
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[4] ?? 'unset'}>
            {price?.isDefaultPrice && (
              <LightTooltip title="Prijzen zijn automatisch geselecteerd">
                <IconButton
                  onClick={noop}
                  sx={{
                    padding: '5px',
                    '&:disabled': {
                      opacity: '0 !important',
                    },
                  }}
                >
                  <WarningHexagonIcon width={20} height={20} />
                </IconButton>
              </LightTooltip>
            )}
          </CustomTableDataCell>
        </>
      ),
    };
  });
}

export default function MaterialOverviewModal(props: MaterialOverviewModalProps) {
  const { openModal, closeModal, idLot, idLotType, floors, refreshClients, title } = props;
  const dispatch = useDispatch();
  const materialPrice = useAppSelector(materialPriceSelector);
  const fetchMaterialPriceIsLoading = useAppSelector(fetchMaterialPriceIsLoadingSelector);
  const updateMaterialPriceIsLoading = useAppSelector(updateMaterialPriceIsLoadingSelector);
  const updateMaterialPriceIsSuccess = useAppSelector(updateMaterialPriceIsSuccessSelector);
  const [selectedFloor, setSelectedFloor] = useState(0);

  const [sortBy, setSortBy] = useState<string | undefined>('searchkey');
  const [sortDirection, setSortDirection] = useState<TableColumnSortDirection>(TableColumnSortDirection.ASC);

  const [materialFilter, setMaterialFilter] = useState<string>('');
  const [priceFilter, setPriceFilter] = useState<string>('');

  function resetAllFilters() {
    setMaterialFilter('');
    setPriceFilter('');
  }

  function fetchMaterialPrice() {
    dispatch(
      projectActions.fetchMaterialPrice({
        searchKey: materialFilter,
        price: isNaN(parseInt(priceFilter)) ? undefined : parseInt(priceFilter),
        lotTypeId: idLotType,
        lotsId: idLot,
        floor: selectedFloor + 1,
        KeyProperty: sortBy,
        IsAscending: sortDirection === 'asc' ? true : false,
      })
    );
  }

  useEffect(() => {
    if (updateMaterialPriceIsSuccess) {
      handleCloseModal();
      dispatch(projectActions.resetUpdateMaterialPrice());
    }
  }, [dispatch, updateMaterialPriceIsSuccess]);

  useEffect(() => {
    if (openModal) {
      fetchMaterialPrice();
    }
  }, [dispatch, openModal, selectedFloor, materialFilter, priceFilter, sortBy, sortDirection]);

  if (!(idLot || idLotType)) return null;
  if (!openModal) return null;

  function handleChangeSelectedFloor(event: React.SyntheticEvent, newValue: number) {
    setSelectedFloor(newValue);
  }

  function handleCloseModal() {
    setSelectedFloor(0);
    setMaterialFilter('');
    setPriceFilter('');
    return closeModal();
  }

  function handleSubmit(values: object) {
    const result = flattenNestedObject(values);
    const invalidValue = [];
    for (const [key, value] of Object.entries(result)) {
      if (value.includes('.')) {
        invalidValue.push(value);
        ErrorToast('Invalid price', 'Price should not be decimal');
        return;
      }
    }
    if (invalidValue.length === 0) {
      const numberOfElements = Object.keys(result).length;
      const entries = Object.entries(result);
      for (let index = 0; index < entries.length; index++) {
        const [key, value] = entries[index];
        const payload: UpdateMaterialPriceData = {
          lastPrice: index === numberOfElements - 1,
          uploadData: {
            idPrice: parseInt(key),
            seelingPrice: parseInt(value as unknown as string),
            isDefaultPrice: false,
          },
        };
        dispatch(projectActions.updateMaterialPrice(payload));
      }
    }
  }

  // @ts-ignore
  // const modifiedMaterialPriceData = orderBy(
  //   materialPrice,
  //   [(mp) => `${mp[sortBy ?? 'material']}`.toLowerCase()],
  //   sortDirection
  // );

  const initialValues = [...Array(floors).keys()].reduce((ac, i) => ({ ...ac, [i]: {} }), {});

  const validationSchema = Yup.object().shape({});

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ dirty, isValid, values, submitForm, setFieldValue }) => {
        const tableHeaders = materialPriceTableHeaders(materialFilter, setMaterialFilter, priceFilter, setPriceFilter);
        const tableData = materialPriceTableData(
          materialPrice,
          tableHeaders.map((i) => i.width),
          values,
          selectedFloor,
          setFieldValue
        );
        return (
          <Form>
            <CustomDialog
              width={'832px'}
              open={openModal}
              handleClose={handleCloseModal}
              title={`Beschikbare producten voor ${title}`}
              cancelButtonText={'Annuleren'}
              handleCancelButtonPress={handleCloseModal}
              primaryButtonText={'Opslaan'}
              primaryButtonDisabled={!isValid || !dirty || updateMaterialPriceIsLoading}
              handlePrimaryButtonPress={submitForm}
            >
              <Box
                mb={2}
                sx={{
                  marginLeft: '-40px',
                  marginRight: '-40px',
                }}
              >
                <Tabs
                  variant={'scrollable'}
                  scrollButtons
                  value={selectedFloor}
                  onChange={handleChangeSelectedFloor}
                  TabIndicatorProps={{ style: { height: 4 } }}
                  sx={{
                    '.MuiTabs-flexContainer': {
                      display: 'flex',
                      gap: '5px',
                    },
                    '.MuiTabs-scrollButtons': {
                      opacity: 0.4,
                    },
                    '.MuiTabs-scrollButtons.Mui-disabled': {
                      opacity: 0,
                    },
                  }}
                >
                  {getReadableFloorName(floors).map((el) => (
                    <Tab
                      key={el}
                      label={el}
                      sx={{
                        fontWeight: 700,
                        fontStyle: 'italic',
                        minHeight: '48px',
                        paddingLeft: 0,
                        paddingRight: '4px',
                      }}
                    />
                  ))}
                </Tabs>
              </Box>
              <CustomTable
                tableHeaders={tableHeaders}
                showFilters
                tableData={tableData}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortDirection={sortDirection}
                setSortDirection={setSortDirection}
                isLoading={fetchMaterialPriceIsLoading}
                isMaterialOverview
                appliedFilter={materialFilter.length !== 0 || priceFilter.length !== 0}
              />
            </CustomDialog>
          </Form>
        );
      }}
    </Formik>
  );
}
