import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { lighten } from 'polished';

interface PageNavigationLinkProps {
  open: boolean;
  item: {
    label: string;
    icon: string;
    route: string;
  };
}

export default function PageNavigationLink(props: PageNavigationLinkProps) {
  const { open, item } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const routeFromUrl = `${(location.pathname || '').split(`/`)?.[1]}`;
  const isSelected = useMemo(() => `/${routeFromUrl}` === item.route, [item.route, routeFromUrl]);

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={{
          height: '48px',
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
          borderRadius: '5px',
          marginLeft: '8px',
          marginRight: '8px',
          backgroundColor: isSelected ? 'rgba(189, 136, 144, 0.5)' : '#343E47',
          '&:hover': {
            backgroundColor: isSelected ? 'rgba(189, 136, 144, 0.5)' : lighten(0.1, '#7a93a7'),
          },
        }}
        onClick={() => navigate(item.route)}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
            color: '#fff',
          }}
        >
          <img src={item.icon} alt={item.label} style={{ height: '20px', width: '20px' }} />
        </ListItemIcon>
        <ListItemText
          primary={item.label}
          sx={{
            opacity: open ? 1 : 0,
            fontFamily: 'Montserrat',
            color: '#FFFFFE',
            fontSize: '16px',
            fontWeight: 400,
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}
