import React, { useEffect, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';

import { ReactComponent as SearchIcon } from '@assets/images/search.svg';
import { ReactComponent as CloseIcon } from '@assets/images/closeWithoutOutline.svg';
import { IconButton } from '@mui/material';
import { useDebounce } from 'use-debounce';

interface SearchFieldProps {
  value: string;
  placeholder?: string;
  handleOnChange: (a: string) => void;
  placeholderColor?: string;
  color?: string;
  type?: string;
}

function SearchField({
  value,
  handleOnChange,
  placeholder = '',
  placeholderColor = '#9A9FA5',
  color = '#2a2d31',
  type = 'text',
}: SearchFieldProps) {
  const [inputValue, setInputValue] = useState(value);
  const [debouncedValue] = useDebounce(inputValue, 600);

  useEffect(() => {
    if (value !== debouncedValue) {
      handleOnChange(debouncedValue);
    }
  }, [debouncedValue, handleOnChange, value]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(e.target.value);
  }

  function handleClear() {
    setInputValue('');
  }

  return (
    <OutlinedInput
      size="small"
      type={type}
      fullWidth
      sx={{
        height: '34px',
        width: '100%',
        fontSize: 16,
        fontWeight: 400,
        color,
        borderRadius: '5px',
        border: `1px solid #9A9FA5`,
        paddingRight: '8px',
        boxShadow: 'none',
        '&:focus-within': {
          border: `1px solid #9A9FA5`,
        },
        '& .MuiOutlinedInput-input': {
          padding: '8px',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        },
        '& fieldset': { border: 'none' },
        '& input::placeholder': {
          color: placeholderColor,
          opacity: 1,
        },
        '& input[type=number]': {
          '-moz-appearance': 'textfield',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      }}
      endAdornment={
        <IconButton disabled={!value} disableRipple onClick={handleClear} sx={{ padding: 0 }}>
          {!value ? <SearchIcon /> : <CloseIcon />}
        </IconButton>
      }
      placeholder={placeholder ?? ''}
      onChange={handleChange}
      value={inputValue}
    />
  );
}

export default SearchField;
