import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { productActions } from '../slice/productsSlice';
import { ApiRequest } from '../../../../api/ApiService';
import { DownloadImageDataResponse } from '../../types';

async function callApi(id: number) {
  return ApiRequest(Method.GET, ENDPOINTS.getProductColorImage, { id });
}

export default function* callDownloadProductColorImageSaga({ payload }: PayloadAction<number>) {
  try {
    const imageDataResponse: DownloadImageDataResponse = yield call(callApi, payload);
    yield put(productActions.downloadProductColorImageSuccess(imageDataResponse));
  } catch (error: unknown) {
    console.error('callDownloadProductColorImageSaga', error);
    yield put(productActions.downloadProductColorImageFailure());
  }
}
