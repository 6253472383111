import { ApiRequest } from '../../../../api/ApiService';
import { Method } from '../../../../api/types';
import { ENDPOINTS, WORKSPACE_CODE, WORKSPACE_ID } from '../../../../api/constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { EditUserPayload } from '../../types';
import { userActions } from '../slice/usersSlice';

async function callApi(props: EditUserPayload) {
  const payload = {
    fullName: props.fullName,
    email: props.email,
    idRole: parseInt(String(props.idRole)),
    idWorkspace: WORKSPACE_ID,
    workspaceCode: WORKSPACE_CODE,
    message: props.message,
  };
  return ApiRequest(Method.PUT, ENDPOINTS.updateUser, { id: props.id }, undefined, payload);
}

export default function* callEditUserSaga({ payload }: PayloadAction<EditUserPayload>) {
  try {
    yield call(callApi, payload);
    yield put(userActions.editUserSuccess());
    SuccessToast('Succes!', 'Gebruiker succesvol bewerkt');
  } catch (error: unknown) {
    console.error('callEditUserSaga', error);
    yield put(userActions.editUserFailure());
    ErrorToast(undefined, error.toString());
  }
}
