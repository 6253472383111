import { call, put } from 'redux-saga/effects';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { productActions } from '../slice/productsSlice';
import { ApiRequest } from '../../../../api/ApiService';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import { PayloadAction } from '@reduxjs/toolkit';
import { SyncSingleProductParams } from '../../types';

async function callApi(payload: SyncSingleProductParams) {
  return ApiRequest(Method.GET, ENDPOINTS.syncSingleProduct, { id: payload.groupId });
}

export default function* callSyncSingleProductWithExactSaga({ payload }: PayloadAction<SyncSingleProductParams>) {
  try {
    yield call(callApi, payload);
    yield put(productActions.syncSingleProductWithExactSuccess());
    SuccessToast('Product updated!', `\`${payload.groupName}\` is updated`);
  } catch (error: unknown) {
    console.error('callSyncSingleProductWithExactSaga', error);
    yield put(productActions.syncSingleProductWithExactFailure());
  }
}
