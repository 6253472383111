import { call, put } from 'redux-saga/effects';
import { UpdateClientPortal } from '../../types';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { Method } from '../../../../api/types';

async function callApi(props: UpdateClientPortal) {
  const queryParam = {
    id: props.id,
    IdLot: props.IdLot,
  };
  const payload = {
    name: props.name,
    portalStatus: props.portalStatus,
    numberOfFloors: Number(props.numberOfFloors),
  };
  return ApiRequest(Method.PUT, ENDPOINTS.updateClient, undefined, queryParam, payload);
}

export default function* callUpdateClientPortalSaga({ payload }: PayloadAction<UpdateClientPortal>) {
  try {
    yield call(callApi, payload);
    yield put(clientActions.updateClientPortalSuccess());
    SuccessToast('Succes!', 'Portaal succesvol gewijzigd');
  } catch (error: unknown) {
    console.error('callUpdateClientPortalSaga', error);
    yield put(clientActions.updateClientPortalFailure());
    ErrorToast(undefined, error.toString());
  }
}
