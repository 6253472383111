import { FilterType, TableHeaders } from '../../../shared/types';

export default function projectTableHeaders(
  nameFilter: string | undefined,
  setNameFilter: (a: string | undefined) => void,
  lotFilter: number,
  setLotFilter: (value: number) => void,
  bookedDateFilter: string,
  setBookedDateFilter: (value: string) => void
): TableHeaders[] {
  return [
    {
      label: 'Projecten',
      isSortable: true,
      filterPlaceHolder: 'Filter projecten...',
      filterType: FilterType.search,
      filterValue: nameFilter,
      onFilterChange: setNameFilter,
      columnName: 'project',
    },
    // {
    //   label: 'Styled Lots',
    //   isSortable: true,
    //   filterPlaceHolder: 'Filter lot...',
    //   filterType: FilterType.search,
    //   filterValue: lotFilter,
    //   onFilterChange: setLotFilter,
    //   columnName: 'styledLot',
    //   width: '20%',
    // },
    {
      label: 'Stijlkamer',
      columnName: 'styledLot',
      width: '20%',
      filterPlaceHolder: 'Filter status...',
      filterType: FilterType.select,
      filterValue: bookedDateFilter,
      onFilterChange: setBookedDateFilter,
      filterOptions: [
        { value: 'Booked', label: 'Geboekt' },
        { value: 'To book', label: 'Boeken' },
      ],
    },
  ];
}
