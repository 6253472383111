import { CustomTableDataCell } from '@shared/components/customTable/CustomTable';
import { IconButton } from '@mui/material';
import React from 'react';
import { TableBodyRow } from '@shared/types';
import TableStatusLabel, { TableStatusType } from '../../../shared/components/tableTextOptions/TableStatusLabel';
import { Product } from '../types';
import { ReactComponent as SyncIcon } from '@assets/images/refreshProduct.svg';
import { ReactComponent as ColorsWatchIcon } from '@assets/images/colorsWatch.svg';
import Box from '@mui/material/Box';
import TableLinkLabel from '../../../shared/components/tableTextOptions/TableLinkLabel';
import LightTooltip from '../../../utils/lightTooltip';

function getTableStatusType(status: string) {
  if (status === 'Voltooid') {
    return TableStatusType.Success;
  } else {
    return TableStatusType.Error;
  }
}

export default function productsTableData(
  productData: Product[],
  tableHeaderWidth: (string | undefined)[],
  handleSyncClick: (a: Product) => void,
  handleProductGroupImageClick: (a: Product) => void,
  handleProductColorClick: (a: Product) => void
): TableBodyRow[] {
  return productData.map((product: Product) => {
    return {
      id: `${product.idProduct}`,
      tableCell: (
        <>
          <CustomTableDataCell
            width={tableHeaderWidth[0] ?? 'unset'}
            onClick={() => handleProductGroupImageClick(product)}
            clickable
          >
            <Box display={'flex'} flexDirection={'row'}>
              <TableLinkLabel showUnderline={!!product?.picture || !!product?.brochureName}>
                {product.type}
              </TableLinkLabel>
            </Box>
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[1] ?? 'unset'} fontWeight={700}>
            {product.group}
          </CustomTableDataCell>
          <CustomTableDataCell
            width={tableHeaderWidth[2] ?? 'unset'}
            onClick={() => handleProductColorClick(product)}
            clickable
          >
            {product.color ? (
              <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'4px'}>
                <ColorsWatchIcon />
                <TableLinkLabel showUnderline>{`${product.color} kleuren`}</TableLinkLabel>
              </Box>
            ) : (
              'N/A'
            )}
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[3] ?? 'unset'} fontWeight={700}>
            <TableStatusLabel label={product.status || 'Image not there'} type={getTableStatusType(product.status!)} />
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[4] ?? 'unset'}>
            <LightTooltip title="Product bijwerken">
              <IconButton
                onClick={() => handleSyncClick(product)}
                sx={{
                  padding: '5px',
                  '&:disabled': {
                    opacity: '0 !important',
                  },
                }}
              >
                <SyncIcon width={16} height={16} />
              </IconButton>
            </LightTooltip>
          </CustomTableDataCell>
        </>
      ),
    };
  });
}
