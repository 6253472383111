import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { productActions } from '../slice/productsSlice';
import { ApiRequest } from '../../../../api/ApiService';
import { DownloadImageDataResponse } from '../../types';

async function callApi(id: number) {
  return ApiRequest(Method.GET, ENDPOINTS.getProductGroupImage, { id });
}

export default function* callDownloadProductGroupImageSaga({ payload }: PayloadAction<number>) {
  try {
    const imageDataResponse: DownloadImageDataResponse = yield call(callApi, payload);
    yield put(productActions.downloadProductGroupImageSuccess(imageDataResponse));
  } catch (error: unknown) {
    console.error('callDownloadProductGroupImageSaga', error);
    yield put(productActions.downloadProductGroupImageFailure());
  }
}
