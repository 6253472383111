import { call, put } from 'redux-saga/effects';
import { ChosenStyle } from '../../types';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';

async function callApi(clientId: number) {
  const queryParam = {
    clientId: clientId,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getChoosenStyle, undefined, queryParam);
}

export default function* callFetchChosenStyleSaga({ payload }: PayloadAction<number>) {
  try {
    const response: ChosenStyle = yield call(callApi, payload);
    yield put(clientActions.fetchChosenStyleSuccess(response));
  } catch (error: unknown) {
    console.error('callFetchChosenStyleSaga', error);
    yield put(clientActions.fetchChosenStyleFailure());
  }
}
