import CustomDialog from '../../../shared/components/modal/CustomDialog';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import {
  exitingTemplateDataSelector,
  selectTemplateStatusChangedSelector,
  updateClientTemplateIsLoadingSelector,
  updateClientTemplateIsSuccessSelector,
} from '../store/selectors/clientSelector';
import getReadableFloorName, { getReadableFloor } from '../../../utils/getReadableFloorName';
import { clientActions } from '../store/slice/clientSlice';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import EditTemplateSelectField from './EditTemplateSelectField';
import WindowsFieldComponent from './WindowsFieldsComponent';
import ErrorToast from '../../../shared/components/toast/ErrorToast';
import { styled } from '@mui/material/styles';
import { ImageData } from '../../products/types';
import convertToBase64 from '../../../utils/convertBase64';
import TableLinkLabel from '../../../shared/components/tableTextOptions/TableLinkLabel';
import { IconButton, Typography } from '@mui/material';
import { ReactComponent as EditIcon } from '@assets/images/tableRowIcons/edit.svg';
import { ReactComponent as DeleteIcon } from '@assets/images/tableRowIcons/delete.svg';
import { ReactComponent as AddIcon } from '@assets/images/add.svg';

interface EditTemplateModalProps {
  openModal: boolean;
  closeModal: () => void;
  idLot?: number;
  idLotType?: number;
  floors: number;
  refreshClients: () => void;
  selectedClientName?: string;
  floorImageUploadAvailable?: boolean;
}

interface ImageDataMap {
  [key: number]: ImageData | null;
}

export default function CreateEditTemplateModal(props: EditTemplateModalProps) {
  const {
    openModal,
    closeModal,
    idLot,
    idLotType,
    floors,
    refreshClients,
    selectedClientName,
    floorImageUploadAvailable = false,
  } = props;
  const dispatch = useAppDispatch();
  const updateClientTemplateIsLoading = useAppSelector(updateClientTemplateIsLoadingSelector);
  const updateClientTemplateIsSuccess = useAppSelector(updateClientTemplateIsSuccessSelector);
  const selectTemplateStatusChanged = useAppSelector(selectTemplateStatusChangedSelector);
  const exitingTemplateData = useAppSelector(exitingTemplateDataSelector);

  const [curruntFloor, setCurruntFloor] = useState<number>(1);
  const [showImage, setShowImage] = useState<ImageDataMap | null>(null);

  const imageInputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCurruntFloor(1);
    setShowImage(null);
  }, [openModal]);

  useEffect(() => {
    if (exitingTemplateData && exitingTemplateData[curruntFloor]) {
      const data = exitingTemplateData[curruntFloor];
      if (data.floorImageName && data.floorImageBlobId) {
        const updatedState = {
          ...showImage,
          [curruntFloor]: {
            fileName: data.floorImageName,
            fileData: data.floorImageBlobId,
          },
        };
        setShowImage(updatedState);
      }
    }
  }, [exitingTemplateData]);

  useEffect(() => {
    if (openModal && updateClientTemplateIsSuccess) {
      refreshClients();
      closeModal();
      dispatch(clientActions.resetSelectTemplateStatusChanged());
      dispatch(clientActions.resetUpdateClientTemplate());
    }
  }, [dispatch, closeModal, openModal, refreshClients, updateClientTemplateIsSuccess]);

  useEffect(() => {
    if (openModal) {
      dispatch(clientActions.fetchAvailableTemplates());
    }
  }, [dispatch, openModal]);

  function handleClose() {
    dispatch(clientActions.resetSelectTemplateStatusChanged());
    closeModal();
  }

  function handleSubmit(values: object) {
    console.log('---submit-->', JSON.stringify(values, null, 2));

    const templates: any = [];
    const windows = [];
    const valid: string[] = [];
    // @ts-ignore
    for (const [key, value] of Object.entries(values)) {
      if (value.template > 0) {
        const floor = parseInt(key) + 1;
        templates.push({
          idTemplate: Number(value.template),
          floors: floor,
        });
      }

      (value.windows || []).map((data) => {
        if (data.numberOfWindows !== '' && data.height !== '' && data.width !== '') {
          // @ts-ignore
          windows.push({
            numberOfWindows: Number(data.numberOfWindows),
            height: Number(data.height),
            width: Number(data.width),
            floors: parseInt(key) + 1,
            idWindows: data.idWindows,
          });
        } else {
          if (value.template !== '') {
            valid.push('invalid');
            ErrorToast(undefined, `venstervelden kunnen niet leeg zijn op de ${getReadableFloor(parseInt(key) + 1)}`);
          }
        }
      });
    }

    if (valid.length === 0) {
      const decimals: string[] = [];
      windows.forEach((window) => {
        const value = `${window.numberOfWindows}`;
        if (value.includes('.')) {
          decimals.push('false');
        }
      });
      if (decimals.length === 0) {
        if (templates.length !== 0) {
          dispatch(
            clientActions.updateClientTemplate({
              idLotType: idLotType,
              idLot: idLot,
              templetes: templates,
              windows: windows,
            })
          );
        }
      } else {
        ErrorToast('Invalid window number!', 'Please give valid window number');
      }
    }
  }

  const initialValues = [...Array(5).keys()].reduce(
    (ac, i) => ({
      ...ac,
      [i]: {
        template: '',
        windows: [],
      },
    }),
    { selectedFloor: 0 }
  );

  const validationSchema = Yup.object().shape({});

  if (!floors) return null;
  if (!(idLot || idLotType)) return null;
  if (!openModal) return null;

  function handleImageClick() {
    // setShowImage(true);
  }

  function handleDeleteImageClick() {
    const templateData = exitingTemplateData[curruntFloor];
    if (templateData?.floorImageName) {
      if (templateData?.idDefaultTemplete) {
        dispatch(clientActions.deleteFloorImage(templateData.idDefaultTemplete));
      }
    }
    if (showImage && showImage[curruntFloor]) {
      const updatedState = {
        ...showImage,
        [curruntFloor]: null,
      };
      setShowImage(updatedState);
      if (imageInputRef.current) {
        imageInputRef.current.value = '';
      }
    }
  }

  // console.log(showImage, 'showImage', curruntFloor);

  async function handleImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
    console.log(idLotType, idLot);
    // @ts-ignore
    if (idLotType || idLot) {
      const imageFile: any = event.target.files[0];
      const imageSizeInBytes = imageFile.size;
      const sizeInKB = imageSizeInBytes / (1024 * 1024);
      const fileExtension = imageFile.name.split('.').pop();
      if (fileExtension === 'pdf') {
        ErrorToast(undefined, 'kan pdf niet uploaden');
        handleClose();
      } else {
        if (sizeInKB >= 5) {
          ErrorToast(undefined, 'Image size should be less than 5mb');
        } else {
          const base64 = await convertToBase64(imageFile);
          const imageData = {
            fileName: imageFile.name,
            fileData: base64.split(',')[1],
          };
          const apiPayload = {
            LotTypeId: idLotType,
            LotsId: idLot,
            data: {
              floors: curruntFloor,
              floorImageFileData: imageData.fileData,
              floorImageFileName: imageData.fileName,
            },
          };

          dispatch(clientActions.uploadFloorImage(apiPayload));
          const updatedState = {
            ...showImage,
            [curruntFloor]: imageData,
          };
          setShowImage(updatedState);
        }
      }
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ dirty, isValid, values, submitForm, setFieldValue }) => {
        return (
          <Form>
            <CustomDialog
              open={openModal}
              handleClose={handleClose}
              title={`Sjabloon toevoegen voor ${selectedClientName}`}
              cancelButtonText="Annuleren"
              handleCancelButtonPress={handleClose}
              primaryButtonText="Opslaan"
              primaryButtonDisabled={
                !isValid || !dirty || updateClientTemplateIsLoading || !selectTemplateStatusChanged
              }
              handlePrimaryButtonPress={submitForm}
            >
              <Box
                mb={2}
                sx={{
                  marginLeft: '-40px',
                  marginRight: '-40px',
                }}
              >
                <Tabs
                  variant={'scrollable'}
                  scrollButtons
                  value={values.selectedFloor}
                  onChange={(event: React.SyntheticEvent, value: number) => {
                    setFieldValue('selectedFloor', value);
                    setCurruntFloor(value + 1);
                    imageInputRef?.current?.value && (imageInputRef.current.value = '');
                  }}
                  TabIndicatorProps={{ style: { height: 4 } }}
                  sx={{
                    '.MuiTabs-flexContainer': {
                      display: 'flex',
                      gap: '5px',
                    },
                    '.MuiTabs-scrollButtons': {
                      opacity: 0.4,
                    },
                    '.MuiTabs-scrollButtons.Mui-disabled': {
                      opacity: 0,
                    },
                  }}
                >
                  {getReadableFloorName(floors).map((el) => (
                    <Tab
                      key={el}
                      label={el}
                      sx={{
                        fontWeight: 700,
                        fontStyle: 'italic',
                        minHeight: '48px',
                        paddingLeft: 0,
                        paddingRight: '4px',
                      }}
                    />
                  ))}
                </Tabs>
              </Box>
              <EditTemplateSelectField openModal={openModal} idLot={idLot} idLotType={idLotType} />
              {floorImageUploadAvailable && (
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  sx={{ marginTop: '20px' }}
                >
                  <label htmlFor="image-file-upload">
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: '700',
                        color: '#0c1b2a',
                        marginBottom: '5px',
                        lineHeight: '110%',
                      }}
                    >
                      Plattegrond
                    </Typography>
                    <Input
                      accept=".png, .jpg, .jpeg, .heic"
                      id="image-file-upload"
                      type="file"
                      onChange={(event) => handleImageUpload(event)}
                      ref={imageInputRef}
                    />
                    {showImage && showImage[curruntFloor] ? (
                      <ImageLinkContainer onClick={handleImageClick}>
                        <TableLinkLabel showUnderline fontWeight={400}>
                          {showImage && showImage[curruntFloor] && showImage[curruntFloor]?.fileName}
                        </TableLinkLabel>
                      </ImageLinkContainer>
                    ) : (
                      <UploadImageLabel>
                        <AddIcon stroke={'#EC9E29'} style={{ marginRight: '4px' }} />
                        Bestand uploaden
                      </UploadImageLabel>
                    )}
                  </label>
                  {showImage && showImage[curruntFloor] && (
                    <Box display={'flex'} flexDirection={'row'} gap={'4px'} alignItems={'center'}>
                      <IconButton
                        sx={{
                          padding: '5px',
                          '&:disabled': {
                            opacity: '0 !important',
                          },
                        }}
                      >
                        <label htmlFor="image-file-upload" style={{ display: 'flex', cursor: 'pointer' }}>
                          <EditIcon width={16} height={16} />
                        </label>
                      </IconButton>
                      <IconButton
                        onClick={handleDeleteImageClick}
                        sx={{
                          padding: '5px',
                          '&:disabled': {
                            opacity: '0 !important',
                          },
                        }}
                      >
                        <DeleteIcon width={16} height={16} />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              )}
              <WindowsFieldComponent idLots={idLot} idLotType={idLotType} />
            </CustomDialog>
          </Form>
        );
      }}
    </Formik>
  );
}

const Input = styled('input')({
  display: 'none',
});

const ImageLinkContainer = styled('button')`
  cursor: pointer;
  border: none;
  background: transparent;
`;

const UploadImageLabel = styled('p')`
  color: #ec9e29;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`;
