import { call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { Material } from '../../../clients/types';
import { productActions } from '../slice/productsSlice';
import { ApiRequest } from '../../../../api/ApiService';

async function callApi(templateId: number) {
  const queryParam = {
    templateId,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getExistMaterials, undefined, queryParam);
}

export default function* callFetchExistingMaterialsSaga({ payload }: PayloadAction<number>) {
  try {
    const existMaterials: Material[] = yield call(callApi, payload);
    yield put(productActions.fetchExistingMaterialsSuccess(existMaterials));
  } catch (error: unknown) {
    console.error('callFetchExistingMaterialsSaga', error);
    yield put(productActions.fetchExistingMaterialsFailure());
  }
}
