import { call, put } from 'redux-saga/effects';
import { FetchProjectsParams, FetchProjectsResponse } from '../../types';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SIZE, ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { projectActions } from '../slice/projectsSlice';

async function callApi(props: FetchProjectsParams) {
  const queryParam = {
    searchKey: props?.searchKey,
    project: props?.project,
    lots: props?.lots,
    bookedDate: props?.bookedDate,
    KeyProperty: props?.KeyProperty,
    IsAscending: props?.IsAscending,
    pageSize: props?.pageSize ?? DEFAULT_PAGE_SIZE,
    pageNumber: props?.pageNumber ?? 1,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getProjects, undefined, queryParam);
}

export default function* callFetchProjectsSaga({ payload }: PayloadAction<FetchProjectsParams>) {
  try {
    const response: FetchProjectsResponse = yield call(callApi, payload);
    yield put(projectActions.fetchProjectsSuccess(response));
  } catch (error: unknown) {
    console.error('callFetchProjectsSaga', error);
    yield put(projectActions.fetchProjectsFailure());
  }
}
