import { GET } from '@api/ApiService';
import { BASE_URL } from './constants';
import axios from 'axios';

const urlFactory = {
  getToken: () => `${BASE_URL}/api/Token`,
  getUserinfo: () => `${BASE_URL}/UserInfo`,
};

const AuthenticationService = {
  getToken: async (redirectUrl: string, code: string) => {
    const params = {
      redirect_uri: redirectUrl,
      code,
    };
    const authRequired = false;
    return GET(urlFactory.getToken(), params, authRequired);
  },
  getUserinfo: async (accessToken: string) => {
    return axios
      .get(urlFactory.getUserinfo(), {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default AuthenticationService;
