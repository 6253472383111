import { useFormikContext } from 'formik';
import React from 'react';

import CustonSwitch from '../../../shared/components/customSwitch/CustomSwitch';

function DisableSwitch() {
  const { setFieldValue, values } = useFormikContext();

  const onChangeSwitch = (value: any) => {
    setFieldValue('isActive', !value);
  };

  return <CustonSwitch checked={!values.isActive} setChecked={onChangeSwitch} />;
}

export default DisableSwitch;
