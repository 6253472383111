import { ApiRequest } from '../../../../api/ApiService';
import { Method } from '../../../../api/types';
import { ENDPOINTS } from '../../../../api/constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { UpdateFloorAmountPayload } from '../../types';
import { projectActions } from '../slice/projectsSlice';

async function callApi(props: UpdateFloorAmountPayload) {
  const payload = {
    amountOfFloors: props.amountOfFloors,
  };
  return ApiRequest(Method.PUT, ENDPOINTS.updateLot, { lotTypeId: props.id }, undefined, payload);
}

export default function* callUpdateFloorAmountSaga({ payload }: PayloadAction<UpdateFloorAmountPayload>) {
  try {
    yield call(callApi, payload);
    yield put(projectActions.updateFloorAmountSuccess());
    SuccessToast('Succes!', 'Aantal verdiepingen Is gewijzigd');
  } catch (error: unknown) {
    console.error('callUpdateFloorAmountSaga', error);
    yield put(projectActions.updateFloorAmountFailure());
    ErrorToast(undefined, error.toString());
  }
}
