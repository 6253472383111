import { ApiRequest } from '../../../../api/ApiService';
import { Method } from '../../../../api/types';
import { ENDPOINTS } from '../../../../api/constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { productActions } from '../slice/productsSlice';

async function callApi(productId: number) {
  const pathParam = { id: productId };
  return ApiRequest(Method.DELETE, ENDPOINTS.deleteGroupImage, pathParam, undefined);
}

export default function* callDeleteGroupImageSaga({ payload }: PayloadAction<number>) {
  try {
    yield call(callApi, payload);
    yield put(productActions.deleteGroupImageSuccess());
    SuccessToast('Succes!', 'Afbeelding succesvol verwijderd');
  } catch (error: unknown) {
    console.error('callDeleteGroupImageSaga', error);
    yield put(productActions.deleteGroupImageFailure());
    // @ts-ignore
    ErrorToast(undefined, error.toString());
  }
}
