import { call, put } from 'redux-saga/effects';
import { AvailableTemplate, ExistingTemplateResponse, FetchExistingTemplateParams } from '../../types';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import { clientActions } from '../slice/clientSlice';
import { Method } from '../../../../api/types';

async function callApi(lotsId: number, lotTypeId: number, floor: number) {
  const queryParam = {
    lotsId,
    lotTypeId,
    floor,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getExistTemplates, undefined, queryParam);
}

export default function* callFetchExistingTemplatesSaga({ payload }: PayloadAction<FetchExistingTemplateParams>) {
  try {
    const existTemplates: ExistingTemplateResponse = yield call(
      callApi,
      payload.lotsId,
      payload.lotTypeId,
      payload.floor
    );
    payload.setData(existTemplates);
    yield put(
      clientActions.fetchExistingTemplatesLoaded({
        ...existTemplates,
        floor: payload.floor,
      })
    );
  } catch (error: unknown) {
    console.error('callFetchExistingTemplatesSaga', error);
  }
}
