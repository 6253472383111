import { call } from 'redux-saga/effects';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { Method } from '../../../../api/types';

async function callApi(lotTypeId: number | null) {
  const queryParam = {
    idLotType: lotTypeId,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getInviteAll, undefined, queryParam);
}

export default function* callInviteAllClientsSaga({ payload }: PayloadAction<number | null>) {
  try {
    yield call(callApi, payload);
    SuccessToast('Succes!', 'E-mail(s) verstuurd');
  } catch (error: unknown) {
    console.error('callInviteAllClientsSaga', error);
    ErrorToast(undefined, error.toString());
  }
}
