import { call } from 'redux-saga/effects';
import { ApiRequest } from '@api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';

async function callApi() {
  return ApiRequest(Method.GET, ENDPOINTS.getClientStatus, undefined, undefined);
}

export default function* callFetchClientStatusSaga() {
  try {
    yield call(callApi);
  } catch (error: unknown) {
    console.error('callFetchClientStatusSaga', error);
  }
}
