import { ApiRequest } from '../../../../api/ApiService';
import { Method } from '../../../../api/types';
import { ENDPOINTS } from '../../../../api/constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { UpdateMaterialPriceData, UpdateMaterialPricePayload } from '../../types';
import { projectActions } from '../slice/projectsSlice';

async function callApi(props: UpdateMaterialPricePayload) {
  const payload = {
    seelingPrice: props.seelingPrice,
    isDefaultPrice: props.isDefaultPrice,
  };
  return ApiRequest(Method.PUT, ENDPOINTS.updateProjectMaterialPrice, undefined, { idPrice: props.idPrice }, payload);
}

export default function* callUpdateMaterialPriceSaga({ payload }: PayloadAction<UpdateMaterialPriceData>) {
  try {
    const updatePayload: UpdateMaterialPricePayload = payload.uploadData;
    yield call(callApi, updatePayload);
    yield put(projectActions.updateMaterialPriceSuccess());
    if (payload.lastPrice) {
      SuccessToast('Succes!', 'Prijzen zijn aangepast');
    }
  } catch (error: unknown) {
    console.error('callUpdateMaterialPriceSaga', error);
    yield put(projectActions.updateMaterialPriceFailure());
    ErrorToast(undefined, error.toString());
  }
}
