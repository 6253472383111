import { ProductTypeDropDown } from '../types';

export default function removeDuplicates(array: ProductTypeDropDown[]): ProductTypeDropDown[] {
  const uniqueArray = array.reduce((accumulator, current) => {
    // Check if the current object's 'id' property already exists in the accumulator
    const exists = accumulator.some(
      (item) => item.idProductType === current.idProductType && item.floor === current.floor
    );

    // If it doesn't exist, add it to the accumulator
    if (!exists) {
      accumulator.push(current);
    }

    return accumulator;
  }, []);

  return uniqueArray;
}
