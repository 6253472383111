import { Client } from '@clients/types';
import { CustomTableDataCell } from '@shared/components/customTable/CustomTable';
import { IconButton } from '@mui/material';
import { ReactComponent as HambergerMenuIcon } from '@assets/images/hambergerMenu.svg';
import { ReactComponent as EnjinCoinIcon } from '@assets/images/enjinCoin.svg';
import { ReactComponent as ArchiveAddIcon } from '@assets/images/archiveAdd.svg';
import React from 'react';
import { TableBodyRow } from '@shared/types';
import TableStatusLabel, { TableStatusType } from '../../../shared/components/tableTextOptions/TableStatusLabel';
import LightTooltip from '../../../utils/lightTooltip';

function getTableStatusType(colorCode: number) {
  switch (colorCode) {
    case 1:
      return TableStatusType.Error;
    case 2:
      return TableStatusType.Warn;
    case 3:
    default:
      return TableStatusType.Success;
  }
}

export default function clientTableData(
  clientData: Client[],
  tableHeaderWidth: (string | undefined)[],
  handleMaterialOverviewClick: (a: Client) => void,
  handleEditTemplateClick: (a: Client) => void,
  handleEditPortalClick: (a: Client) => void,
  handleClickOnRow: (client: number, lotsId: number) => void
): TableBodyRow[] {
  const rowData = clientData;
  return rowData?.map((client: Client) => {
    return {
      id: `${client.idLots}`,
      tableCell: (
        <>
          <CustomTableDataCell
            width={tableHeaderWidth[0] ?? 'unset'}
            fontWeight={700}
            onClick={() => handleClickOnRow(client.idclient, client.idLots)}
            clickable
          >
            {client.project}
          </CustomTableDataCell>
          <CustomTableDataCell
            width={tableHeaderWidth[0] ?? 'unset'}
            fontWeight={700}
            onClick={() => handleClickOnRow(client.idclient, client.idLots)}
            clickable
          >
            {client.lots}
          </CustomTableDataCell>
          <CustomTableDataCell
            width={tableHeaderWidth[1] ?? 'unset'}
            fontWeight={700}
            onClick={() => handleClickOnRow(client.idclient, client.idLots)}
            clickable
          >
            {client.client}
          </CustomTableDataCell>
          {/* <CustomTableDataCell
            width={tableHeaderWidth[2] ?? 'unset'}
            fontWeight={700}
            onClick={() => handleClickOnRow(client.idclient, client.idLots)}
            clickable
          >
            {client.exactStatus}
          </CustomTableDataCell> */}
          <CustomTableDataCell
            width={tableHeaderWidth[3] ?? 'unset'}
            onClick={() => handleClickOnRow(client.idclient, client.idLots)}
            clickable
          >
            <TableStatusLabel label={client.status} type={getTableStatusType(client.colorCode)} />
          </CustomTableDataCell>
          <CustomTableDataCell
            width={tableHeaderWidth[4] ?? 'unset'}
            fontWeight={700}
            display="flex"
            justify="center"
            onClick={() => handleClickOnRow(client.idclient, client.idLots)}
            clickable
          >
            {client.floors}
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[5] ?? 'unset'}>
            <LightTooltip title="Portaal status wijzigen">
              <IconButton
                disabled={false}
                className={'table-icon'}
                onClick={() => handleEditPortalClick(client)}
                sx={{
                  padding: '5px',
                  '&:disabled': {
                    opacity: '0 !important',
                  },
                }}
              >
                <HambergerMenuIcon width={16} height={16} />
              </IconButton>
            </LightTooltip>
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[6] ?? 'unset'}>
            <LightTooltip title="Prijs aanpassen">
              <IconButton
                disabled={!client.floors}
                className={'table-icon'}
                onClick={() => handleMaterialOverviewClick(client)}
                sx={{
                  padding: '5px',
                  '&:disabled': {
                    opacity: '0 !important',
                  },
                }}
              >
                <EnjinCoinIcon width={16} height={16} />
              </IconButton>
            </LightTooltip>
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[7] ?? 'unset'}>
            <LightTooltip title="Template toevoegen">
              <IconButton
                disabled={!client.floors}
                className={'table-icon'}
                onClick={() => handleEditTemplateClick(client)}
                sx={{
                  padding: '5px',
                  '&:disabled': {
                    opacity: '0 !important',
                  },
                }}
              >
                <ArchiveAddIcon width={16} height={16} />
              </IconButton>
            </LightTooltip>
          </CustomTableDataCell>
        </>
      ),
    };
  });
}
