import React, { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationActions } from '@authentication/store/authentication.slice';
import { RootState } from '@store/types';
import useApiResponseHandler from '@hooks/UseApiResponseHandler.hook';
import { ROUTE_CLIENTS, ROUTE_LOGIN } from '../../../../utils/routes';

function SSOValidator() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfoLoading = useSelector((state: RootState) => state.authentication.getTokenIsLoading);
  const userInfoError = useSelector((state: RootState) => state.authentication.getTokenHasError);

  function handleError(): void {
    navigate(ROUTE_LOGIN, { replace: true });
  }

  function handleSuccessUserInfo(): void {
    navigate(ROUTE_CLIENTS, { replace: true });
  }

  useApiResponseHandler(userInfoLoading, userInfoError, handleSuccessUserInfo, handleError);

  useEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      const redirectUrl = process.env.REACT_APP_REDIRECT_URL ?? 'http://localhost:3000';
      dispatch(authenticationActions.getToken({ redirectUrl, code }));
    } else {
      navigate(ROUTE_LOGIN, { replace: true });
    }
  }, [dispatch, navigate, searchParams]);

  return (
    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress size={40} />
    </Box>
  );
}

export default SSOValidator;
