import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import { styled as materialStyled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const StyledLabel = materialStyled(Typography)(({ labelColor }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: labelColor,
  textAlign: 'left',
  lineHeight: '110%',
  marginBottom: '4px',
})) as typeof Typography;

interface SelectFieldProps {
  label?: string;
  labelColor?: string;
  options: { value: string | number; label: string }[];
  value: string;
  handleOnChange: (e: string) => void;
  placeholder?: string;
  color?: string;
  maxWidth?: string | number;
  minWidth?: string | number;
}

function SelectField(props: SelectFieldProps) {
  const {
    label,
    labelColor = '#343E47',
    options,
    handleOnChange,
    value = '',
    placeholder,
    color = '#9A9FA5',
    maxWidth = 'unset',
  } = props;

  function handleChange(event: SelectChangeEvent) {
    handleOnChange(event.target.value as string);
  }

  return (
    <Box display={'flex'} flexDirection={'column'}>
      {!!label && <StyledLabel labelColor={labelColor}>{label}</StyledLabel>}
      <Select
        size="small"
        value={value}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        sx={{
          height: '34px',
          fontSize: 16,
          fontWeight: 400,
          borderRadius: '5px',
          border: `1px solid ${color}`,
          borderColor: color,
          color,
          boxShadow: 'none',
          // paddingRight: '25px',
          maxWidth,
          // minWidth,
          '& .MuiOutlinedInput-input': {
            padding: '8px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
          '& fieldset': { border: 'none' },
          '& .MuiSelect-icon': {
            marginRight: '-8px',
            color: '#50606B',
            opacity: 0.5,
          },
        }}
      >
        {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
        {options.map((el) => (
          <MenuItem key={el.value} value={el.value}>
            {el.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

export default SelectField;
