import { ReactComponent as UpdateProductIcon } from '../../../../assets/images/updateProduct.svg';
import { Paper } from '@mui/material';
import TableSearchBar from '../../../shared/components/tableSearchBar/TableSearchBar';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../store/types';
import { useDispatch } from 'react-redux';
import { productActions } from '../store/slice/productsSlice';
import { Product } from '../types';
import CustomTable from '../../../shared/components/customTable/CustomTable';
import { TableColumnSortDirection } from '../../../shared/types';
import {
  fetchProductsIsLoadingSelector,
  productsSelector,
  productsTotalPagesSelector,
  syncProductsWithExactIsLoadingSelector,
  syncProductsWithExactIsSuccessSelector,
  syncSingleProductWithExactIsLoadingSelector,
  syncSingleProductWithExactIsSuccessSelector,
} from '../store/selectors/productsSelector';
import productsTableHeaders from '../components/productsTableHeaders';
import productsTableData from '../components/productsTableData';
import ProductGroupImageModal from '../components/ProductGroupImageModal';
import ProductColorImageModal from '../components/ProductColorImageModal';

export default function ProductsListing() {
  const dispatch = useDispatch();
  const products = useAppSelector(productsSelector);
  const fetchProductsIsLoading = useAppSelector(fetchProductsIsLoadingSelector);
  const totalNumberOfPages = useAppSelector(productsTotalPagesSelector);
  const syncProductsWithExactIsSuccess = useAppSelector(syncProductsWithExactIsSuccessSelector);
  const syncSingleProductWithExactIsSuccess = useAppSelector(syncSingleProductWithExactIsSuccessSelector);
  const syncSingleProductWithExactIsLoading = useAppSelector(syncSingleProductWithExactIsLoadingSelector);
  const syncProductsWithExactIsLoading = useAppSelector(syncProductsWithExactIsLoadingSelector);

  const [selectedProduct, setSelectedProduct] = React.useState<Product | undefined>();
  const [openProductGroupImageModal, setOpenProductGroupImageModal] = useState(false);
  const [openProductColorImageModal, setOpenProductColorImageModal] = React.useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const [sortBy, setSortBy] = useState<string | undefined>('Type');
  const [sortDirection, setSortDirection] = useState<TableColumnSortDirection>(TableColumnSortDirection.ASC);

  const [searchKeyFilter, setSearchKeyFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [groupFilter, setGroupFilter] = useState('');
  const [colorsFilter, setColorsFilter] = useState<number | undefined>();
  const [statusFilter, setStatusFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  function resetAllFilters() {
    if (currentPage === 1) {
      fetchProducts();
    }
    setSearchKeyFilter('');
    setTypeFilter('');
    setGroupFilter('');
    setColorsFilter(undefined);
    setStatusFilter('');
    setCurrentPage(1);
  }

  function fetchProducts() {
    dispatch(
      productActions.fetchProducts({
        searchKey: searchKeyFilter,
        type: typeFilter,
        group: groupFilter,
        color: colorsFilter,
        status: statusFilter,
        KeyProperty: sortBy,
        IsAscending: sortDirection === 'asc' ? true : false,
        pageNumber: searchKeyFilter
          ? 1
          : typeFilter.length === 0 &&
            groupFilter.length === 0 &&
            colorsFilter === undefined &&
            statusFilter.length === 0
          ? currentPage
          : 1,
      })
    );
  }

  useEffect(() => {
    fetchProducts();
  }, [
    searchKeyFilter,
    typeFilter,
    groupFilter,
    colorsFilter,
    statusFilter,
    currentPage,
    dispatch,
    sortBy,
    sortDirection,
  ]);

  useEffect(() => {
    if (syncProductsWithExactIsSuccess) {
      dispatch(productActions.resetSyncProductsWithExact());
      resetAllFilters();
    }
  }, [dispatch, resetAllFilters, syncProductsWithExactIsSuccess]);

  useEffect(() => {
    if (syncSingleProductWithExactIsSuccess) {
      dispatch(productActions.resetSyncSingleProductWithExact());
      resetAllFilters();
    }
  }, [dispatch, resetAllFilters, syncSingleProductWithExactIsSuccess]);

  function handleProductGroupImageClick(product: Product) {
    setSelectedProduct(product);
    setOpenProductGroupImageModal(true);
  }

  function handleProductColorImageClick(product: Product) {
    setSelectedProduct(product);
    setOpenProductColorImageModal(true);
  }

  function handleCloseProductGroupImageModal() {
    setSelectedProduct(undefined);
    setOpenProductGroupImageModal(false);
  }

  function handleCloseProductColorImageModal() {
    setSelectedProduct(undefined);
    setOpenProductColorImageModal(false);
  }

  function toggleFilters() {
    setShowFilters((prevVal) => !prevVal);
  }

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
  }

  function handleSyncProducts() {
    if (!syncProductsWithExactIsLoading) {
      dispatch(productActions.syncProductsWithExact());
    }
  }

  function handleSyncClick(product: Product) {
    if (!syncSingleProductWithExactIsLoading) {
      dispatch(
        productActions.syncSingleProductWithExact({
          groupName: product.group,
          groupId: product.exactId,
        })
      );
    }
  }

  const tableHeaders = productsTableHeaders(
    typeFilter,
    setTypeFilter,
    groupFilter,
    setGroupFilter,
    colorsFilter,
    setColorsFilter,
    statusFilter,
    setStatusFilter
  );

  // const modifiedProductsData = orderBy(products, [(client) => `${client[sortBy ?? '']}`.toLowerCase()], sortDirection);

  const tableData = productsTableData(
    products,
    tableHeaders.map((i) => i.width),
    handleSyncClick,
    handleProductGroupImageClick,
    handleProductColorImageClick
  );

  if (!products) return null;

  return (
    <Paper className="common-paper">
      <TableSearchBar
        buttonStartIcon={<UpdateProductIcon />}
        buttonText="Producten bijwerken"
        placeHolder={'Zoek product...'}
        showFilterToggle
        toggleFilterHandler={toggleFilters}
        buttonClickHandler={handleSyncProducts}
        onSearch={setSearchKeyFilter}
        showFilter={showFilters}
      />
      <CustomTable
        tableHeaders={tableHeaders}
        showFilters={showFilters}
        tableData={tableData}
        totalNumberOfPages={totalNumberOfPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        isLoading={fetchProductsIsLoading}
      />
      <ProductColorImageModal
        openModal={openProductColorImageModal}
        closeModal={handleCloseProductColorImageModal}
        selectedProduct={selectedProduct}
        fetchProducts={fetchProducts}
      />
      <ProductGroupImageModal
        openModal={openProductGroupImageModal}
        closeModal={handleCloseProductGroupImageModal}
        selectedProduct={selectedProduct}
        fetchProducts={fetchProducts}
      />
    </Paper>
  );
}
