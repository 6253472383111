import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { CreateUserPayload, User } from '../types';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import CustomDialog from '../../../shared/components/modal/CustomDialog';
import TextInputField from '../../../shared/components/textField/TextInputField';
import SelectInputField from '../../../shared/components/selectField/SelectInputField';
import { userActions } from '../store/slice/usersSlice';
import { useAppSelector } from '../../../store/types';
import {
  createUserIsLoadingSelector,
  createUserIsSuccessSelector,
  editUserIsLoadingSelector,
  editUserIsSuccessSelector,
} from '../store/selectors/userSelectors';
import { ReactComponent as AddUserIcon } from '../../../../assets/images/addUser.svg';

interface CreateEditUserModalProps {
  openModal: boolean;
  closeModal: () => void;
  selectedUser: User;
  fetchUsers: () => void;
}

export default function CreateEditUserModal(props: CreateEditUserModalProps) {
  const { openModal, closeModal, selectedUser, fetchUsers } = props;
  const dispatch = useDispatch();

  const createUserIsLoading = useAppSelector(createUserIsLoadingSelector);
  const editUserIsLoading = useAppSelector(editUserIsLoadingSelector);
  const createUserIsSuccess = useAppSelector(createUserIsSuccessSelector);
  const editUserIsSuccess = useAppSelector(editUserIsSuccessSelector);

  useEffect(() => {
    if (createUserIsSuccess || editUserIsSuccess) {
      closeModal();
      fetchUsers();
      dispatch(userActions.resetCreateUser());
      dispatch(userActions.resetEditUser());
    }
  }, [closeModal, createUserIsSuccess, dispatch, editUserIsSuccess, fetchUsers]);

  if (!openModal) return null;

  const isEditMode = !!selectedUser;

  function getSelectedUserRole() {
    switch (selectedUser?.authorization) {
      case 'Admin':
        return 1;
      case 'User':
        return 2;
      default:
        return undefined;
    }
  }

  const initialValues: CreateUserPayload = {
    email: selectedUser?.email || '',
    fullName: selectedUser?.fullName || '',
    idRole: getSelectedUserRole() ?? null,
    message: '',
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Naam is verplicht'),
    email: Yup.string().email('E-mail adres is verplicht').required('E-mail is verplicht'),
    idRole: Yup.string().required('Authorization role required'),
  });

  function handleSubmit(values: CreateUserPayload) {
    isEditMode
      ? dispatch(userActions.editUser({ ...values, id: selectedUser.idUser }))
      : dispatch(userActions.createUser(values));
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ dirty, isValid, values, submitForm, errors }) => {
        return (
          <Form>
            <CustomDialog
              open={openModal}
              handleClose={closeModal}
              title={isEditMode ? 'Stijlpartner-gebruiker bewerken' : 'Stijlpartner-gebruiker aanmaken'}
              cancelButtonText={'Annuleren'}
              handleCancelButtonPress={closeModal}
              primaryButtonText={isEditMode ? 'Opslaan' : 'Gebruiker aanmaken'}
              primaryButtonDisabled={!isValid || !dirty || createUserIsLoading || editUserIsLoading}
              handlePrimaryButtonPress={submitForm}
              primaryButtonStartIcon={!isEditMode && <AddUserIcon />}
            >
              <Grid container rowGap={2}>
                <Grid item xs={12}>
                  <TextInputField label={'Naam'} name="fullName" placeholder={'Volledige naam van de gebruiker...'} />
                </Grid>
                <Grid item xs={12}>
                  <TextInputField
                    label={'E-mail'}
                    name="email"
                    disabled={isEditMode}
                    placeholder={'E-mailadres van de gebruiker...'}
                    hint={'Dit e-mail adres wordt gebruikt om te authentiseren met single sign on'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectInputField
                    label={'authorisatieniveau'}
                    name="idRole"
                    placeholder={'Selecteer authorisatieniveau...'}
                    options={[
                      { label: 'Admin', value: 1 },
                      { label: 'User', value: 2 },
                    ]}
                  />
                </Grid>
              </Grid>
            </CustomDialog>
          </Form>
        );
      }}
    </Formik>
  );
}
