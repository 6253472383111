import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import CustomDialog from '@shared/components/modal/CustomDialog';
import SendEmailContent from './SendEmailContent';
import { EmailContentType, PostEmailAttachmentType } from '../types';
import { clientActions } from '../store/slice/clientSlice';
import { fetchClientQuoteSelector, sendQuoteIsSuccessSelector } from '../store/selectors/clientSelector';
import PdfViewer from '../../../shared/components/pdfViewer/PdfViewer';

interface SendEmailModalProps {
  openModal: boolean;
  closeModal: () => void;
  selectedClientEmail: string;
  clientName: string;
  selectedClientId: number;
  idLots: number;
}

const BASE_REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;

export default function SendEmailModal(props: SendEmailModalProps) {
  const { openModal, closeModal, selectedClientEmail, clientName, selectedClientId, idLots } = props;
  const dispatch = useAppDispatch();

  const getClientQuote = useAppSelector(fetchClientQuoteSelector);
  const sendQuoteIsSuccess = useAppSelector(sendQuoteIsSuccessSelector);

  useEffect(() => {
    if (sendQuoteIsSuccess) {
      dispatch(clientActions.resetSendQuote());
      closeModal();
    }
  }, [sendQuoteIsSuccess, dispatch, closeModal]);

  function handleSubmit(values: EmailContentType) {
    const originalEmailBody = values?.emailBody;
    const emailSplit = originalEmailBody.split('<p><br></p>');
    const emailBody = emailSplit.join('');
    const emailContents: EmailContentType = {
      from_email: values?.from_email,
      from_name: values?.from_name,
      subject: values?.subject,
      to_email: values?.to_email,
      addCCs: values?.addCCs,
      emailBody: emailBody,
    };
    if (getClientQuote && selectedClientId && idLots) {
      const payload: PostEmailAttachmentType = {
        id: selectedClientId,
        idLots: idLots,
        email: emailContents,
        attachment: {
          content: getClientQuote?.quote,
          filename: `${getClientQuote?.quoteName}.pdf`,
        },
      };
      dispatch(clientActions.sendQuote(payload));
    }
  }

  const validationSchema = Yup.object().shape({
    // portalStatus: Yup.string().required(),
    // noOfFloors: Yup.number().integer().min(1).required(),
  });

  const initialValues: EmailContentType = {
    from_email: 'support@nimble.expert',
    from_name: 'StijlPartner',
    subject: 'Offerte Stijlpartner',
    to_email: selectedClientEmail ?? '',
    addCCs: [],
    emailBody: `
      <p>Beste ${clientName},</p>
      <br />
      <p>Wat leuk dat je een kijkje hebt genomen in onze app mijn.stijlpartner! We zien dat je je vloer- en raamkleding voor in je nieuwe woning hebt gekozen. Je hebt de eerste stap gezet naar jouw nieuwe stijlvolle thuis. </p>
      <br />
      <p>Voor je gekozen producten hebben we een passende offerte gemaakt, die je in de bijlage kunt vinden. Zou je, bij akkoord, deze offerte digitaal willen ondertekenen? We zijn je alvast erg dankbaar.</p>
      <br />
      <p>Ga naar mijn.stijlpartner:</p>
      <p><a href="https://mijn.stijlpartner.nl/login">mijn.stijlpartner.nl</a></p>
      <br />
      <p>Je kunt nu een afspraak plannen in onze Stijlkamer. Ga hiervoor naar mijn.stijlpartner.nl. Tijdens dit bezoek krijg je alle tijd om je gekozen vloer- en raambekleding in het echt te bekijken. Onze eigen styliste is aanwezig om je advies te geven en al je vragen te beantwoorden.</p>
      <br />
      <p>Mocht je vragen hebben, neem gerust contact met ons op via het telefoonnummer <a href="tel: 0629903001">0629903001</a> of mail naar <a href="mailto: hallo@stijlpartner.nl">hallo@stijlpartner.nl</a>.</p>
      <br />
      <p>Met stijlvolle groet,</p>
      <p>Marianne</p>
      <p>Team Stijlpartner</p>
      <br />
      <p><img src="https://stijlpartnerdevstorage.blob.core.windows.net/spproductimages/81a63e5e-4e07-4276-b755-57ea85b3d0c9_logo-stijlpartner.png" width="160px" height="27px"/></p>
    `,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ dirty, isValid, submitForm }) => {
        return (
          <Form>
            <CustomDialog
              open={openModal}
              handleClose={closeModal}
              title={'E-mailvoorbeeld'}
              cancelButtonText={'Annuleren'}
              handleCancelButtonPress={closeModal}
              primaryButtonText={'Versturen'}
              primaryButtonDisabled={!isValid || !dirty}
              handlePrimaryButtonPress={submitForm}
              width={'80%'}
            >
              <Grid container direction={'row'}>
                <Grid item xs={5}>
                  <SendEmailContent />
                </Grid>
                <Grid
                  item
                  xs={7}
                  sx={{ display: 'flex', justifyContent: 'center', maxHeight: '94vh', marginTop: '4px' }}
                >
                  <PdfViewer pdfData={getClientQuote?.quote ?? ''} />
                </Grid>
              </Grid>
            </CustomDialog>
          </Form>
        );
      }}
    </Formik>
  );
}
