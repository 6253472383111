import { ReactComponent as TemplateIcon } from '../../../../assets/images/templateIcons/templateBtn.svg';
import { Paper } from '@mui/material';
import TableSearchBar from '../../../shared/components/tableSearchBar/TableSearchBar';
import React, { useEffect, useState } from 'react';
import CreateEditTemplateModal from '../components/CreateEditTemplateModal';
import { Template } from '../types';
import { useDispatch } from 'react-redux';
import { productActions } from '../store/slice/productsSlice';
import { useAppSelector } from '../../../store/types';
import { TableColumnSortDirection } from '../../../shared/types';
import CustomTable from '../../../shared/components/customTable/CustomTable';
import templateTableHeaders from '../components/templateTableHeaders';
import templateTableData from '../components/templateTableData';
import {
  fetchTemplatesIsLoadingSelector,
  templatesSelector,
  templatesTotalPagesSelector,
} from '../store/selectors/productsSelector';
import DeleteTemplateModal from '../components/DeleteTemplateModal';

export default function TemplatesListing() {
  const dispatch = useDispatch();
  const templates = useAppSelector(templatesSelector);
  const fetchTemplatesIsLoading = useAppSelector(fetchTemplatesIsLoadingSelector);
  const totalNumberOfPages = useAppSelector(templatesTotalPagesSelector);

  const [selectedTemplate, setSelectedTemplate] = React.useState<Template | null>();
  const [openCreateEditTemplateModal, setOpenCreateEditTemplateModal] = React.useState(false);
  const [openDeleteTemplateModal, setOpenDeleteTemplateModal] = React.useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const [sortBy, setSortBy] = useState<string | undefined>('Name');
  const [sortDirection, setSortDirection] = useState<TableColumnSortDirection>(TableColumnSortDirection.ASC);

  const [searchKeyFilter, setSearchKeyFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('');
  const [materialFilter, setMaterialFilter] = useState<number | null>(null);
  const [statusFilter, setStatusFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  function resetAllFilters() {
    if (currentPage === 1) {
      fetchTemplates();
    }
    setSearchKeyFilter('');
    setNameFilter('');
    setMaterialFilter(null);
    setStatusFilter('');
    setCurrentPage(1);
  }

  function fetchTemplates() {
    dispatch(
      productActions.fetchTemplates({
        searchKey: searchKeyFilter,
        name: nameFilter,
        material: materialFilter,
        status: statusFilter,
        KeyProperty: sortBy,
        IsAscending: sortDirection === 'asc' ? true : false,
        pageNumber: searchKeyFilter
          ? 1
          : nameFilter.length === 0 && materialFilter === null && statusFilter.length === 0
          ? currentPage
          : 1,
      })
    );
  }

  useEffect(() => {
    fetchTemplates();
  }, [searchKeyFilter, nameFilter, materialFilter, statusFilter, currentPage, dispatch, sortBy, sortDirection]);

  function handleCreateEditTemplateClick(template: Template) {
    setSelectedTemplate(template);
    setOpenCreateEditTemplateModal(true);
  }

  function handleDeleteTemplateClick(template: Template) {
    setSelectedTemplate(template);
    setOpenDeleteTemplateModal(true);
  }

  function handleCloseCreateEditTemplateModal() {
    setSelectedTemplate(null);
    setOpenCreateEditTemplateModal(false);
  }

  function handleCloseDeleteTemplateModal() {
    setSelectedTemplate(null);
    setOpenDeleteTemplateModal(false);
  }

  function toggleFilters() {
    setShowFilters((prevVal) => !prevVal);
  }

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setCurrentPage(value);
  }

  // const modifiedTemplateData = orderBy(
  //   templates,
  //   [(client) => `${client[sortBy ?? 'name']}`.toLowerCase()],
  //   sortDirection
  // );

  const tableHeaders = templateTableHeaders(
    nameFilter,
    setNameFilter,
    materialFilter,
    setMaterialFilter,
    statusFilter,
    setStatusFilter
  );

  const tableData = templateTableData(
    templates,
    tableHeaders.map((i) => i.width),
    handleCreateEditTemplateClick,
    handleDeleteTemplateClick
  );

  return (
    <Paper className="common-paper">
      <TableSearchBar
        buttonStartIcon={<TemplateIcon />}
        buttonText={'Template maken'}
        buttonClickHandler={() => setOpenCreateEditTemplateModal(true)}
        placeHolder={'Zoek naam...'}
        showFilterToggle
        toggleFilterHandler={toggleFilters}
        onSearch={setSearchKeyFilter}
        showFilter={showFilters}
      />
      <CustomTable
        tableHeaders={tableHeaders}
        showFilters={showFilters}
        tableData={tableData}
        totalNumberOfPages={totalNumberOfPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        isLoading={fetchTemplatesIsLoading}
      />
      <CreateEditTemplateModal
        openModal={openCreateEditTemplateModal}
        closeModal={handleCloseCreateEditTemplateModal}
        selectedTemplate={selectedTemplate!}
        fetchTemplates={!selectedTemplate ? resetAllFilters : fetchTemplates}
      />
      <DeleteTemplateModal
        openModal={openDeleteTemplateModal}
        closeModal={handleCloseDeleteTemplateModal}
        selectedTemplate={selectedTemplate!}
        fetchTemplates={resetAllFilters}
      />
    </Paper>
  );
}
