import { call, put } from 'redux-saga/effects';
import { UpdateChosenStyles } from '../../types';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { Method } from '../../../../api/types';

async function callApi(props: UpdateChosenStyles) {
  const queryParam = {
    clientId: props.clientId,
  };
  const payload = props.updateChosenStyles
    .filter((i) => Number(i.idProductType) !== -1)
    .map((i) => ({
      idChoosenStyle: i.idChoosenStyle ? Number(i.idChoosenStyle) : 0,
      idProductType: Number(i.idProductType),
      idProductGroup: Number(i.idProductGroup),
      idMaterialColor: Number(i.idMaterialColor),
      price: Number(i.price),
      floors: Number(i.floors),
    }));
  return ApiRequest(Method.PUT, ENDPOINTS.editChoosenStyle, undefined, queryParam, payload);
}

export default function* callUpdateChosenStyleSaga({ payload }: PayloadAction<UpdateChosenStyles>) {
  try {
    yield call(callApi, payload);
    yield put(clientActions.updateChosenStyleSuccess());
    SuccessToast('Succes!', 'Je hebt de gekozen stijl gewijzigd');
  } catch (error: unknown) {
    console.error('callUpdateChosenStyleSaga', error);
    yield put(clientActions.updateChosenStyleFailure());
    ErrorToast(undefined, error.toString());
  }
}
