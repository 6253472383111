import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import TextInputField from '../../../shared/components/textField/TextInputField';
import RichTextBox from '../../../shared/components/RichTextBox/RichTextBox';

const StyledLabel = styled('p')`
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  color: #0c1b2a;
  margin-bottom: 4px;
  line-height: 110%;
  font-family: 'Public-sans';
`;

export default function SendEmailContent() {
  return (
    <Box sx={{ marginRight: '12px' }}>
      <Grid container>
        <Grid item xs={12}>
          <TextInputField label="Naar:" name="to_email" disabled />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '12px' }}>
          <TextInputField label="Onderwerp:" name="subject" />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '12px' }}>
          <StyledLabel>E-mail:</StyledLabel>
          <RichTextBox name={'emailBody'} />
        </Grid>
      </Grid>
    </Box>
  );
}
