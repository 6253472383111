import { CustomTableDataCell } from '@shared/components/customTable/CustomTable';
import React from 'react';
import { TableBodyRow } from '@shared/types';
import { Project } from '../types';
import Box from '@mui/material/Box';
import { ReactComponent as CalendarIcon } from '@assets/images/calendar.svg';
import { styled } from '@mui/material/styles';

const ToBook = styled('p')`
  color: #ec4c29;
  font-size: 14px;
  font-weight: 700;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.56px;
`;

const Booked = styled('p')`
  color: #b2d85a;
  font-size: 14px;
  font-weight: 700;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.56px;
`;

function StyleRoomType(props: { type: number }) {
  switch (props.type) {
    case 0:
      return (
        <Box display={'flex'} flexDirection={'row'}>
          <CalendarIcon stroke={'#EC4C29'} style={{ marginRight: 4 }} />
          <ToBook>Boeken</ToBook>
        </Box>
      );
    case 1:
    default:
      return (
        <Box display={'flex'} flexDirection={'row'}>
          <CalendarIcon stroke={'#B2D85A'} style={{ marginRight: 4 }} />
          <Booked>Geboekt</Booked>
        </Box>
      );
  }
}

export default function projectTableData(
  projectData: Project[],
  tableHeaderWidth: (string | undefined)[],
  handleClickOnRow: (projectId: number) => void,
  handleTimeSlotClick: (project: Project) => void,
  showFilter: boolean
): TableBodyRow[] {
  return projectData.map((project: Project) => {
    return {
      id: `${project.idProject}`,
      tableCell: (
        <>
          <CustomTableDataCell
            width={tableHeaderWidth[0] ?? 'unset'}
            fontWeight={700}
            onClick={() => handleClickOnRow(project.idProject)}
            clickable
          >
            {project.projectName}
          </CustomTableDataCell>
          {/* <CustomTableDataCell width={tableHeaderWidth[1] ?? 'unset'} fontWeight={700}>
            <div style={{ paddingLeft: showFilter ? '75px' : '35px' }}>{project.styledLot}</div>
          </CustomTableDataCell> */}
          <CustomTableDataCell
            width={tableHeaderWidth[2] ?? 'unset'}
            clickable
            onClick={() => handleTimeSlotClick(project)}
          >
            <StyleRoomType type={project?.bookeddates && project?.bookeddates?.length > 0 ? 1 : 0} />
          </CustomTableDataCell>
        </>
      ),
    };
  });
}
