import React, { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { Grid, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import SelectInputField from '../../../shared/components/selectField/SelectInputField';
import TextInputField from '../../../shared/components/textField/TextInputField';
import { MaterialColor, ProductTypeDropDown } from '../types';
import { productTypeDropDownDataSelector } from '../store/selectors/clientSelector';
import { clientActions } from '../store/slice/clientSlice';
import {
  floorMaterialPriceSelector,
  windowMaterialPriceSelector,
  curtainMaterialPriceSelector,
} from '../store/selectors/clientSelector';

interface ChangeStyleModalContentProps {
  floorProductTypeField: string;
  floorMaterialColorField: string;
  floorMaterialPrice: string;
  windowProductTypeField: string;
  windowMaterialColorField: string;
  windowMaterialPrice: string;
  curtainProductTypeField: string;
  curtainMaterialColorField: string;
  curtainMaterialPrice: string;
  isEditStyle: boolean;
  currentFloor: number;
  clientId: number;
  idDefaultTemplate: number;
}

export default function ChangeStyleModalContent(props: ChangeStyleModalContentProps) {
  const { setFieldValue } = useFormikContext();
  const dispatch = useAppDispatch();
  const {
    floorProductTypeField,
    floorMaterialColorField,
    floorMaterialPrice,
    windowProductTypeField,
    windowMaterialColorField,
    windowMaterialPrice,
    curtainProductTypeField,
    curtainMaterialColorField,
    curtainMaterialPrice,
    isEditStyle,
    currentFloor,
    clientId,
    idDefaultTemplate,
  } = props;
  const productTypeDropDownData = useAppSelector(productTypeDropDownDataSelector);
  const newFloorMaterialPrice = useAppSelector(floorMaterialPriceSelector);
  const newWindowMaterialPrice = useAppSelector(windowMaterialPriceSelector);
  const newCurtainMaterialPrice = useAppSelector(curtainMaterialPriceSelector);
  const dropDownType = productTypeDropDownData?.find((i: ProductTypeDropDown) => i.floor === currentFloor);
  const defaultTemplateId = dropDownType?.idDefaultTemplete;
  const floorMaterials = productTypeDropDownData?.filter(
    (i: ProductTypeDropDown) => i.productGroupCode === 1 && i.floor === currentFloor
  );
  const windowsMaterials = productTypeDropDownData?.filter(
    (i: ProductTypeDropDown) => i.productGroupCode === 2 && i.floor === currentFloor
  );
  const curtainMaterials = productTypeDropDownData?.filter(
    (i: ProductTypeDropDown) => i.productGroupCode === 3 && i.floor === currentFloor
  );

  const [floorMaterialColors, setFloorMaterialColors] = useState<MaterialColor[]>([]);
  const [windowMaterialColors, setWindowMaterialColors] = useState<MaterialColor[]>([]);
  const [curtainMaterialColors, setCurtainMaterialColors] = useState<MaterialColor[]>([]);
  const [floorProductTypeFieldData] = useField(floorProductTypeField);
  const [floorMaterialColorFieldData] = useField(floorMaterialColorField);
  const [windowProductTypeFieldData] = useField(windowProductTypeField);
  const [windowMaterialColorFieldData] = useField(windowMaterialColorField);
  const [curtainProductTypeFieldData] = useField(curtainProductTypeField);
  const [curtainMaterialColorFieldData] = useField(curtainMaterialColorField);

  useEffect(() => {
    setFloorMaterialColors([]);
    setWindowMaterialColors([]);
    setCurtainMaterialColors([]);
  }, [currentFloor]);

  useEffect(() => {
    if (floorProductTypeFieldData.value === -1) {
      setFieldValue(floorMaterialPrice, 0);
    }
    const idProductType = productTypeDropDownData.find(
      (data) => data.idProductType === Number(floorProductTypeFieldData.value)
    );
    if (idProductType) {
      const idProduct = idProductType.idProduct;
      dispatch(
        clientActions.fetchMaterialColor({
          idProduct: idProduct,
          setColors: setFloorMaterialColors,
        })
      );
      if (defaultTemplateId) {
        dispatch(
          clientActions.fetchFloorMaterialPrice({
            IdClient: clientId,
            IdProduct: idProduct,
            IdDefaultTemplete: defaultTemplateId,
            floor: currentFloor,
          })
        );
      }
    }
    if (parseInt(floorProductTypeFieldData.value) === -1) {
      setFieldValue(floorMaterialColorField, -1);
    }
  }, [dispatch, floorMaterialColorField, floorProductTypeFieldData.value, setFieldValue, productTypeDropDownData]);

  useEffect(() => {
    if (windowProductTypeFieldData.value === -1) {
      setFieldValue(windowMaterialPrice, 0);
    }
    const idProductType = productTypeDropDownData.find(
      (data) => data.idProductType === Number(windowProductTypeFieldData.value)
    );
    if (idProductType) {
      const idProduct = idProductType.idProduct;
      dispatch(
        clientActions.fetchMaterialColor({
          idProduct: idProduct,
          setColors: setWindowMaterialColors,
        })
      );
      if (defaultTemplateId) {
        dispatch(
          clientActions.fetchWindowMaterialPrice({
            IdClient: clientId,
            IdProduct: idProduct,
            IdDefaultTemplete: defaultTemplateId,
            floor: currentFloor,
          })
        );
      }
    }
    if (parseInt(windowProductTypeFieldData.value) === -1) {
      setFieldValue(windowMaterialColorField, -1);
    }
  }, [dispatch, setFieldValue, windowMaterialColorField, windowProductTypeFieldData.value, productTypeDropDownData]);

  useEffect(() => {
    if (curtainProductTypeFieldData.value === -1) {
      setFieldValue(curtainMaterialPrice, 0);
    }
    const idProductType = productTypeDropDownData.find(
      (data) => data.idProductType === Number(curtainProductTypeFieldData.value)
    );
    if (idProductType) {
      const idProduct = idProductType.idProduct;
      dispatch(
        clientActions.fetchMaterialColor({
          idProduct: idProduct,
          setColors: setCurtainMaterialColors,
        })
      );
      if (defaultTemplateId) {
        dispatch(
          clientActions.fetchCurtainMaterialPrice({
            IdClient: clientId,
            IdProduct: idProduct,
            IdDefaultTemplete: defaultTemplateId,
            floor: currentFloor,
          })
        );
      }
    }
    if (parseInt(curtainProductTypeFieldData.value) === -1) {
      setFieldValue(curtainMaterialColorField, -1);
    }
  }, [dispatch, setFieldValue, curtainMaterialColorField, curtainProductTypeFieldData.value, productTypeDropDownData]);

  useEffect(() => {
    if (newFloorMaterialPrice || newFloorMaterialPrice === 0) {
      setFieldValue(floorMaterialPrice, newFloorMaterialPrice);
    }
  }, [newFloorMaterialPrice, floorMaterialPrice, setFieldValue]);

  useEffect(() => {
    if (newWindowMaterialPrice || newWindowMaterialPrice === 0) {
      setFieldValue(windowMaterialPrice, newWindowMaterialPrice);
    }
  }, [newWindowMaterialPrice, windowMaterialPrice, setFieldValue]);

  useEffect(() => {
    if (newCurtainMaterialPrice || newCurtainMaterialPrice === 0) {
      setFieldValue(curtainMaterialPrice, newCurtainMaterialPrice);
    }
  }, [newCurtainMaterialPrice, curtainMaterialPrice, setFieldValue]);

  const disableFloorPrice =
    (floorProductTypeFieldData.value === -1 && floorMaterialColorFieldData.value === -1) ||
    (floorProductTypeFieldData.value !== -1 && floorMaterialColorFieldData.value === -1) ||
    (floorProductTypeFieldData.value === -1 && floorMaterialColorFieldData.value !== -1);

  const disableWinowPrice =
    (windowProductTypeFieldData.value === -1 && windowMaterialColorFieldData.value === -1) ||
    (windowProductTypeFieldData.value !== -1 && windowMaterialColorFieldData.value === -1) ||
    (windowProductTypeFieldData.value === -1 && windowMaterialColorFieldData.value !== -1);

  const disableCurtainPrice =
    (curtainProductTypeFieldData.value === -1 && curtainMaterialColorFieldData.value === -1) ||
    (curtainProductTypeFieldData.value !== -1 && curtainMaterialColorFieldData.value === -1) ||
    (curtainProductTypeFieldData.value === -1 && curtainMaterialColorFieldData.value !== -1);

  return (
    <>
      <Grid container rowGap={2}>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '110%',
                color: '#4B7C8A',
                marginBottom: '4px',
              }}
            >
              Vloerdecoratie
            </Typography>
          </Grid>
          <Grid item xs={isEditStyle ? 4 : 6} pr={1}>
            <SelectInputField
              label={'Type'}
              placeholder={'Selecteer type...'}
              placeholderValue={-1}
              name={floorProductTypeField}
              options={floorMaterials?.map((i) => ({
                label: i.typeName,
                value: i.idProductType,
              }))}
            />
          </Grid>
          <Grid item xs={isEditStyle ? 4 : 6} pl={1}>
            <SelectInputField
              label={'Kleur'}
              name={floorMaterialColorField}
              placeholder={'Selecteer kleur...'}
              placeholderValue={-1}
              options={floorMaterialColors?.map((i) => ({
                label: i.materialColorName,
                value: i.idMaterialColor,
              }))}
            />
          </Grid>
          {isEditStyle && (
            <Grid item xs={4} pl={1}>
              <TextInputField label={'Prijs'} name={floorMaterialPrice} type="number" disabled={disableFloorPrice} />
            </Grid>
          )}
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '110%',
                color: '#4B7C8A',
                marginBottom: '4px',
              }}
            >
              Raamdecoratie
            </Typography>
          </Grid>
          <Grid item xs={isEditStyle ? 4 : 6} pr={1}>
            <SelectInputField
              label={'Type'}
              placeholder={'Selecteer type...'}
              placeholderValue={-1}
              name={windowProductTypeField}
              options={windowsMaterials?.map((i) => ({
                label: i.typeName,
                value: i.idProductType,
              }))}
            />
          </Grid>
          <Grid item xs={isEditStyle ? 4 : 6} pl={1}>
            <SelectInputField
              label={'Kleur'}
              name={windowMaterialColorField}
              placeholder={'Selecteer kleur...'}
              placeholderValue={-1}
              options={windowMaterialColors?.map((i) => ({
                label: i.materialColorName,
                value: i.idMaterialColor,
              }))}
            />
          </Grid>
          {isEditStyle && (
            <Grid item xs={4} pl={1}>
              <TextInputField label={'Prijs'} name={windowMaterialPrice} type="number" disabled={disableWinowPrice} />
            </Grid>
          )}
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '110%',
                color: '#4B7C8A',
                marginBottom: '4px',
              }}
            >
              Gordijnen
            </Typography>
          </Grid>
          <Grid item xs={isEditStyle ? 4 : 6} pr={1}>
            <SelectInputField
              label={'Type'}
              placeholder={'Selecteer type...'}
              placeholderValue={-1}
              name={curtainProductTypeField}
              options={curtainMaterials?.map((i) => ({
                label: i.typeName,
                value: i.idProductType,
              }))}
            />
          </Grid>
          <Grid item xs={isEditStyle ? 4 : 6} pl={1}>
            <SelectInputField
              label={'Kleur'}
              name={curtainMaterialColorField}
              placeholder={'Selecteer kleur...'}
              placeholderValue={-1}
              options={curtainMaterialColors?.map((i) => ({
                label: i.materialColorName,
                value: i.idMaterialColor,
              }))}
            />
          </Grid>
          {isEditStyle && (
            <Grid item xs={4} pl={1}>
              <TextInputField
                label={'Prijs'}
                name={curtainMaterialPrice}
                type="number"
                disabled={disableCurtainPrice}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
