import { call, put } from 'redux-saga/effects';
import { Material } from '../../types';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';

async function callApi() {
  return ApiRequest(Method.GET, ENDPOINTS.getCompletedMaterials);
}

export default function* callFetchMaterialsSaga() {
  try {
    const response: Material[] = yield call(callApi);
    yield put(clientActions.fetchMaterialsSuccess(response));
  } catch (error: unknown) {
    console.error('callFetchMaterialsSaga', error);
    yield put(clientActions.fetchMaterialsFailure());
  }
}
