import { call, put } from 'redux-saga/effects';
import { FetchLotTypesParams, FetchLotTypesResponse } from '../../types';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SIZE, ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { projectActions } from '../slice/projectsSlice';

async function callApi(props: FetchLotTypesParams) {
  const queryParam = {
    searchKey: props?.searchKey,
    lotType: props?.lotType,
    styleSlot: props?.styleSlot,
    totalMeter: props?.totalMeter,
    floors: props?.floors,
    KeyProperty: props?.KeyProperty,
    IsAscending: props?.IsAscending,
    pageSize: props?.pageSize ?? DEFAULT_PAGE_SIZE,
    pageNumber: props?.pageNumber ?? 1,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getProjectLotTypes, { id: props.id }, queryParam);
}

export default function* callFetchLotTypesSaga({ payload }: PayloadAction<FetchLotTypesParams>) {
  try {
    const response: FetchLotTypesResponse = yield call(callApi, payload);
    yield put(projectActions.fetchLotTypesSuccess(response));
  } catch (error: unknown) {
    console.error('callFetchLotTypesSaga', error);
    yield put(projectActions.fetchLotTypesFailure());
  }
}
