import { ApiRequest } from '../../../../api/ApiService';
import { Method } from '../../../../api/types';
import { ENDPOINTS } from '../../../../api/constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { UploadColorImage } from '../../types';
import { productActions } from '../slice/productsSlice';

async function callApi(payload: UploadColorImage[]) {
  return ApiRequest(Method.POST, ENDPOINTS.uploadProductColorImage, undefined, undefined, payload);
}

export default function* callUploadProductColorImageSaga({ payload }: PayloadAction<UploadColorImage[]>) {
  try {
    yield call(callApi, payload);
    yield put(productActions.uploadProductColorImageSuccess());
    SuccessToast('Succes!', 'Afbeelding succesvol geüpload');
  } catch (error: unknown) {
    console.error('callUploadProductColorImageSaga', error);
    yield put(productActions.uploadProductColorImageFailure());
    // @ts-ignore
    ErrorToast(undefined, error.toString());
  }
}
