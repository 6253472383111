import { RootState } from '../../../../store/types';

export const fetchProductsIsLoadingSelector = (state: RootState) => state.products.fetchProductsIsLoading;
export const productsSelector = (state: RootState) => state.products.products.data;
export const productsTotalPagesSelector = (state: RootState) => state.products.products.totalPages;
export const productsCountSelector = (state: RootState) => state.products.products.count;
export const fetchProductsHasErrorSelector = (state: RootState) => state.products.fetchProductsHasError;

export const syncProductsWithExactIsLoadingSelector = (state: RootState) =>
  state.products.syncProductsWithExactIsLoading;
export const syncProductsWithExactIsSuccessSelector = (state: RootState) =>
  state.products.syncProductsWithExactIsSuccess;
export const syncProductsWithExactHasErrorSelector = (state: RootState) => state.products.syncProductsWithExactHasError;

export const syncSingleProductWithExactIsLoadingSelector = (state: RootState) =>
  state.products.syncSingleProductWithExactIsLoading;
export const syncSingleProductWithExactIsSuccessSelector = (state: RootState) =>
  state.products.syncSingleProductWithExactIsSuccess;
export const syncSingleProductWithExactHasErrorSelector = (state: RootState) =>
  state.products.syncSingleProductWithExactHasError;

export const fetchProductTypesIsLoadingSelector = (state: RootState) => state.products.fetchProductTypesIsLoading;
export const productTypesSelector = (state: RootState) => state.products.productTypes;
export const fetchProductTypesHasErrorSelector = (state: RootState) => state.products.fetchProductTypesHasError;

export const fetchProductGroupsIsLoadingSelector = (state: RootState) => state.products.fetchProductGroupsIsLoading;
export const productGroupsSelector = (state: RootState) => state.products.productGroups;
export const fetchProductGroupsHasErrorSelector = (state: RootState) => state.products.fetchProductGroupsHasError;

export const fetchTemplatesIsLoadingSelector = (state: RootState) => state.products.fetchTemplatesIsLoading;
export const templatesSelector = (state: RootState) => state.products.templates.data;
export const templatesTotalPagesSelector = (state: RootState) => state.products.templates.totalPages;
export const templatesCountSelector = (state: RootState) => state.products.templates.count;
export const fetchTemplatesHasErrorSelector = (state: RootState) => state.products.fetchTemplatesHasError;

export const createTemplateIsLoadingSelector = (state: RootState) => state.products.createTemplateIsLoading;
export const createTemplateIsSuccessSelector = (state: RootState) => state.products.createTemplateIsSuccess;
export const createTemplateHasErrorSelector = (state: RootState) => state.products.createTemplateHasError;

export const editTemplateIsLoadingSelector = (state: RootState) => state.products.editTemplateIsLoading;
export const editTemplateIsSuccessSelector = (state: RootState) => state.products.editTemplateIsSuccess;
export const editTemplateHasErrorSelector = (state: RootState) => state.products.editTemplateHasError;

export const deleteTemplateIsLoadingSelector = (state: RootState) => state.products.deleteTemplateIsLoading;
export const deleteTemplateIsSuccessSelector = (state: RootState) => state.products.deleteTemplateIsSuccess;
export const deleteTemplateHasErrorSelector = (state: RootState) => state.products.deleteTemplateHasError;

export const fetchExistingMaterialsIsLoadingSelector = (state: RootState) =>
  state.products.fetchExistingMaterialsIsLoading;
export const existingMaterialsSelector = (state: RootState) => state.products.existingMaterials;
export const fetchExistingMaterialsHasErrorSelector = (state: RootState) =>
  state.products.fetchExistingMaterialsHasError;

export const materialColorsIsLoadingSelector = (state: RootState) => state.products.fetchMaterialColorsIsLoading;
export const materialColorsSelector = (state: RootState) => state.products.materialColors.data;
export const materialColorsTotalPagesSelector = (state: RootState) => state.products.materialColors.totalPages;
export const materialColorsCountSelector = (state: RootState) => state.products.materialColors.count;
export const materialColorsHasErrorSelector = (state: RootState) => state.products.fetchMaterialColorsHasError;

export const uploadProductGroupImageIsLoadingSelector = (state: RootState) =>
  state.products.uploadProductGroupImageIsLoading;
export const uploadProductGroupImageIsSuccessSelector = (state: RootState) =>
  state.products.uploadProductGroupImageIsSuccess;
export const uploadProductGroupImageHasErrorSelector = (state: RootState) =>
  state.products.uploadProductGroupImageHasError;

export const uploadProductBrochureIsSuccessSelector = (state: RootState) =>
  state.products.uploadProductBrochureIsSuccess;

export const downloadProductGroupImageIsLoadingSelector = (state: RootState) =>
  state.products.downloadProductGroupImageIsLoading;
export const downloadProductGroupImageSelector = (state: RootState) => state.products.downloadProductGroupImage;
export const downloadProductGroupImageHasErrorSelector = (state: RootState) =>
  state.products.downloadProductGroupImageHasError;

export const uploadProductColorImageIsLoadingSelector = (state: RootState) =>
  state.products.uploadProductColorImageIsLoading;
export const uploadProductColorImageIsSuccessSelector = (state: RootState) =>
  state.products.uploadProductColorImageIsSuccess;
export const uploadProductColorImageHasErrorSelector = (state: RootState) =>
  state.products.uploadProductColorImageHasError;

export const downloadProductColorImageIsLoadingSelector = (state: RootState) =>
  state.products.downloadProductColorImageIsLoading;
export const downloadProductColorImageSelector = (state: RootState) => state.products.downloadProductColorImage;
export const downloadProductColorImageHasErrorSelector = (state: RootState) =>
  state.products.downloadProductColorImageHasError;
export const deleteGroupImageIsSuccessSelector = (state: RootState) => state.products.deleteGroupImageIsSuccess;
export const deleteGroupBrochureIsSuccessSelector = (state: RootState) => state.products.deleteGroupBrochureIsSuccess;
export const downloadProductBrochureSelector = (state: RootState) => state.products.downloadProductBrochure;

export const deleteProductColorImageIsSuccessSelector = (state: RootState) =>
  state.products.deleteProductColorImageIsSuccess;
export const deleteProductColorImageIsLoadingSelector = (state: RootState) =>
  state.products.deleteProductColorImageIsLoading;
