import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';

import { PostTimeSlotModal, Project, TimeSlot } from '../types';
import CustomDialog from '@shared/components/modal/CustomDialog';
import DatePicker from '../../../shared/components/DatePicker/DatePicker';
import { projectActions } from '../store/slice/projectsSlice';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import {
  postTimeSlotIsValidSelector,
  postTimeSlotMessageSelector,
  updateTimeSlotIsSuccessSelector,
} from '../store/selectors/projectsSelectors';
import TodaysDate from '../utils/TodaysDate';
import passValidTime from '../utils/passValidTime';
import findDuplicateObjects from '../utils/findDuplicateObjects';
import checkTimeValidity from '../utils/checkTimeValidity';

const DisabledInput = styled('input')`
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 300;
  color: #0c1b2a;
  border: 1px solid #9a9fa5;
  background-color: #f0f1f2;
  &:focus {
    outline: none;
    opacity: 1;
  }
`;

interface TimeSlotModalProps {
  openModal: boolean;
  closeModal: () => void;
  selectedProject: Project | null | undefined;
  page: number;
  fetchProjects: () => void;
}

export default function TileSlotModal(props: TimeSlotModalProps) {
  const { openModal, closeModal, selectedProject, page, fetchProjects } = props;

  const dispatch = useAppDispatch();

  const updateTimeSlotIsSuccess = useAppSelector(updateTimeSlotIsSuccessSelector);
  const postTimeSlotIsValid = useAppSelector(postTimeSlotIsValidSelector);
  const postTimeSlotMessage = useAppSelector(postTimeSlotMessageSelector);

  const [updatedDates, setUpdatedDates] = useState<TimeSlot[] | null>(null);
  const [inValidDateError, setInvalidDateError] = useState<string | null>(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (updateTimeSlotIsSuccess) {
      if (postTimeSlotIsValid) {
        closeModal();
        setUpdatedDates(null);
        setHasError(false);
        setInvalidDateError(null);
        dispatch(
          projectActions.fetchProjects({
            searchKey: '',
            project: '',
            lots: undefined,
            KeyProperty: 'project',
            IsAscending: true,
            pageNumber: page,
          })
        );
        dispatch(projectActions.resetTimeSlot());
      }
    }
  }, [updateTimeSlotIsSuccess, page, dispatch, closeModal, postTimeSlotIsValid]);

  const parseDate = (dateString) => {
    if (dateString) {
      const dateObj = new Date(dateString);
      const nextDate = new Date(dateObj.getTime() + 86400000);
      return nextDate.toISOString().split('T')[0];
    }
  };

  function chooseDates() {
    if (selectedProject?.bookeddates) {
      if (selectedProject?.bookeddates?.length > 0) {
        const bookedDates = selectedProject?.bookeddates?.map((date) => {
          return {
            idStyleRoom: date.idStyleRoom,
            bookedDate: parseDate(date.styledRoomBooked),
            from: passValidTime(date.bookedFrom),
            to: passValidTime(date.bookedTo),
          };
        });
        return bookedDates;
      } else {
        const initialDate = [
          {
            idStyleRoom: 0,
            bookedDate: TodaysDate(),
            from: '10:00',
            to: '18:00',
          },
        ];
        return initialDate;
      }
    }
  }

  const initialValue: PostTimeSlotModal = {
    idProject: selectedProject?.idProject,
    timeSlots: postTimeSlotMessage || hasError ? updatedDates : chooseDates(),
  };

  const validationSchema = Yup.object().shape({
    // portalStatus: Yup.string().required(),
    // noOfFloors: Yup.number().integer().min(1).required(),
  });

  const handleSubmit = (values: PostTimeSlotModal) => {
    if (values) {
      const currentDate = new Date();
      const todaysDate = TodaysDate();
      const hours = currentDate.getHours().toString().padStart(2, '0');
      const minutes = currentDate.getMinutes().toString().padStart(2, '0');
      const currentTime = `${hours}:${minutes}`;
      const initialDates =
        selectedProject?.bookeddates && selectedProject?.bookeddates?.length > 0 ? chooseDates() : [];
      const updatedDates = values.timeSlots;
      setUpdatedDates(updatedDates);
      const finalDates = updatedDates.filter((item2) => {
        return !initialDates.some(
          (item1) => item1.bookedDate === item2.bookedDate && item1.from === item2.from && item1.to === item2.to
        );
      });
      const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      const inValidity: string[] = [];
      if (finalDates.length > 0) {
        const duplicateDates = findDuplicateObjects(finalDates, 'bookedDate');
        duplicateDates.map((i) => {
          const duplicateObjects = finalDates.filter((j) => j.bookedDate === i.bookedDate);
          const haveValidTime = checkTimeValidity(duplicateObjects);
          if (!haveValidTime) {
            inValidity.push('Cant_choose_same_time');
            setHasError(true);
          }
        });
        finalDates.map((i) => {
          const startHour = i.from?.split(':')[0];
          const startMinute = i.from?.split(':')[1];
          const endHour = i.to?.split(':')[0];
          const endMinute = i.to?.split(':')[1];
          const timeDifference = Number(endHour) - Number(startHour);
          if (timeRegex.test(i.from) && timeRegex.test(i.to)) {
            if (i.from < i.to) {
              setInvalidDateError(null);
            } else {
              inValidity.push('from_time');
              setHasError(true);
            }
            if (timeDifference < 2 || endMinute < startMinute) {
              inValidity.push('2_hour');
              setHasError(true);
            } else {
              setInvalidDateError(null);
            }
            if (i.bookedDate === todaysDate) {
              if (i.from < currentTime || i.to < currentTime) {
                inValidity.push('past_time');
                setHasError(true);
              } else {
                setInvalidDateError(null);
              }
            }
          } else {
            inValidity.push('!');
            setHasError(true);
          }
        });
      } else {
        inValidity.push('same_date_time');
        setHasError(true);
      }
      if (inValidity.length !== 0) {
        const sameTimeValidation = inValidity.find((i) => i === 'Cant_choose_same_time');
        const fromTimeInValidity = inValidity.find((i) => i === 'from_time');
        const pastTimeInvalidity = inValidity.find((i) => i === 'past_time');
        const sameDateTimeValidity = inValidity.find((i) => i === 'same_date_time');
        const timeHoursValidity = inValidity.find((i) => i === '2_hour');
        if (sameTimeValidation) {
          setInvalidDateError(`Kan niet dezelfde tijdsintervallen kiezen!`);
        } else if (fromTimeInValidity) {
          setInvalidDateError('Kies geldig vanaf tijd!');
        } else if (pastTimeInvalidity) {
          setInvalidDateError(`Kan verleden tijd niet selecteren!`);
        } else if (sameDateTimeValidity) {
          setInvalidDateError(`Kan niet dezelfde datum en dezelfde tijd selecteren!`);
        } else if (timeHoursValidity) {
          setInvalidDateError(`Kan geen tijdslot korter dan twee uur kiezen!`);
        } else {
          setInvalidDateError('Kies een geldige tijd!');
        }
      }
      if (inValidity.length === 0) {
        const uploadValue: PostTimeSlotModal = {
          idProject: values.idProject,
          timeSlots: updatedDates,
        };
        dispatch(projectActions.updateTimeSlot(uploadValue));
      }
    }
  };

  const onClosing = () => {
    dispatch(projectActions.resetTimeSlot());
    fetchProjects();
    setUpdatedDates(null);
    setInvalidDateError(null);
    setHasError(false);
    closeModal();
  };

  function checkHasEmpty(val) {
    if (val) {
      const hasEmptyField = val.find((j) => j.bookedDate === '' || j.from === '' || j.to === '');
      if (hasEmptyField) {
        return true;
      } else {
        return false;
      }
    }
  }

  function correctError() {
    setInvalidDateError(null);
  }

  return (
    <Formik initialValues={initialValue} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize>
      {({ dirty, isValid, values, submitForm }) => {
        const hasEmptyField = checkHasEmpty(values?.timeSlots);

        const onCloseTimeSlotModal = () => {
          //@ts-ignore
          values['timeSlots'] = chooseDates();
          onClosing();
        };

        return (
          <Form>
            <CustomDialog
              open={openModal}
              handleClose={onCloseTimeSlotModal}
              title={
                selectedProject?.bookeddates && selectedProject?.bookeddates?.length > 0
                  ? 'Tijdslot wijzigen'
                  : 'Boek tijdslot'
              }
              primaryButtonText={'Boek'}
              primaryButtonDisabled={!isValid || !dirty || hasEmptyField || values?.timeSlots.length === 0}
              handlePrimaryButtonPress={submitForm}
            >
              <Grid container rowGap={2}>
                <Grid item xs={12}>
                  <DisabledInput value={selectedProject?.projectName} disabled />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    label="Datum"
                    correctError={correctError}
                    errorMessage={!postTimeSlotIsValid && postTimeSlotMessage ? postTimeSlotMessage : inValidDateError}
                    selectedProject={selectedProject}
                  />
                </Grid>
              </Grid>
            </CustomDialog>
          </Form>
        );
      }}
    </Formik>
  );
}
