export default function getReadableFloorName(noOfFloors = 0): string[] {
  return [...Array(noOfFloors).keys()].map((el) => {
    switch (el) {
      case 0:
        return 'Begane grond';
      default:
        return `Verdieping ${el}`;
    }
  });
}

export function getReadableFloor(floor: number): string {
  switch (floor) {
    case 0:
      return 'undefined';
    case 1:
      return 'Begane grond';
    default:
      return `Verdieping ${floor - 1}`;
  }
}
