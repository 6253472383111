import { CustomTableDataCell } from '@shared/components/customTable/CustomTable';
import { IconButton } from '@mui/material';
import React from 'react';
import { TableBodyRow } from '@shared/types';
import TableStatusLabel, { TableStatusType } from '../../../shared/components/tableTextOptions/TableStatusLabel';
import { Template } from '../types';
import { ReactComponent as EditIcon } from '@assets/images/tableRowIcons/edit.svg';
import { ReactComponent as DeleteIcon } from '@assets/images/tableRowIcons/delete.svg';
import LightTooltip from '../../../utils/lightTooltip';

function getTableStatusType(status: string) {
  if (status === 'Voltooid') {
    return TableStatusType.Success;
  } else {
    return TableStatusType.Error;
  }
}

export default function templateTableData(
  templateData: Template[],
  tableHeaderWidth: (string | undefined)[],
  handleCreateTemplateClick: (a: Template) => void,
  handleDeleteTemplateClick: (a: Template) => void
): TableBodyRow[] {
  return templateData.map((template: Template) => {
    return {
      id: `${template.idTemplate}`,
      tableCell: (
        <>
          <CustomTableDataCell width={tableHeaderWidth[0] ?? 'unset'} fontWeight={700}>
            {template.name}
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[1] ?? 'unset'} fontWeight={700}>
            {template.material ? `${template.material} materiaalgroepen` : 'N/A'}
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[2] ?? 'unset'} fontWeight={700}>
            {template.isActive ? (
              <TableStatusLabel
                label={template.status || 'Contains incomplete products'}
                type={getTableStatusType(template.status)}
              />
            ) : (
              <p>Gehandicapt</p>
            )}
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[6] ?? 'unset'}>
            <LightTooltip title="Template wijzigen">
              <IconButton
                className={'table-icon'}
                onClick={() => handleCreateTemplateClick(template)}
                sx={{
                  padding: '5px',
                  '&:disabled': {
                    opacity: '0 !important',
                  },
                }}
              >
                <EditIcon width={16} height={16} />
              </IconButton>
            </LightTooltip>
          </CustomTableDataCell>
          <CustomTableDataCell width={tableHeaderWidth[7] ?? 'unset'}>
            <LightTooltip title="Template verwijderen">
              <IconButton
                className={'table-icon'}
                onClick={() => handleDeleteTemplateClick(template)}
                sx={{
                  padding: '5px',
                  '&:disabled': {
                    opacity: '0 !important',
                  },
                }}
              >
                <DeleteIcon width={16} height={16} />
              </IconButton>
            </LightTooltip>
          </CustomTableDataCell>
        </>
      ),
    };
  });
}
