import React from 'react';
import Box from '@mui/material/Box';

interface PageLayoutProps {
  children: React.ReactNode;
}

function PageLayout({ children }: PageLayoutProps) {
  return (
    <Box
      component="main"
      sx={{
        minHeight: '100vh',
        width: '100%',
        backgroundColor: '#FAFAFA',
      }}
    >
      {children}
    </Box>
  );
}

export default PageLayout;
