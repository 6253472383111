export default function flattenNestedObject(i) {
  const crushObj = (obj = {}) =>
    Object.keys(obj || {}).reduce((acc, cur) => {
      if (typeof obj[cur] === 'object') {
        // eslint-disable-next-line no-param-reassign
        acc = { ...acc, ...crushObj(obj[cur]) };
      } else {
        // eslint-disable-next-line no-param-reassign
        acc[cur] = obj[cur];
      }
      return acc;
    }, {});

  return crushObj(i);
}
