import { call, put } from 'redux-saga/effects';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { productActions } from '../slice/productsSlice';
import { ApiRequest } from '../../../../api/ApiService';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';

async function callApi() {
  return ApiRequest(Method.GET, ENDPOINTS.syncProducts);
}

export default function* callSyncProductsWithExactSaga() {
  try {
    yield call(callApi);
    yield put(productActions.syncProductsWithExactSuccess());
    SuccessToast('Products updated!', 'All products are updated with the newest exact materials');
  } catch (error: unknown) {
    console.error('callSyncProductsWithExactSaga', error);
    yield put(productActions.syncProductsWithExactFailure());
  }
}
