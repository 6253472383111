import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import { useDispatch } from 'react-redux';

import { clientActions } from '../../store/slice/clientSlice';
import { useAppSelector } from '../../../../store/types';
import { styled } from '@mui/material/styles';
import { appointmentDetailsSelector } from '../../store/selectors/clientSelector';
import EditTimeSlotModal from '../EditTimeSlotModal';

const Header = styled('label')`
  font-weight: 400;
  font-size: 18px;
  font-style: italic;
  font-family: NeulisAlt;
  color: #45525e;
`;

const Label = styled('p')`
  font-size: 16px;
  font-weight: 700;
  color: #343e47;
`;

const Value = styled('p')`
  font-size: 16px;
  font-weight: 300;
  color: #343e47;
`;

const BtnStyle = styled('button')`
  text-transform: uppercase;
  border: 1px solid #bd8890;
  border-radius: 5px;
  color: #bd8890;
  cursor: pointer;
  padding: 8px 10px;
  background-color: #fffffe;
  margin-top: 40px;
  font-family: Public-sans;
`;

interface AppointmentProps {
  idClient: number;
}

function AppointmentCard({ idClient }: AppointmentProps) {
  const dispatch = useDispatch();

  const [openEditTimeSlot, setOpenEditTimeSlot] = useState(false);

  const AppointmentDetails = useAppSelector(appointmentDetailsSelector);

  useEffect(() => {
    if (idClient) {
      dispatch(clientActions.fetchAppointmentDetails(idClient));
    }
  }, [idClient, dispatch]);

  return (
    <Card style={{ padding: '20px 12px', boxShadow: '0px 10px 24px 0px rgba(12, 27, 42, 0.06)' }}>
      <EditTimeSlotModal
        openModal={openEditTimeSlot}
        handleClose={() => setOpenEditTimeSlot(false)}
        clientId={idClient}
      />
      {AppointmentDetails?.datum ? (
        <>
          <Header>Geselecteerde tijdslot door klant</Header>
          <div style={{ margin: '15px 0px' }}>
            <Label>{AppointmentDetails?.datum}</Label>
            <Value>{AppointmentDetails?.timeSlot}</Value>
          </div>
          <BtnStyle onClick={() => setOpenEditTimeSlot(true)}>Nieuw tijdslot voorstellen</BtnStyle>
        </>
      ) : (
        <Header>Er is nog geen afspraak gepland.</Header>
      )}
    </Card>
  );
}

export default AppointmentCard;
