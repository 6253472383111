import { takeEvery, takeLatest } from 'redux-saga/effects';
import { projectActions } from '../slice/projectsSlice';
import callFetchProjectsSaga from './callFetchProjectsSaga';
import callFetchLotTypesSaga from './callFetchLotTypesSaga';
import callUpdateFloorAmountSaga from './callUpdateFloorAmountSaga';
import callUpdateMaterialPriceSaga from './callUpdateMaterialPriceSaga';
import callFetchMaterialPriceSaga from './callFetchMaterialPriceSaga';
import callUpdateTimeSlotSaga from './callUpdateTimeSlotSaga';
import callSyncWithExact from './callSyncWithExactSaga';
import callSyncLotsWithExact from './callSyncLotsWithExactSaga';
import callDeleteTimeSlotSaga from './callDeleteTimeSlotSaga';

export default function* projectsSaga() {
  yield takeLatest(projectActions.fetchProjects, callFetchProjectsSaga);
  yield takeLatest(projectActions.fetchLotTypes, callFetchLotTypesSaga);
  yield takeLatest(projectActions.updateFloorAmount, callUpdateFloorAmountSaga);
  yield takeLatest(projectActions.fetchMaterialPrice, callFetchMaterialPriceSaga);
  yield takeEvery(projectActions.updateMaterialPrice, callUpdateMaterialPriceSaga);
  yield takeLatest(projectActions.updateTimeSlot, callUpdateTimeSlotSaga);
  yield takeLatest(projectActions.projectSyncWithExact, callSyncWithExact);
  yield takeLatest(projectActions.projectSyncLotsWithExact, callSyncLotsWithExact);
  yield takeLatest(projectActions.deleteTimeSlot, callDeleteTimeSlotSaga);
}
