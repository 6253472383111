import { call, put } from 'redux-saga/effects';
import { MaterialPriceParam } from '../../types';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';

async function callApi(props: MaterialPriceParam) {
  const queryParam = {
    IdClient: props.IdClient,
    IdProduct: props.IdProduct,
    IdDefaultTemplete: props.IdDefaultTemplete,
    floor: props.floor,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getMaterialPrice, undefined, queryParam);
}

export default function* callFetchCurtainMaterialPriceSaga({ payload }: PayloadAction<MaterialPriceParam>) {
  try {
    const response: { price: number } = yield call(callApi, payload);
    yield put(clientActions.fetchCurtainMaterialPriceSuccess(response));
  } catch (error: unknown) {
    console.error('callFetchCurtainMaterialPriceSaga', error);
  } finally {
    yield put(clientActions.fetchMaterialPriceIsLoaded());
  }
}
