import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';

import CustomDialog from '../../../shared/components/modal/CustomDialog';
import { ImageData, Product } from '../types';
import ShowImageModal from './ShowImageModal';
import ShowBrochureModal from './ShowBrochureModal';
import {
  deleteGroupBrochureIsSuccessSelector,
  deleteGroupImageIsSuccessSelector,
  downloadProductBrochureSelector,
  uploadProductBrochureIsSuccessSelector,
  uploadProductGroupImageIsLoadingSelector,
  uploadProductGroupImageIsSuccessSelector,
} from '../store/selectors/productsSelector';
import { useAppSelector } from '../../../store/types';
import TableLinkLabel from '../../../shared/components/tableTextOptions/TableLinkLabel';
import { ReactComponent as EditIcon } from '@assets/images/tableRowIcons/edit.svg';
import { ReactComponent as DeleteIcon } from '@assets/images/tableRowIcons/delete.svg';
import { ReactComponent as AddIcon } from '@assets/images/add.svg';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { productActions } from '../store/slice/productsSlice';
import convertToBase64 from '../../../utils/convertBase64';
import ErrorToast from '../../../shared/components/toast/ErrorToast';

const StyledTitle = styled('p')`
  color: #0c1b2a;
  font-size: 16px;
  line-height: 110%;
  margin-bottom: 6px;
  font-weight: 700;
`;

const ImageLinkContainer = styled('button')`
  cursor: pointer;
  border: none;
  background: transparent;
`;

const UploadImageLabel = styled('p')`
  color: #ec9e29;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`;

const Input = styled('input')({
  display: 'none',
});

interface ProductGroupImageModalProps {
  openModal: boolean;
  closeModal: () => void;
  selectedProduct?: Product;
  fetchProducts: () => void;
}

export default function ProductGroupImageModal(props: ProductGroupImageModalProps) {
  const { openModal, closeModal, selectedProduct, fetchProducts } = props;
  const dispatch = useDispatch();
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [showImage, setShowImage] = useState<ImageData | null>(null);
  const [showBrochureModal, setShowBrochureModal] = useState(false);
  const [uploadBrochure, setUploadBrochure] = useState<ImageData | null>(null);

  // const downloadProductGroupImageIsLoading = useAppSelector(downloadProductGroupImageIsLoadingSelector);
  // const downloadProductGroupImage = useAppSelector(downloadProductGroupImageSelector);
  const uploadProductGroupImageIsLoading = useAppSelector(uploadProductGroupImageIsLoadingSelector);
  const uploadProductGroupImageIsSuccess = useAppSelector(uploadProductGroupImageIsSuccessSelector);
  const uploadProductBrochureIsSuccess = useAppSelector(uploadProductBrochureIsSuccessSelector);
  const deleteGroupImageIsSuccess = useAppSelector(deleteGroupImageIsSuccessSelector);
  const deleteGroupBrochureIsSuccess = useAppSelector(deleteGroupBrochureIsSuccessSelector);
  const downloadProductBrochure = useAppSelector(downloadProductBrochureSelector);

  const handleCloseModal = useCallback(() => {
    setShowImage(null);
    setUploadBrochure(null);
    if (deleteGroupImageIsSuccess) {
      fetchProducts();
    }
    if (deleteGroupBrochureIsSuccess) {
      fetchProducts();
    }
    closeModal();
  }, [closeModal, deleteGroupImageIsSuccess, fetchProducts, deleteGroupBrochureIsSuccess]);

  useEffect(() => {
    if (uploadProductGroupImageIsSuccess || uploadProductBrochureIsSuccess) {
      dispatch(productActions.resetUploadProductGroupImage());
      dispatch(productActions.resetUploadProductBrochure());
      setShowImage(null);
      setUploadBrochure(null);
      closeModal();
      fetchProducts();
    }
  }, [dispatch, fetchProducts, closeModal, uploadProductGroupImageIsSuccess, uploadProductBrochureIsSuccess]);

  useEffect(() => {
    if (deleteGroupImageIsSuccess || deleteGroupBrochureIsSuccess) {
      dispatch(productActions.resetDeleteGroupImage());
      dispatch(productActions.resetDeleteGroupBrochure());
      fetchProducts();
      handleCloseModal();
    }
  }, [dispatch, deleteGroupImageIsSuccess, handleCloseModal, fetchProducts, deleteGroupBrochureIsSuccess]);

  if (!selectedProduct || !openModal) {
    return null;
  }

  function onSaveImage() {
    if (uploadBrochure) {
      dispatch(
        productActions.uploadProductBrochure({
          id: selectedProduct!.idProduct,
          uploadPhotoParams: {
            fileName: uploadBrochure?.fileName,
            fileData: uploadBrochure?.fileData,
          },
        })
      );
    }
    if (showImage) {
      dispatch(
        productActions.uploadProductGroupImage({
          id: selectedProduct!.idProduct,
          uploadPhotoParams: {
            fileName: showImage?.fileName,
            fileData: showImage?.fileData,
          },
        })
      );
    }
  }

  function handleDeleteImageClick() {
    if (selectedProduct?.picture) {
      dispatch(productActions.deleteGroupImage(selectedProduct!.idProduct));
    } else {
      if (showImage) {
        setShowImage(null);
      }
    }
  }

  function handleDeleteBrochureClick() {
    if (selectedProduct?.brochureName) {
      dispatch(productActions.deleteGroupBrochure(selectedProduct!.idProduct));
    } else {
      if (uploadBrochure) {
        setUploadBrochure(null);
      }
    }
  }

  function handleImageClick() {
    setShowImageModal(true);
  }

  function handleBrochureClick() {
    if (!uploadBrochure) {
      dispatch(productActions.downloadProductBrochure(selectedProduct!.idProduct));
    }
    setShowBrochureModal(true);
  }

  async function handleImageUpload(event: React.ChangeEvent<HTMLInputElement>) {
    // @ts-ignore
    const imageFile = event.target.files[0];
    const imageSizeInBytes = imageFile.size;
    const sizeInKB = imageSizeInBytes / (1024 * 1024);
    const fileExtension = imageFile.name.split('.').pop();
    if (fileExtension === 'pdf') {
      ErrorToast(undefined, 'kan pdf niet uploaden');
      handleCloseModal();
    } else {
      if (sizeInKB >= 5) {
        ErrorToast(undefined, 'Image size should be less than 5mb');
      } else {
        const base64 = await convertToBase64(imageFile);
        setShowImage({
          fileName: imageFile.name,
          fileData: base64.split(',')[1],
        });
      }
    }
  }

  async function handleBrochureUpload(event: React.ChangeEvent<HTMLInputElement>) {
    // @ts-ignore
    const brochureFile = event.target.files[0];
    const base64 = await convertToBase64(brochureFile);
    const fileExtension = brochureFile.name.split('.').pop();
    if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'heic') {
      ErrorToast(undefined, 'kan geen afbeeldingen uploaden');
      handleCloseModal();
    } else {
      setUploadBrochure({
        fileName: brochureFile.name,
        fileData: base64.split(',')[1],
      });
    }
  }

  return (
    <CustomDialog
      width="422px"
      open={openModal}
      handleClose={handleCloseModal}
      useSmallTitle
      title={selectedProduct?.picture ? 'Details bewerken:' : 'Details toevoegen:'}
      subTitle={selectedProduct?.type}
      primaryButtonText={'Opslaan'}
      primaryButtonDisabled={!showImage && !uploadBrochure}
      primaryButtonIsLoading={uploadProductGroupImageIsLoading}
      handlePrimaryButtonPress={onSaveImage}
    >
      <ShowImageModal
        open={showImageModal}
        image={showImage}
        isBlobId={showImage === null}
        blobId={encodeURIComponent(selectedProduct?.blobId)}
        handleClose={() => {
          setShowImageModal(false);
          dispatch(productActions.resetDownloadProductGroupImage());
        }}
      />
      <ShowBrochureModal
        open={showBrochureModal}
        handleClose={() => setShowBrochureModal(false)}
        brochure={uploadBrochure !== null ? uploadBrochure?.fileData : downloadProductBrochure?.fileData ?? ''}
      />
      <Grid container rowGap={2}>
        <Grid item xs={12}>
          <StyledTitle>Brochure</StyledTitle>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <label htmlFor="brochure-file-upload">
              <Input
                accept=".pdf"
                id="brochure-file-upload"
                type="file"
                onChange={(event) => handleBrochureUpload(event)}
              />
              {selectedProduct?.brochureName || uploadBrochure ? (
                // <ReadyToUpload>{uploadBrochure?.name}</ReadyToUpload>
                <ImageLinkContainer onClick={handleBrochureClick}>
                  <TableLinkLabel showUnderline fontWeight={400}>
                    {uploadBrochure ? uploadBrochure?.fileName : selectedProduct.brochureName}
                  </TableLinkLabel>
                </ImageLinkContainer>
              ) : (
                <UploadImageLabel>
                  <AddIcon stroke={'#EC9E29'} style={{ marginRight: '4px' }} />
                  Bestand uploaden
                </UploadImageLabel>
              )}
            </label>
            {(selectedProduct?.brochureName || uploadBrochure) && (
              <Box display={'flex'} flexDirection={'row'} gap={'4px'} alignItems={'center'}>
                <IconButton
                  sx={{
                    padding: '5px',
                    '&:disabled': {
                      opacity: '0 !important',
                    },
                  }}
                >
                  <label htmlFor="brochure-file-upload" style={{ display: 'flex', cursor: 'pointer' }}>
                    <EditIcon width={16} height={16} />
                  </label>
                </IconButton>
                <IconButton
                  onClick={handleDeleteBrochureClick}
                  sx={{
                    padding: '5px',
                    '&:disabled': {
                      opacity: '0 !important',
                    },
                  }}
                >
                  <DeleteIcon width={16} height={16} />
                </IconButton>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <StyledTitle>Image</StyledTitle>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <label htmlFor="image-file-upload">
              <Input
                accept=".png, .jpg, .jpeg, .heic"
                id="image-file-upload"
                type="file"
                onChange={(event) => handleImageUpload(event)}
              />
              {selectedProduct?.picture || showImage ? (
                // <ReadyToUpload>{uploadImage?.name}</ReadyToUpload>
                <ImageLinkContainer onClick={handleImageClick}>
                  <TableLinkLabel showUnderline fontWeight={400}>
                    {showImage ? showImage?.fileName : selectedProduct.picture}
                  </TableLinkLabel>
                </ImageLinkContainer>
              ) : (
                <UploadImageLabel>
                  <AddIcon stroke={'#EC9E29'} style={{ marginRight: '4px' }} />
                  Bestand uploaden
                </UploadImageLabel>
              )}
            </label>
            {(selectedProduct?.picture || showImage) && (
              <Box display={'flex'} flexDirection={'row'} gap={'4px'} alignItems={'center'}>
                <IconButton
                  sx={{
                    padding: '5px',
                    '&:disabled': {
                      opacity: '0 !important',
                    },
                  }}
                >
                  <label htmlFor="image-file-upload" style={{ display: 'flex', cursor: 'pointer' }}>
                    <EditIcon width={16} height={16} />
                  </label>
                </IconButton>
                <IconButton
                  onClick={handleDeleteImageClick}
                  sx={{
                    padding: '5px',
                    '&:disabled': {
                      opacity: '0 !important',
                    },
                  }}
                >
                  <DeleteIcon width={16} height={16} />
                </IconButton>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </CustomDialog>
  );
}
