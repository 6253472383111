function isTimeInvalid(obj1, obj2) {
  if ((obj2.from >= obj1.from && obj2.from <= obj1.to) || (obj2.to >= obj1.from && obj2.to <= obj1.to)) {
    return true;
  } else {
    return false;
  }
}

export default function checkTimeValidity(array) {
  const inValid: string[] = [];
  for (let i = 0; i < array.length; i++) {
    const currentItem = array[i];
    if (array.slice(0, i).some((item) => isTimeInvalid(item, currentItem))) {
      inValid.push('!');
    }
  }
  if (inValid.length) {
    return false;
  } else {
    return true;
  }
}
