import { ApiRequest } from '../../../../api/ApiService';
import { Method } from '../../../../api/types';
import { ENDPOINTS } from '../../../../api/constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';
import { projectActions } from '../slice/projectsSlice';

async function callApi(id: number) {
  const queryParam = {
    IdStyleRoom: id,
  };
  return ApiRequest(Method.DELETE, ENDPOINTS.deleteBookedTimeSlot, undefined, queryParam);
}

export default function* callDeleteTimeSlotSaga({ payload }: PayloadAction<number>) {
  try {
    yield call(callApi, payload);
    yield put(projectActions.deleteTimeSlotSuccess());
    SuccessToast('Succes!', 'Tijdslot succesvol verwijderd');
  } catch (error: unknown) {
    console.error('callDeleteTimeSlotSaga', error);
    yield put(projectActions.deleteTimeSlotFailure());
    // @ts-ignore
    ErrorToast(undefined, error.toString());
  }
}
