import React from 'react';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';

interface FieldProps {
  value: string;
  onChange: (e: unknown) => void;
}

export default function TimeTextField(props: FieldProps) {
  const { value, onChange } = props;

  const [timeValue] = useField(value);

  function onChangeWithTime(e: any) {
    const regex = /^[0-9:]+$/;
    const inputValue = e.target.value;
    if (inputValue === '' || regex.test(inputValue)) {
      onChange(inputValue);
    }
  }

  return (
    <TextField
      type="text"
      value={timeValue.value}
      onChange={onChangeWithTime}
      sx={{
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
          display: 'none',
        },
        '& input[type=number]': {
          MozAppearance: 'textfield',
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: '5px',
          '& fieldset': {
            border: '1px solid #9a9fa5',
          },
          '&:hover fieldset': {
            border: '1px solid #9a9fa5',
          },
          '&.Mui-focused fieldset': {
            border: '1px solid #9a9fa5',
          },
          '& .MuiOutlinedInput-input': {
            padding: '4px 8px',
            color: '#11151B',
            fontWeight: '400',
            fontSize: '16px',
            width: '100%',
            height: '28px',
          },
        },
      }}
    />
  );
}
