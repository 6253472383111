import React from 'react';

import classes from './CommonHeader.module.css';
import Title from '../title/Title';

interface CommonHeaderProps {
  children?: React.ReactNode;
  header: string;
  subHeader?: string;
  removeBottomPadding?: boolean;
}

function CommonHeader(props: CommonHeaderProps) {
  const { children, header, subHeader, removeBottomPadding = false } = props;
  return (
    <div className={classes.root} style={removeBottomPadding ? { paddingBottom: 0 } : {}}>
      <Title text={header} />
      <label className={classes.subHeader}>{subHeader}</label>
      {children}
    </div>
  );
}

export default CommonHeader;
