import { call, put } from 'redux-saga/effects';
import { FetchProductsParams, FetchProductsResponse } from '../../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_PAGE_SIZE, ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import { productActions } from '../slice/productsSlice';
import { ApiRequest } from '../../../../api/ApiService';

async function callApi(props: FetchProductsParams) {
  const queryParam = {
    searchKey: props?.searchKey,
    type: props?.type,
    group: props?.group,
    color: props?.color,
    status: props?.status,
    KeyProperty: props?.KeyProperty,
    IsAscending: props?.IsAscending,
    pageSize: props?.pageSize ?? DEFAULT_PAGE_SIZE,
    pageNumber: props?.pageNumber ?? 1,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getProducts, undefined, queryParam);
}

export default function* callFetchProductsSaga({ payload }: PayloadAction<FetchProductsParams>) {
  try {
    const products: FetchProductsResponse = yield call(callApi, payload);
    yield put(productActions.fetchProductsSuccess(products));
  } catch (error: unknown) {
    console.error('callFetchProductsSaga', error);
    yield put(productActions.fetchProductsFailure());
  }
}
