import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  //@ts-ignore
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#343E47',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Public-sans',
    padding: '10px 15px',
    borderRadius: '10px',
  },
}));

export default LightTooltip;
