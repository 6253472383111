import React from 'react';

interface Props {
  selected: boolean;
}

const ProductIcon: React.FC<Props> = ({ selected = false }: Props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: '0.4rem' }}
    >
      <path
        d="M3.17004 6.43945L12 11.5495L20.77 6.46945M12 20.6095V11.5395"
        stroke={selected ? '#BD8890' : '#45525E'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.93001 1.48028L4.59001 4.44028C3.38001 5.11028 2.39001 6.79028 2.39001 8.17028V13.8203C2.39001 15.2003 3.38001 16.8803 4.59001 17.5503L9.93001 20.5203C11.07 21.1503 12.94 21.1503 14.08 20.5203L19.42 17.5503C20.63 16.8803 21.62 15.2003 21.62 13.8203V8.17028C21.62 6.79028 20.63 5.11028 19.42 4.44028L14.08 1.47028C12.93 0.840281 11.07 0.840281 9.93001 1.48028Z"
        stroke={selected ? '#BD8890' : '#45525E'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProductIcon;
