import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#BD8890',
    },
    secondary: {
      main: '#FCFCFC',
    },
    warning: {
      main: '#EC9E29',
    },
    error: {
      main: '#EC4C29',
    },
    success: {
      main: '#B4D74B',
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'NeulisAlt',
          fontWeight: 400,
          fontSize: 18,
          // textTransform: 'capitalize',
          color: '#45525E',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { fontSize: 14, color: '#fff', borderRadius: 5, fontWeight: 700, padding: '9px 12px' },
        text: { color: '#50606B', fontSize: 14, fontWeight: 700, padding: '9px 12px', borderRadius: 5 },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          height: '44px',
          fontSize: 14,
        },
      },
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Public-sans',
      textTransform: 'none',
    },
  },
});

export default theme;
