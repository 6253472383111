import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/types';
import { availableTemplatesSelector, fetchExistingTemplatesIsLoadingSelector } from '../store/selectors/clientSelector';
import { clientActions } from '../store/slice/clientSlice';
import { useFormikContext } from 'formik';
import { styled } from '@mui/material/styles';
import { ExistingTemplateResponse } from '../types';
import SelectTemplateInputField from './SelectTemplateInputField';

const ErrorText = styled('p')`
  color: #ec4c29;
  font-family: 'Public-sans';
  font-size: 12px;
  font-weight: 700;
  margin-top: 3px;
`;

interface EditTemplateSelectFieldProps {
  openModal: boolean;
  idLot?: number;
  idLotType?: number;
}

export default function EditTemplateSelectField(props: EditTemplateSelectFieldProps) {
  const { openModal, idLot, idLotType, selectedFloor } = props;
  const { setFieldValue, values } = useFormikContext();
  const dispatch = useAppDispatch();
  const availableTemplates = useAppSelector(availableTemplatesSelector);
  const fetchExistingTemplatesIsLoading = useAppSelector(fetchExistingTemplatesIsLoadingSelector);
  const floor = values.selectedFloor ?? 0;

  useEffect(() => {
    if (openModal && (idLot || idLotType) && values[floor].template === '') {
      dispatch(
        clientActions.fetchExistingTemplates({
          lotsId: idLot,
          lotTypeId: idLotType,
          floor: floor + 1,
          setData: (template: ExistingTemplateResponse) => {
            setFieldValue(`${floor}.template`, template?.idTemplate ?? '-1');
            setFieldValue(`${floor}.windows`, template?.window ?? []);
          },
        })
      );
    }
  }, [dispatch, floor, idLot, idLotType, openModal, setFieldValue, values]);

  return (
    <>
      <SelectTemplateInputField
        label="Template"
        name={`${floor}.template`}
        placeholder="Selecteer template..."
        placeholderValue={''}
        options={availableTemplates.map((i) => ({ value: i.idTemplate, label: i.name }))}
        isLoading={fetchExistingTemplatesIsLoading}
      />
      {/*
      <ErrorText>{error}</ErrorText>
*/}
    </>
  );
}
