import React from 'react';
import { Typography } from '@mui/material';

export default function TableLinkLabel({
  showUnderline = false,
  fontWeight = 700,
  children,
}: {
  showUnderline?: boolean;
  fontWeight?: number;
  children: React.ReactNode;
}) {
  return (
    <Typography
      sx={{
        color: showUnderline ? '#BD8890' : '#343E47',
        fontSize: '14px',
        fontWeight,
        borderBottom: showUnderline ? '1px solid #BD8890' : 'none',
        width: 'fit-content',
        maxWidth: '275px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
    </Typography>
  );
}
