import { FilterType, TableHeaders } from '../../../shared/types';

export default function userTableHeaders(
  nameFilter: string,
  setNameFilter: (a: string) => void,
  emailFilter: string,
  setEmailFilter: (a: string) => void,
  authFilter: string,
  setAuthFilter: (a: string) => void
): TableHeaders[] {
  return [
    {
      label: 'Naam',
      isSortable: true,
      filterPlaceHolder: 'Filter naam...',
      filterType: FilterType.search,
      filterValue: nameFilter,
      onFilterChange: setNameFilter,
      columnName: 'Name',
    },
    {
      label: 'E-mail',
      isSortable: true,
      filterPlaceHolder: 'Filter e-mail...',
      filterType: FilterType.search,
      filterValue: emailFilter,
      onFilterChange: setEmailFilter,
      columnName: 'Email',
      width: '40%',
    },
    {
      label: 'Authorisaties',
      isSortable: true,
      filterPlaceHolder: 'Selecteer authorisaties...',
      filterType: FilterType.select,
      filterValue: authFilter,
      onFilterChange: setAuthFilter,
      filterOptions: [
        { value: 'Admin', label: 'Admin' },
        { value: 'User', label: 'Gebruiker' },
      ],
      columnName: 'Autherization',
      align: 'center',
      width: '250px',
    },
    {
      label: '',
      columnName: 'view',
      align: 'center',
      width: '34px',
    },
    {
      label: '',
      columnName: 'edit',
      align: 'center',
      width: '34px',
    },
  ];
}
