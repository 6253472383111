import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BookedDate } from '../types';
import CustomDialog from '../../../shared/components/modal/CustomDialog';
import { projectActions } from '../store/slice/projectsSlice';
import { useAppSelector } from '../../../store/types';
import { deleteTimeSlotIsLoadingSelector, deleteTimeSlotIsSuccessSelector } from '../store/selectors/projectsSelectors';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import passValidTime from '../utils/passValidTime';

const StyledTitle = styled('p')`
  color: #222;
  font-size: 16px;
  font-weight: 300;
  line-height: 110%;
  letter-spacing: 0.64px;
  text-align: center;
`;

const StyledUserName = styled('p')`
  color: #0c1b2a;
  font-size: 16px;
  font-weight: 700;
  line-height: 110%;
`;

interface DeletetimeSlotModalProps {
  openModal: boolean;
  closeModal: () => void;
  dateToDelete: BookedDate | null;
  clearDate: (id: number | undefined) => void;
}

export default function DeleteTimeSlotModal(props: DeletetimeSlotModalProps) {
  const { openModal, closeModal, dateToDelete, clearDate } = props;
  const dispatch = useDispatch();

  const deleteTimeSlotIsSuccess = useAppSelector(deleteTimeSlotIsSuccessSelector);
  const deleteTimeSlotIsLoading = useAppSelector(deleteTimeSlotIsLoadingSelector);

  useEffect(() => {
    if (deleteTimeSlotIsSuccess) {
      closeModal();
      clearDate(dateToDelete?.idStyleRoom);
      dispatch(projectActions.resetDeleteTimelSlot());
    }
  }, [deleteTimeSlotIsSuccess, closeModal, dispatch, clearDate, dateToDelete]);

  if (!openModal) return null;

  const parseDate = (dateString) => {
    if (dateString) {
      const dateObj = new Date(dateString);
      const nextDate = new Date(dateObj.getTime() + 86400000);
      return nextDate.toISOString().split('T')[0];
    }
  };

  function handleSubmit() {
    if (dateToDelete?.idStyleRoom) {
      dispatch(projectActions.deleteTimeSlot(dateToDelete?.idStyleRoom));
    }
  }

  return (
    <CustomDialog
      open={openModal}
      handleClose={closeModal}
      cancelButtonText={'Annuleren'}
      handleCancelButtonPress={closeModal}
      primaryButtonText={'Verwijderen'}
      primaryButtonDisabled={deleteTimeSlotIsLoading}
      handlePrimaryButtonPress={handleSubmit}
      footerAlign={'center'}
      showCloseButton={false}
    >
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <StyledTitle>Weet je zeker dat je het volgende tijdslot wilt verwijderen </StyledTitle>
        <StyledUserName>{`${parseDate(dateToDelete?.styledRoomBooked)} van ${passValidTime(
          dateToDelete?.bookedFrom
        )} tot ${passValidTime(dateToDelete?.bookedTo)}`}</StyledUserName>
      </Box>
    </CustomDialog>
  );
}
