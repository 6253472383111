import React from 'react';
import { Button } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';

import { ReactComponent as ProdErrorIcon } from '../../../../assets/images/prodError.svg';
import { ReactComponent as ProdFinalIcon } from '../../../../assets/images/prodFinalised.svg';
import { ReactComponent as ProdWarnIcon } from '../../../../assets/images/prodWarn.svg';

export enum TableStatusType {
  Error = 'error',
  Success = 'success',
  Warn = 'warn',
}

function getColor(theme: Theme, type?: TableStatusType) {
  switch (type) {
    case TableStatusType.Success:
      return theme.palette.success.main;
    case TableStatusType.Warn:
      return theme.palette.warning.main;
    case TableStatusType.Error:
      return theme.palette.error.main;
    default:
      return 'unset';
  }
}

function getStatusIcon(type: TableStatusType) {
  switch (type) {
    case TableStatusType.Success:
      return <ProdFinalIcon width={30} />;
    case TableStatusType.Warn:
      return <ProdWarnIcon width={30} />;
    case TableStatusType.Error:
    default:
      return <ProdErrorIcon width={30} />;
  }
}

interface TableStatusProps {
  label: string;
  type?: TableStatusType;
  handleClick?: () => void;
}

export default function TableStatusLabel({ label, type, handleClick }: TableStatusProps) {
  const theme = useTheme();
  return (
    <Button
      variant="text"
      disableElevation
      disableRipple
      disableFocusRipple
      disableTouchRipple
      disabled={!handleClick}
      onClick={handleClick}
      sx={{
        color: `${getColor(theme, type)} !important`,
        fontWeight: '300',
        fontSize: '14px',
        padding: '5px 0px',
        '&.MuiButtonBase-root:hover': {
          backgroundColor: 'transparent',
        },
        textAlign: 'left',
        lineHeight: '22px',
      }}
    >
      <div style={{ minWidth: '30px', marginRight: '4px' }}>{!!type && getStatusIcon(type)}</div>
      {label}
    </Button>
  );
}
