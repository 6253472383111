import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CustomDialog from '../../../shared/components/modal/CustomDialog';
import { useAppSelector } from '../../../store/types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { clientActions } from '../store/slice/clientSlice';
import { deleteTimeSlotIsSuccessSelector } from '../store/selectors/clientSelector';

const StyledTitle = styled('p')`
  color: #222;
  font-size: 16px;
  font-weight: 300;
  line-height: 110%;
  letter-spacing: 0.64px;
  text-align: center;
`;

interface DeleteTimeSlotModalProps {
  openModal: boolean;
  closeModal: () => void;
  id: number;
  clientId: number;
}

export default function DeleteTimeSlotModal(props: DeleteTimeSlotModalProps) {
  const { openModal, closeModal, id, clientId } = props;
  const dispatch = useDispatch();

  const deleteTimeSlotIsSuccess = useAppSelector(deleteTimeSlotIsSuccessSelector);

  useEffect(() => {
    if (deleteTimeSlotIsSuccess) {
      dispatch(clientActions.resetDeleteTimeSlot());
      if (clientId) {
        dispatch(clientActions.fetchAppointmentDetails(clientId));
      }
      closeModal();
    }
  }, [deleteTimeSlotIsSuccess, dispatch, closeModal, clientId]);

  if (!openModal) return null;

  function handleSubmit() {
    if (id) {
      dispatch(clientActions.deleteTimeSlot(id));
    }
    closeModal();
  }

  return (
    <CustomDialog
      open={openModal}
      handleClose={closeModal}
      cancelButtonText={'annuleren'}
      handleCancelButtonPress={closeModal}
      primaryButtonText={'Bevestigen'}
      handlePrimaryButtonPress={handleSubmit}
      footerAlign={'center'}
      showCloseButton={false}
    >
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <StyledTitle>Weet je zeker dat je het volgende tijdslot wilt verwijderen</StyledTitle>
      </Box>
    </CustomDialog>
  );
}
