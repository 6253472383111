import { call, put } from 'redux-saga/effects';
import { FetchMaterialColorPayload, MaterialColor } from '../../types';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';

async function callApi(idProduct: number) {
  const queryParam = {
    idProduct: idProduct,
  };
  return ApiRequest(Method.GET, ENDPOINTS.getClientMaterialColor, undefined, queryParam);
}

export default function* callFetchMaterialColorSaga({ payload }: PayloadAction<FetchMaterialColorPayload>) {
  try {
    const response: MaterialColor[] = yield call(callApi, payload.idProduct);
    payload.setColors(response);
  } catch (error: unknown) {
    console.error('callFetchMaterialColorSaga', error);
  } finally {
    yield put(clientActions.fetchMaterialColorLoaded());
  }
}
