import { call, put } from 'redux-saga/effects';
import { clientActions } from '../slice/clientSlice';
import { ApiRequest } from '@api/ApiService';
import { PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../../../api/constants';
import { Method } from '../../../../api/types';
import SuccessToast from '../../../../shared/components/toast/SuccessToast';
import ErrorToast from '../../../../shared/components/toast/ErrorToast';

async function callApi(id: number) {
  return ApiRequest(Method.DELETE, ENDPOINTS.deleteTimeSlot, { id: id }, undefined);
}

export default function* callDeleteTimeSlotSaga({ payload }: PayloadAction<number>) {
  try {
    yield call(callApi, payload);
    yield put(clientActions.deleteTimeSlotSuccess());
    SuccessToast('Succes!', 'Tijdslot is succesvol verwijderd.');
  } catch (error: unknown) {
    console.error('callDeleteTimeSlotSaga', error);
    yield put(clientActions.deleteTimeSlotFailure());
    ErrorToast(undefined, error.toString());
  }
}
