import React from 'react';

import CommonHeader from '../../../shared/components/commonHeader/CommonHeader';
import PageLayout from '../../../layout/PageLayout';

import ProductIcon from '../../../../assets/ProductIcon';
import TemplateIcon from '../../../../assets/TemplateIcon';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ProductsListing from './ProductsListingTab';
import TemplatesListing from './TemplatesListingTab';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_PRODUCTS } from '../../../utils/routes';

enum ProductTabs {
  PRODUCTS,
  TEMPLATES,
}

function ProductsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const routeFromUrl = `${location.pathname || ''}`;
  const templatesSelected = routeFromUrl.includes('/products/templates');
  const productsSelected = routeFromUrl.includes('/products/overview');

  function handleChange(event: React.SyntheticEvent, newValue: ProductTabs) {
    if (newValue === ProductTabs.PRODUCTS) {
      navigate(`${ROUTE_PRODUCTS}/overview`);
    }
    if (newValue === ProductTabs.TEMPLATES) {
      navigate(`${ROUTE_PRODUCTS}/templates`);
    }
  }

  return (
    <PageLayout>
      <CommonHeader header={'Producten'} subHeader={'Overzicht van producten en templates'} removeBottomPadding>
        <Box style={{ marginTop: 32 }}>
          <Tabs
            value={productsSelected ? ProductTabs.PRODUCTS : ProductTabs.TEMPLATES}
            onChange={handleChange}
            TabIndicatorProps={{ style: { height: 4 } }}
          >
            <Tab
              value={ProductTabs.PRODUCTS}
              icon={<ProductIcon selected={productsSelected} />}
              iconPosition="start"
              label="Producten"
              sx={{
                fontWeight: 700,
                fontStyle: 'italic',
                minHeight: '48px',
              }}
            />
            <Tab
              value={ProductTabs.TEMPLATES}
              icon={<TemplateIcon selected={templatesSelected} />}
              iconPosition="start"
              label="Templates"
              sx={{
                fontWeight: 700,
                fontStyle: 'italic',
                minHeight: '48px',
              }}
            />
          </Tabs>
        </Box>
      </CommonHeader>
      {productsSelected && <ProductsListing />}
      {templatesSelected && <TemplatesListing />}
    </PageLayout>
  );
}

export default ProductsPage;
