import React from 'react';

import { Viewer, Worker } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfViewer = ({ pdfData }) => {
  if (pdfData.length === 0) return null;
  const dataUri = `data:application/pdf;base64,${pdfData}`;

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Viewer fileUrl={dataUri} plugins={[defaultLayoutPluginInstance]} />
      </Worker>
    </>
  );
};

export default PdfViewer;
