import React from 'react';
import { Field, useFormikContext } from 'formik';
import { styled } from '@mui/material/styles';
import { useAppDispatch } from '../../../store/types';
import { clientActions } from '../store/slice/clientSlice';

export const StyledInput = styled('input')<{ $color: string }>`
  padding: 4px 8px;
  width: 100%;
  height: 34px;
  border: 1px solid #9a9fa5;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  font-family: 'Public-sans';
  line-height: 110%;
  color: #9a9fa5;
  width: 40px;
  height: 27px;

  &:focus {
    outline: none;
    opacity: 1;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

interface WindowTextFieldProps {
  name: string;
}

export default function WindowTextField({ name }: WindowTextFieldProps) {
  const dispatch = useAppDispatch();
  const { setFieldValue } = useFormikContext();

  function onChanheWindowField(e) {
    setFieldValue(name, e.target.value);
    dispatch(clientActions.setSelectTemplateStatusChanged());
  }

  return <Field as={StyledInput} onChange={onChanheWindowField} name={name} type="number" />;
}
