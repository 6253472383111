import * as React from 'react';
import { IconButton, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MobileDrawer from '@mui/material/Drawer';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import MainMenu from './MainMenu';
import { ReactComponent as CloseIcon } from '@assets/images/close.svg';
import { ReactComponent as LogoutIcon } from '../../assets/images/menu/logout.svg';
import { ReactComponent as StijlPartnerIcon } from '../../assets/images/stijlPartner.svg';
import { ROUTE_LOGIN } from '../utils/routes';
import { removeItem } from '../shared/services/LocalStorage';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DesktopDrawer = styled(MobileDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerFooter = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginBottom: '16px',
});

function MainLayout() {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpenClose = (): void => {
    setOpen(!open);
  };

  return (
    <>
      <DesktopDrawer
        variant="permanent"
        open={open}
        sx={{
          display: { xs: 'none', sm: 'block' },
        }}
        PaperProps={{
          sx: {
            backgroundColor: '#343E47',
            color: '#fff',
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerOpenClose}>
            {!open ? (
              <MenuIcon style={{ color: '#fff', marginRight: 3 }} />
            ) : (
              <CloseIcon stroke={'white'} style={{ marginRight: 148 }} />
            )}
          </IconButton>
        </DrawerHeader>
        <MainMenu open={open} />
        <DrawerFooter>
          <div style={{ display: 'flex', width: '100%', marginBottom: '30px' }}>
            <ListItemButton
              onClick={() => {
                removeItem('console_admin_token');
                navigate(ROUTE_LOGIN, { replace: true });
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: '#fff',
                  marginLeft: open ? '4px' : '-10px',
                  marginRight: 0,
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Log uit'}
                sx={{
                  opacity: open ? 1 : 0,
                  fontFamily: 'Montserrat',
                  color: '#FFFFFE',
                  fontSize: '16px',
                  fontWeight: 400,
                }}
              />
            </ListItemButton>
          </div>
          <div style={{ display: 'flex', width: '60%', justifyContent: 'center' }}>
            <StijlPartnerIcon style={{ width: '100%', height: '100%' }} />
          </div>
        </DrawerFooter>
      </DesktopDrawer>
    </>
  );
}

export default MainLayout;
