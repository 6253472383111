import { takeLatest } from 'redux-saga/effects';

import { userActions } from '../slice/usersSlice';
import callFetchUsersSaga from './callFetchUsersSaga';
import callEditUserSaga from './callEditUserSaga';
import callCreateUserSaga from './callCreateUserSaga';
import callDeleteUserSaga from './callDeleteUserSaga';

export default function* UsersSaga() {
  yield takeLatest(userActions.fetchUsers, callFetchUsersSaga);
  yield takeLatest(userActions.createUser, callCreateUserSaga);
  yield takeLatest(userActions.editUser, callEditUserSaga);
  yield takeLatest(userActions.deleteUser, callDeleteUserSaga);
}
