import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Field, useFormikContext } from 'formik';
import { Grid, IconButton } from '@mui/material';

import { ReactComponent as AddDateFieldIcon } from '../../../../assets/images/PlusIconRed.svg';
import TimeTextField from '../textField/TimeTextField';
import TodaysDate from '../../../modules/projects/utils/TodaysDate';
import { ReactComponent as DeleteIcon } from '@assets/images/tableRowIcons/delete.svg';
import { BookedDate, Project, TimeSlot } from '../../../modules/projects/types';
import DeleteTimeSlotModal from '../../../modules/projects/components/DeleteTimeSlotModal';

const StyledLabelContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

interface LabelProps {
  labelColor: string;
  disabled: boolean;
}

const StyledLabel = styled('p')<LabelProps>`
  line-height: 110%;
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  color: ${({ labelColor }) => labelColor};
  margin-bottom: 4px;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

const StyledInput = styled('input')`
  color: #0c1b2a;
  border: 1px solid #9a9fa5;
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 300;
  font-family: 'Public-sans';
  margin-bottom: 20px;
  height: 35px;
  &:focus {
    outline: none;
    opacity: 1;
  }
`;

const ErrorText = styled('p')`
  font-size: 14px;
  font-weight: 300;
  font-family: 'Public-sans';
  color: #ec4c29;
`;

interface Props {
  label?: string;
  disabled?: boolean;
  labelColor?: string;
  errorMessage?: string | null;
  correctError: () => void;
  selectedProject: Project | undefined | null;
}

export default function DatePicker(props: Props) {
  const { label, disabled = false, labelColor = '#343E47', errorMessage, correctError, selectedProject } = props;

  const { setFieldValue, values } = useFormikContext();
  const [counter, setCounter] = useState(0);
  const [openDeleteTimeSlot, setOpendeleteTimeSlot] = useState(false);
  const [deleteTimeSlot, setDeleteTimeSlot] = useState<BookedDate | null>(null);

  const onAddDateField = (counter: number) => {
    setCounter(counter - 1);
    const tempDates = values?.timeSlots;
    tempDates.push({
      idStyleRoom: counter - 1,
      bookedDate: TodaysDate(),
      from: '10:00',
      to: '18:00',
    });
    setFieldValue('timeSlots', tempDates);
  };

  const today = new Date().toISOString().split('T')[0];

  function onClickDeleteTimeSlot(date: TimeSlot) {
    const dateAlreadyBooked = selectedProject?.bookeddates?.find((d) => d.idStyleRoom === date.idStyleRoom);
    if (dateAlreadyBooked) {
      setOpendeleteTimeSlot(true);
      setDeleteTimeSlot(dateAlreadyBooked);
    } else {
      const tempDates = values?.timeSlots;
      const newDates = tempDates.filter((d: TimeSlot) => d.idStyleRoom !== date.idStyleRoom);
      setFieldValue('timeSlots', newDates);
    }
  }

  function clearDeletedDate(id: number | undefined) {
    if (id) {
      const tempDates = values?.timeSlots;
      const newDates = tempDates.filter((d: TimeSlot) => d.idStyleRoom !== id);
      setFieldValue('timeSlots', newDates);
    }
  }

  function onCloseDeleteTimeSlot() {
    setOpendeleteTimeSlot(false);
    setDeleteTimeSlot(null);
  }

  return (
    <>
      <DeleteTimeSlotModal
        openModal={openDeleteTimeSlot}
        closeModal={onCloseDeleteTimeSlot}
        dateToDelete={deleteTimeSlot}
        clearDate={clearDeletedDate}
      />
      <Grid container flexDirection={'row'} justifyContent={'space-between'}>
        <Grid item xs={5}>
          {label && (
            <StyledLabelContainer>
              <StyledLabel labelColor={labelColor} disabled={disabled}>
                {label}
              </StyledLabel>
            </StyledLabelContainer>
          )}
        </Grid>
        <Grid item xs={3}>
          <StyledLabelContainer>
            <StyledLabel labelColor={labelColor} disabled={disabled}>
              Van
            </StyledLabel>
          </StyledLabelContainer>
        </Grid>
        <Grid item xs={3}>
          <StyledLabelContainer>
            <StyledLabel labelColor={labelColor} disabled={disabled}>
              Tot
            </StyledLabel>
          </StyledLabelContainer>
        </Grid>
        <Grid item xs={0.5}></Grid>
      </Grid>
      {values.timeSlots?.map((date, index) => {
        return (
          <Grid key={index} container flexDirection={'row'} justifyContent={'space-between'}>
            <Grid item xs={5}>
              <Field as={StyledInput} min={today} type="date" name={`timeSlots.${index}.bookedDate`} />
            </Grid>
            <Grid item xs={3}>
              <TimeTextField
                value={`timeSlots.${index}.from`}
                onChange={(val) => {
                  setFieldValue(`timeSlots.${index}.from`, val);
                  correctError();
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TimeTextField
                value={`timeSlots.${index}.to`}
                onChange={(val) => {
                  setFieldValue(`timeSlots.${index}.to`, val);
                  correctError();
                }}
              />
            </Grid>
            <Grid item xs={0.5}>
              <IconButton
                onClick={() => onClickDeleteTimeSlot(date)}
                sx={{
                  '&:disabled': {
                    opacity: '0 !important',
                  },
                }}
              >
                <DeleteIcon width={16} height={16} />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '15px' }}>
        <AddDateFieldIcon style={{ cursor: 'pointer' }} onClick={() => onAddDateField(counter)} />
      </Grid>
    </>
  );
}
