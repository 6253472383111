import { call, put, takeLatest } from 'redux-saga/effects';

import AuthenticationService from '@api/AuthenticationService';
import { authenticationActions } from '@authentication/store/authentication.slice';
import { GetTokenRequest, GetTokenResponse, GetUserInfoResponse } from '@app-types/Responses.types';
import ErrorToast from '../../../shared/components/toast/ErrorToast';
import { PayloadAction } from '@reduxjs/toolkit';
import { removeItem, setWithExpiry } from '../../../shared/services/LocalStorage';

function* watchGetToken(action: PayloadAction<GetTokenRequest>) {
  const { redirectUrl, code } = action.payload;
  try {
    const tokenResponse: GetTokenResponse = yield call(AuthenticationService.getToken, redirectUrl, code);
    if (tokenResponse.access_token) {
      setWithExpiry('console_admin_token', tokenResponse.access_token, tokenResponse.expires_in * 1000);
      const userInfoResponse: GetUserInfoResponse = yield call(
        AuthenticationService.getUserinfo,
        tokenResponse.access_token
      );
      if (userInfoResponse?.isFound) {
        yield put(authenticationActions.getTokenSuccess({ tokenData: tokenResponse, userInfo: userInfoResponse }));
      } else {
        removeItem('console_admin_token');
        ErrorToast('Oh snap!', 'You dont have Permissions for NSL');
        throw new Error('you dont have permissions for nsl');
      }
    } else {
      throw new Error('no access token');
    }
  } catch (error) {
    yield put(authenticationActions.getTokenFailure());
  }
}

export default function* authenticationSaga() {
  yield takeLatest(authenticationActions.getToken, watchGetToken);
}
