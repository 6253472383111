import { combineReducers } from 'redux';
import authenticationReducer from '@authentication/store/authentication.slice';
import projectReducer from '../modules/projects/store/slice/projectsSlice';
import productReducer from '../modules/products/store/slice/productsSlice';
import userReducer from '../modules/users/store/slice/usersSlice';
import clientReducer from '@clients/store/slice/clientSlice';

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  projects: projectReducer,
  products: productReducer,
  users: userReducer,
  clients: clientReducer,
});

export default rootReducer;
